import React, { memo, useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { useBreakpoints } from "react-match-breakpoints";
import { resetPassword } from "../../api/venturaCuida";
import { useNavigate, useParams } from "react-router-dom";
import { withSnackbar } from "notistack";
import { translate } from "react-internationalization";

const ResetPassword = ({ enqueueSnackbar }) => {
    const { tabletDown } = useBreakpoints();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            setError("Passwords do not match");
        } else {
            setError("");
            // Handle password reset logic here
            resetPassword({ id, data: { password } })
                .then((result) => {
                    enqueueSnackbar(translate("canLoggin"), {
                        variant: "success",
                    });
                    navigate("/therapist");
                }).catch((error) => {
                    enqueueSnackbar(translate("errorHappened"), { variant: "error" });
                });
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: tabletDown ? 100 : 200,
                marginBottom: tabletDown ? 100 : 200,
            }}
        >
            <div style={{ width: tabletDown ? "90%" : "40%" }}>
                <Typography
                    variant="h4"
                    style={{
                        textAlign: "center",
                        marginBottom: 20,
                        color: "#333",
                        fontFamily: "Alfa Slab One",
                    }}
                >
                    Nova palavra-passe
                </Typography>
                <TextField
                    label="Nova palavra-passe"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    style={{ marginBottom: 20 }}
                />
                <TextField
                    label="Confirme sua palavra-passe"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    style={{ marginBottom: 20 }}
                    error={!!error}
                    helperText={error}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    style={{ marginTop: 20 }}
                >
                    Reset Password
                </Button>
            </div>
        </div>
    );
};

export default memo(withSnackbar(ResetPassword));

