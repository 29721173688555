import React from "react";
import { Text, translate } from "react-internationalization";
import { BLACK, BONDI_BLUE, BRIGHT_GRAY, GUNMETAL } from "../../../constants.js";
import "./index.css";
import { Typography, useTheme } from "@mui/material";
import { useBreakpoints } from "react-match-breakpoints";
import { ClickableLogo } from "../../../components/ClickableLogo/index.js";
import { Cloud } from "../Therapist/Cloud/index.js";
import hexRgb from "hex-rgb";
import { WhatsAppWidget } from "../../../components/WhatsappWidget/index.js";

const AboutUs = () => {
  const { tabletDown } = useBreakpoints();
  const text = translate("aboutUsText");
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: GUNMETAL
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: tabletDown ? 20 : 100,
          marginBottom: tabletDown ? 20 : 100,
          gap: tabletDown ? 20 : 100,
          flexDirection: tabletDown ? "column-reverse" : "row",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={18}
          color={BLACK}
          fontFamily={"Poppins"}
          dangerouslySetInnerHTML={{ __html: text }}
          style={{
            color: 'white',
            maxWidth: 900,
            borderRadius: 4,
            padding: 20,
          }}
        ></Typography>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <div
          style={{
            width: 1000,
            border: `1px solid ${hexRgb(BRIGHT_GRAY, {
              alpha: 1,
              format: "css",
            })}`,
            borderRadius: 10,
            padding: theme.spacing(2),
            margin: 10,
            display: "flex",
            flexDirection: tabletDown ? "column" : "row",
          }}
        >
          <div style={{ position: "relative", display: "flex" }}>
            <img
              fetchpriority="high"
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: 1, x: 0 }}
              src="images/ana.webp"
              style={{
                height: "auto",
                maxWidth: tabletDown ? "300px" : "400px",
                ...(tabletDown && { margin: "auto", paddingLeft: 16 }),
                alignSelf: tabletDown ? "center" : "flex-end",
              }}
              alt="Ana"
            ></img>
            <div
              style={{
                zIndex: 100,
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                bottom: 50,
                backgroundColor: BRIGHT_GRAY,
                padding: tabletDown ? 0 : theme.spacing(1),
                borderRadius: 10,
                padding: 10,
              }}
            >
              <Typography fontWeight={700} variant="caption" color={BLACK}>
                <Text id={"home.clinicalDirector"}></Text>
              </Typography>
              <Typography variant="caption">
                <Text id={"home.phisioterapist"}></Text>
              </Typography>
            </div>
          </div>
          <Typography
            style={{
              borderRadius: 4,
              backgroundColor: BRIGHT_GRAY,
              padding: 20,
              marginTop: tabletDown ? -10 : 0,
              zIndex: 100,
            }}
            variant="body1"
          >
            <Text id="aboutAna" />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
