import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { hours } from "../ScheduleSession/helpers";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { map, size } from "lodash";
import moment from "moment";

export const DateFormInput = ({ name = "Data da sessão" }) => {
  const { control } = useFormContext();

  return (
    <Controller
      style={{ width: "100%" }}
      control={control}
      name={name}
      rules={{
        required: "Data da sessão é obrigatória",
        validate: (date) => {
          return size(date?.split("/") || 0) === 3 || "Data inválida";
        },
      }}
      defaultValue={moment().format("YYYY/MM/DD")}
      render={({
        field: { onChange, value, ref },
        fieldState: { error },
      }) => (
        <LocalizationProvider
          style={{ width: "100%" }}
          dateAdapter={AdapterDayjs}
        >
          <DatePicker
            inputFormat="YYYY/MM/DD"
            dateFormat="YYYY/MM/DD"
            format="YYYY/MM/DD"
            ref={ref}
            label={name}
            value={value}
            onChange={(e) => {
              onChange(
                e?.format("YYYY/MM/DD") || moment()?.format("YYYY/MM/DD")
              );
            }}
            renderInput={(params) => (
              <TextField
                style={{ width: "100%" }}
                {...params}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export const DateAndHour = ({ dateName, hourName = "Hora" }) => {
  const { control } = useFormContext()
  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <DateFormInput name={dateName} />
      <Controller
        control={control}
        name={hourName}
        rules={{ required: 'Hora é obrigatória' }}
        render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
          <FormControl fullWidth error={!!error} style={{ width: '70%' }}>
            <InputLabel id="hour-label">Hora</InputLabel>
            <Select
              labelId="hour-label"
              onChange={onChange}
              value={value || ''}
              inputRef={ref}
              label="Hora"
            >
              {map(hours, (hour, index) => (
                <MenuItem key={`hour-${index}`} value={hour}>
                  {hour}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </div>
  );
};