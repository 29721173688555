import { useMutation, useQueryClient } from "react-query";
import { venturaCuidaApi } from "../../../api/venturaCuida/index.js";
import { Typography } from "@mui/material";
import { useAuth } from "../../../contexts/AuthProvider/index.js";
import { useMemo, useState } from "react";
import 'react-data-grid/lib/styles.css';
import { DataPlot } from "../DataPlot/index.js";
import opacity from "hex-color-opacity";
import { BONDI_BLUE } from "../../../constants.js/index.js";
import { VButton } from "../../NewHomeComponents/VButton/index.js";
import { useDashboardData } from "../../../hooks/useDashboardData/index.js";
import { textEditor } from "react-data-grid";
import { useDataPlotData } from "../../../hooks/useDataPlotData/index.js";
import { EditableDataPlotContext, EditableDataPlotProvider } from "../../../contexts/EditDataPlotProvider/index.js";
import usePersonalizedCols, { useOrderedCols } from "../../../hooks/usePersonalizedCols/index.js";
import { TabletDownDashboardChanger } from "../../TabletDownDashboardChanger/index.js";

const RESOURCE = 'pricing';

const SHOW_COLS = ['partnership.name', 'service.name', 'totalPrice', 'withoutTax', 'therapistPay', 'partnershipCommission'];

export const Pricing = () => {

    const [_, setAddSessionOpen] = useState(false);
    const { data, isLoading } = useDashboardData({ resource: RESOURCE });

    const { user } = useAuth();

    let [initialColumns, initialRows] = useDataPlotData({
        columnsProps: {
            renderEditCell: textEditor
        },
        data,
        isLoading,
        columnsBlackListed: ['_id', '__v', 'createdAt']
    });

    const personalizedCols = usePersonalizedCols({
        initialColumns,
        COLUMNS_BLUEPRINT: {
            admin: {
                cols: SHOW_COLS,
                'partnership.name': {
                    renderCell: ({ row }) => {
                        return row?.partnership?.name;
                    },
                    renderEditCell: null
                },
                'service.name': {
                    renderCell: ({ row }) => {
                        return row?.service?.name;
                    },
                    renderEditCell: null
                },
            }
        }
    })

    const personalizedColsOrdered = useOrderedCols(personalizedCols, SHOW_COLS)

    const updateDataMutation = useMutation({
        mutationFn: (data) => {
            return venturaCuidaApi().put('api/admin/' + RESOURCE, data)
        },
    });

    const queryClient = useQueryClient();

    return <div style={{ height: '100%', width: '100%', overflowX: 'scroll' }}>
        {!isLoading && data &&
            <EditableDataPlotProvider rows={initialRows}>
                <EditableDataPlotContext.Consumer>
                    {({ isEdited, changedRows, reset, setEditedRows, editedRows, rowKeyGetter }) => {
                        return (
                            <DataPlot
                                columns={personalizedColsOrdered}
                                rows={editedRows}
                                dataGridProps={{
                                    onRowsChange: setEditedRows,
                                    rowKeyGetter,
                                }}
                                Header={() => <div style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: opacity(BONDI_BLUE, 0.7),
                                    width: '100%',
                                    height: 60
                                }}>
                                    <TabletDownDashboardChanger currentPage={'Preçário'} />
                                    <div style={{ display: 'flex', gap: 10 }}>
                                        <VButton loading={updateDataMutation.isLoading} disabled={!isEdited} onClick={() => {
                                            updateDataMutation.mutate(changedRows, {
                                                onSuccess: () => {
                                                    console.log('onSuccess')
                                                    queryClient.invalidateQueries({ queryKey: [`${RESOURCE}-${user._id}`] })
                                                    reset();
                                                },
                                            })
                                        }}>
                                            Guardar
                                        </VButton>
                                    </div>
                                </div>
                                }
                                {...{ data, isLoading }}
                            />)
                    }}
                </EditableDataPlotContext.Consumer>
            </EditableDataPlotProvider>
        }
    </div >
}