import { Button, Link, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import DownloadIcon from '@mui/icons-material/Download';
import { useAuth } from "../../contexts/AuthProvider";
import { VButton } from "../../components/NewHomeComponents/VButton";
import { GUNMETAL } from "../../constants.js";
import { translate } from 'react-internationalization';
import { TherapistWidget } from "../../components/TherapistWidget/index.js";
import { useBreakpoints } from "react-match-breakpoints";
import { useDashboardPages } from "../../hooks/useDashboardPages";

export const Dashboard = ({ children }) => {
    const { spacing } = useTheme();
    const { user, isLoggedIn } = useAuth();
    const navigate = useNavigate()

    useEffect(() => { !isLoggedIn && navigate('/login') }, [isLoggedIn]);

    const { logOut } = useAuth();


    const { tabletDown } = useBreakpoints();

    const pages = useDashboardPages();

    return (
        <div style={{
            flexDirection: 'column',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'flex-start',
            height: window.innerHeight,
            // width: '100%',
        }}>
            {user?.role === 'therapist' && <TherapistWidget />}
            <div style={{
                width: '100%',
                paddingLeft: 10,
                paddingRight: 10,
                height: 60,
                backgroundColor: GUNMETAL,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <div>
                    <img
                        loading="lazy"
                        width="40"
                        src={`${window.location.origin}/images/logo/logo_circulo_branco.png`}
                        alt="Dashboard"
                    />
                </div>
                <Typography style={{ color: 'white' }}>{`${translate('header.' + user?.role)} ${user?.name}`}</Typography>
                <VButton onClick={() => { logOut() }} variant="text">Sair</VButton>
            </div>
            <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex' }}>
                {!tabletDown && <Sidebar>
                    <Menu>
                        {pages.map(({ name, onClick }, index) => <MenuItem key={name + index}
                            onClick={onClick}
                        >
                            {name}
                        </MenuItem>)}
                        <SubMenu label="Downloads">
                            <MenuItem>
                                <Button
                                    onClick={() => {
                                        window.open(
                                            'https://docs.google.com/spreadsheets/d/1R8PzRknYbhxu1xEDyGPuPTqD8GQPWLcDct9bTSLt55I/copy',
                                            '_blank'
                                        );
                                    }}
                                    variant="text"
                                >
                                    Folha de Registo <DownloadIcon />
                                </Button>
                            </MenuItem>
                        </SubMenu>
                    </Menu>
                </Sidebar>}
                {children}
            </div>
        </div >
    );
}