import { TextField, Typography, useTheme, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText, FormGroup, Checkbox, CircularProgress, Alert } from "@mui/material";
import React, { memo, useEffect, useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import VButton from "../V/VButton/index.js";
import { withSnackbar } from "notistack";
import { useState } from "react";
import { useAddSheetRowData } from "../../hooks/useVenturaCuidaSheet/index.js";
import { BONDI_BLUE, VENTURACARE_SPREADSHEET } from "../../constants.js/index.js";
import { DateFormInputV2 } from "../Modal/DateFormInputV2/index.js";
import moment from "moment";
import { MyDialog } from "../Modal/MyDialog/index.js";
import { ClickableLogo } from "../ClickableLogo/index.js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLanguage } from "../../contexts/Language/index.js";
import { Text, translate } from "react-internationalization";
import { Language } from "../Header/Language/index.js";
import useCreateUserAndAppointment from "../../hooks/usePreApprointmentCreate/index.js";
import { useBreakpoints } from "react-match-breakpoints";

const getDefaultForm = () => ({
  medicalConditions: {
    [translate('preAppointment.medicalConditions.pregnancy')]: '',
    [translate('preAppointment.medicalConditions.injuries')]: false,
    [translate('preAppointment.medicalConditions.uncontrolledHeart')]: false,
    [translate('preAppointment.medicalConditions.deepVein')]: false,
    [translate('preAppointment.medicalConditions.bloodClotting')]: false,
    [translate('preAppointment.medicalConditions.skinInfections')]: false,
    [translate('preAppointment.medicalConditions.severeVaricose')]: false,
    [translate('preAppointment.medicalConditions.osteoporosis')]: false,
    [translate('preAppointment.medicalConditions.cancer')]: false,
    [translate('preAppointment.medicalConditions.others')]: false,
  },
  areasBody: {
    [translate('preAppointment.areasBody.feet')]: false,
    [translate('preAppointment.areasBody.legs')]: false,
    [translate('preAppointment.areasBody.back')]: false,
    [translate('preAppointment.areasBody.abdomen')]: false,
    [translate('preAppointment.areasBody.neckAndHead')]: false,
  },
  termsAndConditions: {
    acceptReceiveMassage: {
      text: translate('preAppointment.termsAndConditions.acceptReceiveMassage'),
      value: false
    },
    massagePurpose: {
      text: translate('preAppointment.termsAndConditions.massagePurpose'),
      value: false
    },
    InappropriateBehaviour: {
      text: translate('preAppointment.termsAndConditions.inappropriateBehaviour'),
      value: false,
    },
    terms: {
      text: translate('preAppointment.termsAndConditions.terms'),
      value: false,
    }
  },
})

function PreAppointment({ open = true, enqueueSnackbar }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { mobile } = useBreakpoints()

  const { language, setLanguage } = useLanguage();

  useEffect(() => {
    setLanguage(searchParams.get('lang') || 'en');
    setTimeout(() => {
      reset(getDefaultForm());
    }, 1);
  }, []);

  useEffect(() => {
    setSearchParams(`?${new URLSearchParams({ lang: language })}`)
  }, [language]);

  const defaultForm = getDefaultForm();

  const { partnership } = useParams();

  const navigate = useNavigate();

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  const { setValue, control, watch, register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: defaultForm,
  });

  const localHandleClose = () => {
    reset(defaultForm);
    dialogRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const addClientAndAppointmentMongoDB = useCreateUserAndAppointment();

  const onError = (errors, e) => {
    enqueueSnackbar(translate('preAppointment.respondToAll'), { variant: "error", });
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    const {
      Birthday,
      Email,
      Gender,
      Information,
      Name,
      Phone,
      typeOfMassage,
      otherMedicalCondition,
    } = data;

    const preAppointmentRow = {
      'Created At': moment().format('YYYY/MM/DD HH:MM'),
      Birthday,
      Email,
      Gender,
      Information,
      Name,
      Phone,
      typeOfMassage,
      focusOnBodyParts: Object.keys(data?.areasBody).filter((bodyPart) => bodyPart && data.areasBody[bodyPart])?.join(', '),
      medicalConditions: Object.keys(data?.medicalConditions).filter((condition) => condition && data.medicalConditions[condition])?.join(', '),
      otherMedicalCondition,
    };

    const clientRow = {
      'Carimbo de data/hora': moment().format('YYYY/MM/DD HH:MM'),
      'Idade': Birthday,
      'Endereço de email': Email,
      'Sexo': Gender,
      'Nome': Name,
      'Telemóvel': Phone,
      'Registado por': "pre-appointment"
    }

    if (partnership) clientRow['Parceria'] = partnership

    addClientAndAppointmentMongoDB.mutate(
      {
        userData: clientRow,
        appointmentData: preAppointmentRow,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(translate('preAppointment.success'), { variant: "success" });
          setIsLoading(false);
          navigate('/about-us');
        },
        onError: (error) => {
          setIsLoading(false);
          enqueueSnackbar(translate('preAppointment.success'), { variant: "error" });
          throw error;
        }
      }
    );
  };


  const dialogRef = useRef();

  return (
    <MyDialog
      stylesProps={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      scroll="paper"
      fullScreen={true}
    >
      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        <Language />
      </div>
      <div
        ref={dialogRef}
        style={{ width: mobile ? '100%' : '50%', display: 'flex', alignSelf: 'center' }}
      >
        <FormProvider {...{ setValue, register, errors, control, watch }}>
          <div
            style={{
              padding: theme.spacing(3),
              paddingLeft: 20,
              paddingRight: 20,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
              <ClickableLogo />
              <Typography variant="body1" fontWeight={700} color={BONDI_BLUE}>
                <Text id="preAppointment.title" />
              </Typography>
            </div>
            <>
              <Typography variant="body2" style={{ fontWeight: 700 }}>
                <Text id="preAppointment.personalInfo" />
              </Typography>
              <Typography variant="body2"><Text id="preAppointment.name" />*</Typography>
              <TextField
                {...register("Name", { required: translate('preAppointment.nameRequired') })}
                label="Name"
                variant="outlined"
                error={!!errors.Name}
                helperText={errors.Name ? errors.Name.message : null}
              />
              <Typography variant="body2"><Text id="preAppointment.phone" />*</Typography>
              <TextField
                {...register("Phone", { placeholder: "+351986345780", required: translate('preAppointment.phoneNumberRequired') })}
                label={translate('preAppointment.phoneNumber')}
                variant="outlined"
                placeholder="+351986345780"
                error={!!errors.Phone}
                helperText={errors.Phone ? errors.Phone.message : null}
              />
              <Typography variant="body2"><Text id="preAppointment.email" /></Typography>
              <TextField
                {...register("Email")}
                label="Email"
                type="email"
                variant="outlined"
                error={!!errors.Email}
                helperText={errors.Email ? errors.Email.message : null}
              />
              <Typography variant="body2">
                <Text id="preAppointment.birthday" />*
              </Typography>
              <DateFormInputV2
                name={'Birthday'}
                registerOptions={{ required: translate('preAppointment.birthdayRequired') }}
              />
              <FormControl error={!!errors.Gender}>
                <Typography variant="body2"><Text id="preAppointment.gender" />*</Typography>
                <Controller
                  {...register('Gender', { required: translate('preAppointment.genderRequired') })}
                  name={translate('preAppointment.gender')}
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      onChange={(e) => {
                        setValue('Gender', e.target.value);
                      }}
                      aria-labelledby="gender-radio-buttons-group-label"
                    >
                      <FormControlLabel value="Woman" control={<Radio />} label={translate('preAppointment.woman')} />
                      <FormControlLabel value="Men" control={<Radio />} label={translate('preAppointment.men')} />
                    </RadioGroup>
                  )}
                />
                {errors.Gender && <FormHelperText>{errors.Gender.message}</FormHelperText>}
              </FormControl>
              <Typography variant="body2" style={{ fontWeight: 700 }}>
                <Text id="preAppointment.medicalConditionText" />
              </Typography>
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <Alert severity="info">
                  <Typography
                    dangerouslySetInnerHTML={{ __html: translate("preAppointment.paymentCashInfo") }}
                  ></Typography>
                </Alert>
                <FormGroup>
                  {Object.keys(watch('medicalConditions')).map((condition) => {
                    const value = watch('medicalConditions')[condition];
                    return (
                      <FormControlLabel
                        key={condition}
                        control={
                          <Checkbox
                            onChange={(e) => { setValue(`medicalConditions.${condition}`, !value) }}
                            checked={value}
                          />
                        }
                        label={condition}
                      />
                    )
                  })}
                </FormGroup>
                {watch(`medicalConditions.${translate('preAppointment.medicalConditions.others')}`) === true && <FormControl error={!!errors['otherMedicalCondition']}>
                  <FormLabel id="otherMedicalCondition">
                    <Text id="preAppointment.pleaseMedicalCondition" />
                  </FormLabel>
                  <Controller
                    {...register('otherMedicalCondition', { required: translate('preAppointment.otherIsRequired') })}
                    name="otherMedicalCondition"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={translate('preAppointment.otherConditions')}
                        variant="outlined"
                        error={!!errors['otherMedicalCondition']}
                        helperText={errors['otherMedicalCondition'] ? errors['otherMedicalCondition'].message : null}
                      />
                    )}
                  />
                </FormControl>}
              </FormControl>
              <Typography variant="body2" style={{ fontWeight: 700 }}>
                <Text id="preAppointment.preferences" />
              </Typography>
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend">
                  <Text id="preAppointment.areasOfTheBody" />
                </FormLabel>
                <FormGroup>
                  {Object.keys(watch('areasBody')).map((bodypart) => {
                    const value = watch('areasBody')[bodypart];
                    return (
                      <FormControlLabel
                        key={bodypart}
                        control={
                          <Checkbox
                            onChange={(e) => { setValue(`areasBody.${bodypart}`, !value) }}
                            checked={value}
                          />
                        }
                        label={bodypart}
                      />
                    )
                  })}
                </FormGroup>
              </FormControl>

              <FormControl error={!!errors.typeOfMassage}>
                <FormLabel id="typeOfMassage-label">
                  <Text id="preAppointment.massagePreferences" />
                </FormLabel>
                <Controller
                  {...register('typeOfMassage')}
                  name="typeOfMassage"
                  control={control}
                  disabled={isLoading}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      aria-labelledby="typeOfMassage-label"
                    >
                      <FormControlLabel value={translate('preAppointment.preferencesOptions.pressure')} control={<Radio />} label={translate('preAppointment.preferencesOptions.pressure')} />
                      <FormControlLabel value={translate('preAppointment.preferencesOptions.toRelax')} control={<Radio />} label={translate('preAppointment.preferencesOptions.toRelax')} />
                      <FormControlLabel value={translate('preAppointment.preferencesOptions.swollen')} control={<Radio />} label={translate('preAppointment.preferencesOptions.swollen')} />
                      <FormControlLabel value={translate('preAppointment.preferencesOptions.pain')} control={<Radio />} label={translate('preAppointment.preferencesOptions.pain')} />
                    </RadioGroup>
                  )}
                />
                {errors.typeOfMassage && <FormHelperText>{errors.typeOfMassage.message}</FormHelperText>}
              </FormControl>

              <Typography variant="body2" style={{ fontWeight: 700 }}>
                <Text id="preAppointment.extraInformation" />
              </Typography>
              <Alert severity="info">
                <Text id="preAppointment.relevantInformation" />
              </Alert>
              <TextField
                {...register("Information")}
                label={translate('preAppointment.information')}
                variant="outlined"
                disabled={isLoading}
              />

              <Typography variant="body2" style={{ fontWeight: 700 }}>
                <Text id="preAppointment.termsAndConditionsText" />
              </Typography>
              <FormControl
                component="fieldset"
                variant="standard"
                error={errors.termsAndConditions}
              >
                <FormGroup style={{ display: 'flex', gap: theme.spacing(2) }}>
                  {Object.keys(watch('termsAndConditions')).map((condition) => {
                    const conditionObj = watch('termsAndConditions')[condition];
                    return (
                      <FormControlLabel
                        key={condition}
                        disabled={isLoading}
                        control={
                          <Checkbox
                            style={{ color: conditionObj.value ? BONDI_BLUE : errors?.termsAndConditions?.[condition] ? 'red' : 'none' }}
                            {...register(`termsAndConditions.${condition}.value`, { required: true })}
                            onChange={(e) => { setValue(`termsAndConditions.${condition}.value`, !conditionObj.value) }}
                            checked={conditionObj.value}
                          />
                        }
                        label={conditionObj.text}
                      />
                    )
                  })}
                  {errors?.termsAndConditions && <FormHelperText error={errors?.termsAndConditions}>
                    <Text id="preAppointment.checkboxsChecked" />
                  </FormHelperText>}
                </FormGroup>
                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                  <VButton
                    onClick={() => {
                      window.open('/policy/privacy');
                    }}
                    showDot={null}
                    style={{ fontSize: 10, height: 20 }}
                    variant="text"
                  >
                    <Text id="policy.privacy.id" />
                  </VButton>
                  <VButton
                    onClick={() => {
                      window.open('/policy/terms');
                    }}
                    showDot={null}
                    style={{ fontSize: 10, height: 20 }}
                    variant="text"
                  >
                    <Text id="policy.terms.id" />
                  </VButton>
                  <VButton
                    onClick={() => {
                      window.open('/policy/protection');
                    }}
                    showDot={null}
                    style={{ fontSize: 10, height: 20 }}
                    variant="text"
                  >
                    <Text id="policy.protection.id" />
                  </VButton>
                </div>
              </FormControl>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <VButton
                  style={{ alignSelf: "flex-end" }}
                  variant="contained"
                  color="secondary"
                  showDot={null}
                  disabled={isLoading}
                  onClick={() => {
                    localHandleClose();
                  }}
                >
                  <Text id="preAppointment.reset" />
                </VButton>
                <VButton
                  style={{ alignSelf: "flex-end" }}
                  variant="contained"
                  color="primary"
                  showDot={isLoading ? null : 'right'}
                  disabled={isLoading}
                  onClick={() => {
                    handleSubmit(onSubmit, onError)();
                  }}
                >
                  {isLoading
                    ? <CircularProgress sx={{ color: 'white' }} />
                    : <Text id="preAppointment.submit" />
                  }
                </VButton>
              </div>
            </>
          </div>
        </FormProvider >
      </div>
    </MyDialog >
  );
}

export default memo(withSnackbar(PreAppointment));
