import { useBreakpoints } from "react-match-breakpoints";
import { useAuth } from "../../../contexts/AuthProvider";
import { FormProvider, useForm } from "react-hook-form";
import Auth from "../../../components/Modal/Auth";
import { PartnershipReservations } from "../PartnershipReservations";
import { Typography } from "@mui/material";
import { BONDI_BLUE } from "../../../constants.js";
import { translate } from "react-internationalization";

export const PartnershipReservationsWithAuth = () => {
    const { tabletDown } = useBreakpoints();
    const { isLoggedIn, user } = useAuth();

    const { control, watch, register, handleSubmit, reset } = useForm();

    if (!isLoggedIn) {
        return <FormProvider
            {...{ control, register, handleSubmit, reset, watch }}
        >
            <div style={{
                marginTop: 200,
                justifyContent: 'center',
                width: '100%',
                display: 'flex'
            }}>
                <div style={{
                    width: tabletDown ? '90%' : '50%',
                    display: 'flex',
                }}>
                    <div style={{ width: '100%', gap: 10, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Typography fontWeight={'bold'} variant="body1" color={BONDI_BLUE}>{translate('partnership')}</Typography>
                        <Typography variant="caption" style={{ opacity: 0.7 }}>
                            {translate('reservationsMade')}
                            <br />
                            {translate('askForPassword')}
                        </Typography>
                        <Auth />
                    </div>
                </div>
            </div>
        </FormProvider >
    } else {
        return <PartnershipReservations />
    }
}