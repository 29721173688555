import axios from "axios";
import _ from "lodash";
import { getConfig } from "../../config";

export const venturaCareApi = () => {
  return axios.create({
    baseURL: getConfig().VENTURACUIDA_ENDPOINT, // Base URL for all requests
    headers: {
      "Content-Type": "application/json", // Set the request content type
    },
  });
};

export const venturaCuidaApi = () =>
  axios.create({
    baseURL: `${getConfig().VENTURACUIDA_ENDPOINT}/`,
    headers: {
      "Content-Type": "application/json",
      ...(localStorage.getItem("token") && {
        Authorization:
          "Bearer " + localStorage.getItem("token")?.replaceAll('"', ""),
      }),
    },
  });

export const loginAttempt = async ({ email, password }) => {
  try {
    const result = await venturaCuidaApi().post("/companion/auth/login", {
      email,
      password,
    });
    return _.pick(result, "data", "status");
  } catch (error) {
    throw "Wrong password";
  }
};

export const signUpAttempt = async ({ email, password, phone, name }) => {
  const result = await venturaCuidaApi().post("/auth/signup", {
    email,
    password,
    phone,
    name,
  });
  return _.pick(result, "data", "status");
};

export const refreshTokenRequest = async (token) => {
  const result = await venturaCareApi().post("/auth/token", { token });
  return _.pick(result, "data", "status");
};

export const forgotPassword = async (data) => {
  const result = await venturaCareApi()
    .post(
      "/send-email-forgot-password",
      data
    );
  return _.pick(result, "data", "status");
};

export const resetPassword = async ({ id, data }) => {
  const result = await venturaCareApi()
    .post(
      `/companion/auth/forgot/${id}`,
      data
    );
  return _.pick(result, "data", "status");
};


export const getUserData = async () => {
  const result = await venturaCuidaApi().get('/companion/auth/user');
  return _.pick(result, "data", "status");
};


