import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { set, uniq } from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";
import { textEditor } from "react-data-grid";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import { dark } from "@mui/material/styles/createPalette";

// Create the context
export const EditableDataPlotContext = createContext();

export const createRender = (tranformValue) =>
    ({ column, row, onRowChange, onClose, ...props }) => {
        const colBeingChanged = column.key;
        return textEditor({
            column, row, ...props,
            onRowChange: function (row, commitChanges = true) {
                const newValue = row[colBeingChanged];
                const path = colBeingChanged.split('.');
                const newRow = set(row, path, tranformValue(newValue));
                onRowChange(newRow, false);
            },
            onClose: function ({ row, ...props }) {
                onClose({ ...props, row, commitChanges: true })
            }
        });
    }

export const createRenderDropdown = ({ options, processValue, processOnChange, ...propsAutoComplete }) => ({ column, row, onRowChange, onClose }) => {
    return <div style={{ width: '100%', height: '100%' }}>
        <Autocomplete
            selectOnFocus
            value={processValue(row) || null}  // Ensure value is either a valid object or null
            options={options || []}  // Ensure options is always a valid array
            getOptionLabel={(option) => option?.name || ""}  // Ensure option is valid
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Desconhecido"
                    onClick={((e) => { e.stopPropagation() })}
                />
            )}
            onChange={(e, value) => {
                const newRow = processOnChange({ e, value, row });
                // const newRow = { ...row, client: value };
                onRowChange(newRow, true);
            }}
            onClick={() => { }}
            sx={{ display: 'flex', alignItems: 'center', width: "100%", height: '100%' }}
            {...propsAutoComplete}
        />
    </div>
}

export const createRenderDate = (props) => ({ column, row, onRowChange, onClose }) => {
    return <LocalizationProvider dateAdapter={AdapterMoment}>
        < DateTimePicker
            value={row?.date}
            onChange={(e) => { }}
            onAccept={(result) => {
                onRowChange({
                    ...row, date: result.toISOString()
                }, true);
            }}
            ampm={false}
            renderInput={(params) => (
                <TextField
                    sx={{
                        '& .MuiInputBase-input': {
                            padding: 1,
                            height: '100%'  // Remove padding from the input field
                        }
                    }}
                    style={{ width: '100%', height: '100%' }}  // Remove padding from the TextField itself
                    {...params}
                />
            )}
        />

    </LocalizationProvider >
}



// Create a provider component
export const EditableDataPlotProvider = ({ children, rows, roleFilter }) => {
    const [editedRows, setEditedRows] = useState(null);

    const initialRowsFiltred = useMemo(() => rows?.filter(r => r?.role === roleFilter), [roleFilter])

    const changedRows = useMemo(() => {
        const activeRows = roleFilter ? initialRowsFiltred : rows;
        return editedRows?.reduce((accum, row, index) => {
            if (JSON.stringify(row) !== JSON.stringify(activeRows[index])) {
                accum.push(row);
            }
            return accum;
        }, []);
    }, [editedRows]);

    // console.log({ changedRows });

    // Provide state and methods to children
    return (
        <EditableDataPlotContext.Provider
            value={{
                isEdited: !!changedRows,
                editedRows: editedRows || rows,
                setEditedRows,
                changedRows,
                rowKeyGetter: (row) => row?._id,
                reset: () => {
                    setEditedRows(null);
                }
            }}
        >
            {children}
        </EditableDataPlotContext.Provider >
    );
};

// Custom hook for accessing the context
export const useEditableDataPlot = () => {
    const context = useContext(EditableDataPlotContext);
    if (!context) {
        throw new Error("useEditableDataPlot must be used within a EditableDataPlotProvider");
    }
    return context;
};
