import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { NewHeader } from "../../components/NewHomeComponents/NewHeader";
import { NewFooter } from "../../components/NewHomeComponents/NewFooter";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { pages } from "../../constants.js";
import { WhatsAppWidget } from "../../components/WhatsappWidget/index.js";
import { TherapistWidget } from "../../components/TherapistWidget/index.js";
import { AdminWidget } from "../../components/AdminWidget/index.js";
import { NewHome } from "./NewHome/index.js";
import { Dashboard } from "../Dashboard/index.js";

// Define the animated child routes inside the Layout
export const AnimatedLayoutRoutes = ({ routes }) => {
  const [lastLocation, setLastLocation] = useState(null);

  const location = useLocation();
  const currentOutlet = useOutlet();

  // Find the current route and its associated nodeRef
  const { nodeRef } = routes.find((route) => route.path === location.pathname) || {};

  const defaultStyle = {
    transition: "opacity 300ms ease-in-out, transform 250ms ease-in-out",
  };

  const transitionRightStyles = {
    enter: {
      entering: { opacity: 0, transform: "translateX(-500px)" },
      entered: { opacity: 1, transform: "translateX(0px)" },
    },
    exit: {
      exiting: { opacity: 0, transform: "translateX(500px)" },
      exited: { opacity: 0, transform: "translateX(-500px)" },
    }
  };

  const transitionLeftStyles = {
    enter: {
      entering: { opacity: 0, transform: "translateX(500px)" },
      entered: { opacity: 1, transform: "translateX(0px)" },
    },
    exit: {
      exiting: { opacity: 0, transform: "translateX(-500px)" },
      exited: { opacity: 0, transform: "translateX(500px)" },
    }
  };

  const currentTransiton = useMemo(() => {
    const currentPageIndex = pages.findIndex((obj) => obj.href === location.pathname);
    const movement = currentPageIndex >= lastLocation ? 'right' : 'left';
    return movement === 'left'
      ? { ...transitionLeftStyles.enter, ...transitionLeftStyles.exit }
      : { ...transitionRightStyles.enter, ...transitionRightStyles.exit };
  }, [location.pathname]);

  return (<>
    <NewHeader />
    <Suspense>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={300}
          unmountOnExit
          onEnter={() => {
            const lastPageIndex = pages.findIndex((obj) => obj.href === location.pathname)
            setLastLocation(lastPageIndex);
          }}
        >
          {(state) => (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <div
                ref={nodeRef}
                style={{
                  overflowX: 'hidden',
                  ...defaultStyle,
                  ...currentTransiton[state],
                  maxWidth: 1600,
                }}
              >
                {currentOutlet}
              </div>
            </div>
          )}
        </CSSTransition>
      </SwitchTransition >
    </Suspense>
    <NewFooter />
    {
      ['/admin'].includes(location.pathname) ? <AdminWidget />
        : !['/therapist'].includes(location.pathname)
          ? <WhatsAppWidget />
          : <TherapistWidget />
    }
  </>
  );
};


export const DashboardLayout = () => {
  const currentOutlet = useOutlet();
  return <Dashboard>{currentOutlet}</Dashboard>
}