import { Box } from "@mui/material";
import React from "react";

const ImageWithText = ({ children, img, imageStyle, onClick, ...props }) => {

    return (
        <Box
            onClick={onClick}
            sx={{
                width: '100%',
                position: 'relative',
                backgroundImage: `url(${img})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                cursor: 'default',
                ...onClick && {
                    "&:hover": {
                        cursor: 'pointer'
                    }
                },
                ...imageStyle,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};

export default ImageWithText;
