import { venturaCareApi } from "../../api/venturaCuida";

export const useSendGrid = () => {
  const sendEmail = (data) => {
    venturaCareApi()
      .post("/send-email", data)
      .then(() => {
        // console.log("Email sent");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return sendEmail;
};
