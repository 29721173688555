import { Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Text } from "react-internationalization";
import ContactForm from "../../ContactForm";
import { CLOUD_BURST, MAIN_FONT } from "../../../constants.js/index.js";
import { BlurContext } from "../../Blur";
import { useBreakpoints } from "react-match-breakpoints";
import VButton from "../../V/VButton/index.js";
import { MyDialog } from "../MyDialog";

const HeaderComponentDefault = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.spacing(2),
        marginRight: theme.spacing(3),
      }}
    >
      <img
        loading="lazy"
        width="40px"
        height={"100%"}
        src="images/logo_circulo.webp"
        alt="Home page"
      />
      <Typography
        variant="body1"
        style={{
          fontFamily: MAIN_FONT,
          color: CLOUD_BURST,
          fontWeight: 700,
        }}
      >
        <Text id="bookSession.evaluation.title" />
      </Typography>
    </div>
  );
};

const ContactFormModal = ({
  open,
  handleClose,
  HeaderComponent = HeaderComponentDefault,
  ...props
}) => {
  const theme = useTheme();
  const { setBlur } = useContext(BlurContext);
  const { mobile } = useBreakpoints();

  useEffect(() => {
    setBlur(open);
  }, [open, setBlur]);

  return (
    <MyDialog open={open} onClose={handleClose}>
      <ContactForm
        handleClose={handleClose}
        fieldsRequiredProps={{ message: false }}
        HeaderComponent={HeaderComponent}
        CTAs={({ isLoading }) => (
          <div
            style={{
              ...(mobile
                ? {
                  position: "fixed",
                  bottom: 20,
                  right: 0,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                }
                : {
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: theme.spacing(2),
                }),
            }}
          >
            <VButton
              disabled={isLoading}
              variant="contained"
              color="secondary"
              onClick={handleClose}
              style={{
                alignSelf: "flex-start",
                paddingRight: 30,
                paddingLeft: 30,
              }}
              showDot={"left"}
            >
              <Text id="contactUs.close" />
            </VButton>
            <VButton
              disabled={isLoading}
              variant="contained"
              rel="noreferrer"
              style={{
                alignSelf: "flex-start",
                paddingRight: 30,
                paddingLeft: 30,
              }}
              type="submit"
            >
              <Text id="contactUs.send" />
            </VButton>
          </div>
        )}
        {...props}
      />
    </MyDialog>
  );
};

export default ContactFormModal;
