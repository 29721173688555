import { Typography, useTheme } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useBreakpoints } from "react-match-breakpoints";
import ImageWithText from "../../../../components/ImageWithText";
import { Text } from "react-internationalization";
import { getBlogContent } from "..";
import { color } from "framer-motion";
import { BONDI_BLUE, BRIGHT_GRAY, CLOUD_BURST } from "../../../../constants.js";
import { ClickableLogo } from "../../../../components/ClickableLogo";
import './index.css';

const BlogReader = () => {
  const { id } = useParams();
  const { mobile, tabletDown } = useBreakpoints();
  const getBlogContentType = (type) => getBlogContent(id, type);
  const { spacing } = useTheme();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{
        borderRadius: 4,
        margin: spacing(2),
        padding: spacing(3),
        backgroundColor: 'white',
        width: tabletDown ? "100%" : "70%"
      }}>
        <ImageWithText
          imageStyle={{
            backgroundImage: `linear-gradient(0deg, rgba(37 61 74 / 90%), rgba(37 61 74 / 70%)), url(${getBlogContentType(
              "img"
            )})`,
            backgroundSize: "cover",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: mobile ? "flex-start" : "center",
              gap: 5,
              justifyContent: "center",
              fontFamily: "Alfa Slab One",
              height: 300,
              padding: "20px",
            }}
          >
            {tabletDown && <ClickableLogo boxStyles={{ alignSelf: 'center' }} imgStyles={{ width: 120 }} />}
            <Typography
              variant="h1"
              style={{ textAlign: 'center', fontSize: tabletDown ? 40 : 50, fontFamily: "inherit" }}
              color={BONDI_BLUE}
            >
              {getBlogContentType("title")}
            </Typography>
            <div
              style={{
                padding: "10px 0",
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: mobile ? "flex-start" : "center",
              }}
            >
              <Typography
                variant="body2"
                style={{ fontFamily: "inherit", color: BRIGHT_GRAY }}
              >
                <Text id="author" />: {getBlogContentType("createdBy")}
              </Typography>
              <Typography
                variant="body2"
                style={{ fontFamily: "inherit", color: BRIGHT_GRAY }}
              >
                <Text id="date" />: {getBlogContentType("createdAt")}
              </Typography>
            </div>
          </div>
        </ImageWithText>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "50px 0",
          }}
        >
          <Typography
            dangerouslySetInnerHTML={{ __html: getBlogContentType("text") }}
            style={{
              maxWidth: mobile ? "90%" : "70%",
              lineHeight: 1.5,
              textAlign: "justify",
              textJustify: "inter-word",
              color: CLOUD_BURST,
            }}
          ></Typography>
        </div>
      </div>
    </div >
  );
};

export default BlogReader;
