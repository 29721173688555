import React, { useCallback, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import {
  BONDI_BLUE,
  CLOUD_BURST,
  GUNMETAL,
} from "../../../constants.js/index.js";
import ServicesComponent from "../../../components/ServicesComponent/index.js";
import ContactFormModal from "../../../components/Modal/ContactFormModal/index.js";
import { useBreakpoints } from "react-match-breakpoints";
import { Text, translate } from "react-internationalization";
import { useResponsive } from "../../../hooks/useResponsive/index.js";
import { Advantages } from "../../../components/NewHomeComponents/Advantages/index.js";
import { Section } from "../../../components/NewHomeComponents/Section/index.js";
import ImageWithText from "../../../components/ImageWithText/index.js";
import { filter } from "lodash";
import hexRgb from "hex-rgb";
import opacity from "hex-color-opacity";
import { useLanguage } from "../../../contexts/Language/index.js";

function getItemsDesk() {
  return [
    { title: 'workMethods.byBooking.title', description: 'workMethods.byBooking.description' },
    { title: 'workMethods.coupleMassage.title', description: 'workMethods.coupleMassage.description' },
    { title: 'workMethods.substitution.title', description: 'workMethods.substitution.description' },
    { title: 'workMethods.fullTime.title', description: 'workMethods.fullTime.description' },
  ];
}

const Services = () => {

  const { language } = useLanguage();
  const getItems = useCallback(getItemsDesk, [translate, language]);

  const { spacing, ...theme } = useTheme();
  const { tabletDown } = useBreakpoints();
  const [evaluationModal, setEvaluationModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const res = useResponsive();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        backgroundColor: GUNMETAL,
      }}
    >
      <ContactFormModal
        open={evaluationModal}
        handleClose={(event, reason) => {
          setEvaluationModal(false);
        }}
      />
      <ContactFormModal
        open={openModal}
        handleClose={(event, reason) => {
          setOpenModal(false);
        }}
        HeaderComponent={() => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: spacing(2),
              marginRight: spacing(3),
            }}
          >
            <img
              width="40px"
              height={"100%"}
              src="images/logo_circulo.webp"
              alt="Home page"
            />
            <Typography
              variant="body1"
              style={{
                color: CLOUD_BURST,
                fontWeight: 700,
              }}
            >
              <Text id="pricing.priceQuote" />
            </Typography>
          </div>
        )}
      />
      <ImageWithText
        imageStyle={{
          backgroundImage: `linear-gradient(34deg, ${hexRgb(GUNMETAL, { format: "css", alpha: 0.8 })} 46%, ${hexRgb(GUNMETAL, { format: "css", alpha: 0.8 })} 100%), url(/images/hotel-airbnb.webp)`,
          height: 400,
          mixBlendMode: 'normal',
          objectFit: 'contain', width: '100%',
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: 'center',
            gap: spacing(2),
            height: '100%',
            width: '100%',
            mixBlendMode: 'difference',
            textAlign: 'center'
          }}
        >
          <Typography
            fontSize={res('5vw', '4vw', '3vw')}
            fontWeight={700}
            style={{
              color: 'white',
            }}
          >
            <Text id="services.ourServicesTitle" />
          </Typography>
          <Typography
            style={{
              maxWidth: 400,
            }}
            fontSize={res('3vw', '2vw', '1.5vw')}
            color={'white'}
            fontFamily={"Poppins"}
          >
            <Text id="services.enhance"></Text>
          </Typography>
        </div>
      </ImageWithText>
      <div style={{ marginTop: spacing(10) }}></div>
      <ServicesComponent />
      <div style={{ marginTop: spacing(10) }}></div>
      <div style={{ width: '100%', padding: spacing(5), backgroundColor: opacity('#FFFFFF', 0.1) }}>
        <Section text={translate('workMethods.title')}>
          <div style={{
            width: res('100%', '80%', '80%'),
            justifyContent: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            gap: spacing(1)
          }}>
            <Advantages
              mobileItems={getItems}
              descktopItems={getItems}
            />
          </div>
        </Section >
      </div>
    </div >
  );
};

export default Services;
