import { Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DARK_BLUE_GRAY, GUNMETAL, pages, SERVICES } from "../../../constants.js";
import opacity from "hex-color-opacity";
import { map } from "lodash";
import { useBreakpoints } from "react-match-breakpoints";
import { Text } from "react-internationalization";
import VButton from "../../V/VButton/index.js";
import { useResponsive } from "../../../hooks/useResponsive/index.js";

export const NewFooter = () => {

    const { spacing } = useTheme();
    const theme = useTheme();

    const navigate = useNavigate();

    const { tabletDown } = useBreakpoints();

    const res = useResponsive();

    return (
        <>
            <div style={{
                padding: spacing(3),
                borderBottom: `1px solid ${opacity('#FFFFFF', 0.2)}`,
                borderTop: `1px solid ${opacity('#FFFFFF', 0.2)}`,
                backgroundColor: GUNMETAL,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: spacing(res(4, 10)),
                flexWrap: 'wrap'
            }}>
                <div style={{ gap: spacing(2), display: 'flex', flexDirection: 'column' }}>
                    <img
                        loading="lazy"
                        src={`${window.location.origin}/images/logo/logo_branco.png`}
                        onClick={() => navigate("/")}
                        width="100px"
                        style={{ objectFit: 'contain', marginRight: 10 }}
                        alt="Home page"
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ opacity: 0.4 }} variant="caption" color={'white'}>
                            Rua do outeiro n7 Vila verde de ficalho 7830-654
                        </Typography>
                        <Typography style={{ opacity: 0.4 }} variant="caption" color={'white'}>
                            +351 928 095 512
                        </Typography>
                        <Typography style={{ opacity: 0.4 }} variant="caption" color={'white'}>
                            venturacuida@gmail.com
                        </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                        <a href="/pdf/certidao-ers.pdf" target="blank">
                            <img
                                loading="lazy"
                                src="/images/ers.webp"
                                alt="VenturaCuida"
                                width={70}
                            />
                        </a>
                        <a href="https://www.livroreclamacoes.pt" target="blank">
                            <div
                                style={{
                                    padding: 20,
                                    borderRadius: 50,
                                }}
                            >
                                <img
                                    loading="lazy"
                                    src="/images/reclamacao/reclamacao.png"
                                    alt="VenturaCuida"
                                    width={100}
                                />
                            </div>
                        </a>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: spacing(6) }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: 200,
                            gap: 10,
                        }}
                    >
                        <Typography
                            style={{ fontSize: 20, marginBottom: 10, fontWeight: 700 }}
                            color={'white'}
                        >
                            Useful Links
                        </Typography>
                        {map(pages, ({ pageName, href }, index) => (
                            <Link
                                href={href}
                                component="button"
                                underline="none"
                                key={"links-" + index}
                                style={{
                                    fontSize: tabletDown ? 13 : 16,
                                    fontWeight: 500,
                                    color: 'white',
                                    opacity: 0.5
                                }}
                                to={href}
                                onClick={() => {
                                    navigate(href);
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    });
                                }}
                            >
                                <Text id={pageName} />
                            </Link>
                        ))}
                    </div>
                    <div
                        id="third"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: 200,
                            gap: 10,
                        }}
                    >
                        <Typography
                            style={{ fontSize: 20, marginBottom: 10, fontWeight: 700 }}
                            color={'white'}
                        >
                            Services
                        </Typography>
                        {map(SERVICES.slice(0, 4), ({ name, id }, index) => (
                            <Link
                                key={"services-" + index}
                                component="button"
                                underline="none"
                                style={{
                                    fontSize: tabletDown ? 13 : 16,
                                    fontWeight: 500,
                                    color: 'white',
                                    opacity: 0.5
                                }}
                                to={{
                                    pathname: "/services",
                                    hash: id,
                                }}
                            >
                                <Text id={name} />
                            </Link>
                        ))}
                    </div>
                </div>
            </div >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '20vh',
                flexDirection: 'column',
                backgroundColor: GUNMETAL,
                gap: spacing(1),
            }}>
                <Typography
                    color={'white'}
                    style={{
                        fontSize: 16,
                        fontFamily: "Poppins",
                        letterSpacing: -0.16,
                    }}
                >
                    VenturaCuida {new Date().getFullYear()}
                </Typography>
                <span
                    style={{
                        fontSize: 14,
                        color: DARK_BLUE_GRAY,
                        display: "flex",
                        alignItems: "baseline",
                    }}
                >
                    Website desenvolvido por{" "}
                    <VButton
                        onClick={() => {
                            window.open("https://www.camaraspark.com");
                        }}
                        showDot={null}
                        style={{ padding: theme.spacing(1), marginTop: 3.5 }}
                        variant="text"
                    >
                        David Câmara
                    </VButton>
                </span>
            </div >
        </>)
}