import { TextField, Typography, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import VButton from "../../V/VButton";
import { useAuth } from "../../../contexts/AuthProvider";
import { forgotPassword, loginAttempt } from "../../../api/venturaCuida";
import { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { translate } from "react-internationalization";

const Auth = ({
  localHandleClose,
  enqueueSnackbar,
  onAuthSuccess,
}) => {
  const [isForgotPassword, setForgotPassword] = useState(false);
  const { register, handleSubmit } = useFormContext();
  const theme = useTheme();
  const { authenticate } = useAuth();

  const { isLoggedIn } = useAuth();
  useEffect(() => { isLoggedIn && onAuthSuccess() }, []);


  const authSubmit = async ({ email, password }) => {
    const { data } = await loginAttempt({
      email,
      password
    });

    const { token: accessToken, user } = data;

    authenticate({
      accessToken,
      user,
    })

    onAuthSuccess?.();
  };

  const forgotSubmit = async ({ email }) => {
    try {
      const result = await forgotPassword({ email });
      enqueueSnackbar('Verifique o seu email.', {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro', {
        variant: "error",
      });
    }
  };

  return (
    <>
      <TextField
        {...register("email", { required: true })}
        label="Email"
        variant="outlined"
      />
      {!isForgotPassword && <TextField
        {...register("password", { required: true })}
        label={"Password"}
        variant="outlined"
      />}
      <VButton variant="text" onClick={() => { setForgotPassword(!isForgotPassword) }}>
        {!isForgotPassword
          ? <Typography style={{ fontSize: 10 }} variant="caption">{translate('forgotPassword')}</Typography>
          : <Typography style={{ fontSize: 10 }} variant="caption">{translate('login')}</Typography>
        }
      </VButton>
      <div
        style={{
          display: "flex",
          gap: theme.spacing(1),
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {localHandleClose ? <VButton
          style={{ alignSelf: "flex-end" }}
          variant="contained"
          color="secondary"
          showDot="left"
          onClick={() => localHandleClose()}
        >
          {translate('close')}
        </VButton> : <div></div>}
        <VButton
          style={{ alignSelf: "flex-end" }}
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit(isForgotPassword ? forgotSubmit : authSubmit)();
          }}
        >
          {isForgotPassword ? translate('recoverPassword') : translate('submit')}
        </VButton>
      </div >
    </>
  );
};

export default withSnackbar(Auth);