import { Typography } from "@mui/material";
import { CULTERED } from "../../../../../constants.js/index.js";
import { CopyAllOutlined, EmailOutlined, Link, Person } from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';
import { withSnackbar } from "notistack";
import { memo } from "react";
import Business from "@mui/icons-material/Business.js";
import HomeIcon from '@mui/icons-material/Home';
import { useMutation } from "react-query";
import { venturaCareApi, venturaCuidaApi } from "../../../../../api/venturaCuida/index.js";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

export const useTherapistShipLink = () => {
    return useMutation({
        mutationFn: async (email) => {
            const link = await venturaCuidaApi().post(`/api/rot`, { email });
            return link?.data;
        },
    });
};

function TherapistItem({ therapist, ...props }) {

    const { enqueueSnackbar } = props;

    const getTherapistLink = useTherapistShipLink();

    const handleClick = (email) => {
        getTherapistLink.mutate(
            email,
            {
                onSuccess: (data) => {
                    navigator.clipboard.writeText(data);
                    enqueueSnackbar("Link copiado", { variant: "success" });
                },
                onError: (error) => {
                    // console.log({ error });
                    throw error;
                }
            }
        );
    };

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: CULTERED,
                padding: 20,
                borderRadius: 3,
                position: 'relative',
            }}
        >
            <div style={{ flexDirection: 'column', display: 'flex', gap: 8 }}>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <Person />
                    <Typography
                        style={{}}
                    >
                        {therapist?.['Nome']}
                    </Typography>
                </div>
                {therapist?.Information && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <InfoIcon />
                    <Typography>{therapist?.Information}</Typography>
                </div>}
                {therapist?.morada && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <HomeIcon />
                    <Typography>{therapist?.morada}</Typography>
                </div>}
                {therapist?.['Endereço de email'] && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <EmailOutlined />
                    <Typography>{therapist['Endereço de email']}</Typography>
                    <IconButton onClick={() => {
                        enqueueSnackbar("Email copiado", { variant: "success" });
                        navigator.clipboard.writeText(therapist['Endereço de email'])
                    }}>
                        <CopyAllOutlined />
                    </IconButton>
                </div>}
            </div>
        </div >
    )
}

export default memo(withSnackbar(TherapistItem));