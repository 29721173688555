import { Box } from '@mui/material';
import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MaterialTable from '../../../../components/Table';
import PreAppointmentItem from '../Mobile/PreAppointmentItem';
import { map } from 'lodash';
import { useBreakpoints } from 'react-match-breakpoints';
import PartnerShipItem from '../Mobile/PartnerShipItem';
import { Therapist } from '../../Therapist';
import TherapistItem from '../Mobile/TherapistItem';

const Desktop = ({
    sessionsPlot,
    clientsPlot,
    preAppointmentPlot,
    partnershipsPlot,
    therapistsPlot
}) => {
    const { mobile } = useBreakpoints();

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 20,
        }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Tabs>
                    <TabList>
                        <Tab>Sessões</Tab>
                        <Tab>Clientes</Tab>
                        <Tab>Pré-Massagem</Tab>
                        <Tab>Parcerias</Tab>
                        <Tab>Terapeutas</Tab>
                    </TabList>
                    <TabPanel>
                        <MaterialTable {...{ rows: sessionsPlot.rows, columns: sessionsPlot.cols }} />
                    </TabPanel>
                    <TabPanel>
                        <MaterialTable {...{ rows: clientsPlot?.rows, columns: clientsPlot?.cols }} />
                    </TabPanel>
                    <TabPanel>
                        <div style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                            {map(preAppointmentPlot?.rows || [], (preAppointment, index) => {
                                return (
                                    <div style={{ width: mobile ? '100%' : '40%' }} key={`preAppointment-${index}`}>
                                        <PreAppointmentItem
                                            {...{ preAppointment }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                            {map(partnershipsPlot?.rows || [], (partnership, index) => {
                                return (
                                    <div style={{ width: mobile ? '100%' : '40%' }} key={`preAppointment-${index}`}>
                                        <PartnerShipItem partnership={partnership} />
                                    </div>
                                )
                            })}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                            {map(therapistsPlot?.rows || [], (therapist, index) => {
                                return (
                                    <div style={{ width: mobile ? '100%' : '40%' }} key={`preAppointment-${index}`}>
                                        <TherapistItem therapist={therapist} />
                                    </div>
                                )
                            })}
                        </div>
                    </TabPanel>
                </Tabs>
            </Box>
        </div >

    )
}

export default Desktop;