import { useEffect, useState } from "react";
import EvaluationForm from "../../../components/Modal/EvaluationForm";
import VButton from "../../../components/V/VButton";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RemoveIcon from "@mui/icons-material/Remove";
import { BONDI_BLUE, CLOUD_BURST, GUNMETAL, MAIN_FONT } from "../../../constants.js";
import "./index.css";
import { Fab, List, ListItem, Typography, useTheme } from "@mui/material";
import { Text, translate } from "react-internationalization";
import { useBreakpoints } from "react-match-breakpoints";
import Typewriter from "typewriter-effect";
import ContactFormModal from "../../../components/Modal/ContactFormModal/index.js";
import { ClickableLogo } from "../../../components/ClickableLogo/index.js";
import { useLocation } from "react-router-dom";

export const Therapist = () => {
  const theme = useTheme();
  const { tabletDown } = useBreakpoints();
  const location = useLocation();

  const description = [
    "therapist.paymentFair",
    "therapist.nonExclusivity",
    "therapist.flexibility",
    "therapist.airtable",
  ];

  useEffect(() => {
    const openModal = new URLSearchParams(location?.search)?.get("modal");
    (openModal === 'evaluation') && setEvaluationModal(true);
    (openModal === 'plan') && setTherapyModal(true);
  }, [location]);

  const [openContactModal, setOpenContactModal] = useState(false);

  return (
    <div style={{ backgroundColor: GUNMETAL, width: "100%", display: "flex", flexDirection: "column" }}>
      <ContactFormModal
        open={openContactModal}
        handleClose={(event, reason) => {
          setOpenContactModal(false);
        }}
        HeaderComponent={() => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: theme.spacing(2),
                marginRight: theme.spacing(3),
              }}
            >
              <img
                loading="lazy"
                width="40px"
                height={"100%"}
                src="images/logo_circulo.webp"
                alt="Home page"
              />
              <Typography
                variant="body1"
                style={{
                  color: CLOUD_BURST,
                  fontWeight: 700,
                }}
              >
                <Text id="therapist.workWithUs" />
              </Typography>
            </div>
          );
        }}
      />
      <section>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: tabletDown ? theme.spacing(2) : 30,
            marginBottom: tabletDown ? theme.spacing(2) : 30,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: tabletDown ? 90 : 100,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: tabletDown ? theme.spacing(2) : theme.spacing(10),
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(2),
                }}
              >
                <Typography
                  id="top"
                  component={"h1"}
                  variant="h1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: tabletDown ? "30px" : "74px",
                    lineHeight: tabletDown ? "50px" : "74px",
                  }}
                >
                  <span style={{ color: 'white' }}>
                    <Text id="therapist.workUs"></Text>
                  </span>
                  <span
                    style={{ color: BONDI_BLUE, width: tabletDown ? 300 : 700 }}
                  >
                    <Typewriter
                      options={{
                        strings: [
                          translate("therapist.physiotherapist") + " 🩼",
                          translate("therapist.massagist") + " 💆🏻",
                        ],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 4,
                        delay: 20,
                      }}
                    />
                  </span>
                </Typography>
                <div style={{ display: "flex", gap: theme.spacing(1) }}>
                  <Typography
                    color={'white'}
                    style={{ opacity: 0.7, fontSize: 16, fontWeight: 700 }}
                    variant="h2"
                  >
                    <Text id="therapist.location1"></Text>{" "}
                    <img
                      loading="lazy"
                      src="images/icons/location.png"
                      style={{ height: 20, width: "auto" }}
                      alt="region"
                    ></img>
                    <Text id="therapist.location2"></Text>{" "}
                    <img
                      loading="lazy"
                      src="images/icons/location.png"
                      style={{ height: 20, width: "auto" }}
                      alt="region"
                    ></img>
                    <Text id="therapist.location3"></Text>
                  </Typography>
                </div>
              </div>
              <section
                style={{
                  padding: 20,
                  background: BONDI_BLUE,
                  borderRadius: 10,
                  width: '100%',
                  height: '100%',
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div style={{ display: "flex", gap: theme.spacing(1) }}>
                  {!tabletDown && (
                    <img
                      loading="lazy"
                      src="images\pilates.webp"
                      style={{ width: 300, height: "auto", borderRadius: 4 }}
                    ></img>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: theme.spacing(2),
                      alignItems: "center",
                    }}
                  >
                    <List
                      sx={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        maxWidth: 250,
                      }}
                    >
                      {description?.map((d, index) => {
                        return (
                          <ListItem
                            key={"description-" + index}
                            sx={{
                              color: "white",
                              padding: 0,
                              textAlign: "left",
                              listStyleType: "disc",
                              display: "list-item",
                              fontFamily: MAIN_FONT,
                              fontSize: 15,
                            }}
                          >
                            <Text id={d}></Text>
                          </ListItem>
                        );
                      })}
                    </List>
                    <VButton
                      variant="contained"
                      color="secondary"
                      showDot="up"
                      onClick={() => setOpenContactModal(true)}
                    >
                      <Text id={'home.contactUs'}></Text>
                    </VButton>
                  </div>
                  {!tabletDown && (
                    <img
                      loading="lazy"
                      src="images\services\massage.jpg"
                      style={{ width: 300, height: "auto", borderRadius: 4 }}
                    ></img>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
