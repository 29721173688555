import { Typography, useTheme } from "@mui/material"
import { PLAYFAIR } from "../../../constants.js";
import { Text } from "react-internationalization";

export const Section = ({ text, containerProps, textProps, children }) => {
    const { spacing } = useTheme();

    return <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', gap: spacing(3), ...containerProps }}>
        <Typography style={{ fontFamily: PLAYFAIR, color: 'white', textAlign: 'center', ...textProps }} variant="h3">
            <Text id={text}/>
            </Typography>
        {children}
    </div>
}