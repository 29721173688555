import {
  Alert,
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import VButton from "../../V/VButton";
import { withSnackbar } from "notistack";
import { MyDialog } from "../MyDialog";
import { useAddSheetRowData, useSheet } from "../../../hooks/useVenturaCuidaSheet";
import { BONDI_BLUE, VENTURACARE_SPREADSHEET } from "../../../constants.js";
import moment from "moment/moment";
import { DateAndHour } from "../DateAndHour/index.js";
import _ from "lodash";

function ScheduleSession({ onSubmitDataProcessing = (data) => data, showReset = true, hardCodedValues = {}, open = false, handleClose, enqueueSnackbar }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const valueToPayRef = useRef();

  const { data: workers, ...workersHook } = useSheet(VENTURACARE_SPREADSHEET.tabs.Fisioterapeutas, { enabled: open });
  const { data: partnerships, ...partnershipsHook } = useSheet(VENTURACARE_SPREADSHEET.tabs.Parcerias, { enabled: open });
  const { data: precario, ...precarioHook } = useSheet(VENTURACARE_SPREADSHEET.tabs.Precario, { enabled: open });
  const { data: clients, ...clientsHook } = useSheet(VENTURACARE_SPREADSHEET.tabs.Clients, { enabled: open });
  const { data: massageClients, ...massageClientsHook } = useSheet(VENTURACARE_SPREADSHEET.tabs.PreMassagem, { enabled: open });
  const { data: preAppointment, ...preAppointmentHook } = useSheet(VENTURACARE_SPREADSHEET.tabs.PreAppointment, { enabled: open });

  const isLoadingAll = useMemo(() => {
    return workersHook.isLoading || partnershipsHook.isLoading || precarioHook.isLoading || massageClientsHook.isLoading || preAppointmentHook.isLoading;
  }, [workersHook, partnershipsHook, precarioHook, clientsHook, massageClientsHook]);

  const { control, register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      "Nome do cliente": 'Desconhecido',
      "Pago em dinheiro": false,
    },
    shouldUnregister: false,
  });

  useEffect(() => {
    reset({
      "Nome do cliente": 'Desconhecido',
      "Pago em dinheiro": false,
      ...hardCodedValues,
    })
  }, []);

  const providerName = watch("Prestador");
  const service = watch("Serviço");
  const partnership = watch("Parceria");
  const client = watch("Nome do cliente");

  useEffect(() => {
    if (providerName) {
      const worker = workers?.data?.find((w) => w.Nome === providerName);
      setValue("Email Address", worker?.["Endereço de email"], { shouldTouch: true });
    } else {
      setValue("Email Address", "");
    }
  }, [providerName]);

  useEffect(() => {
    if (partnership && service) {
      const price = precario?.data?.find(
        (p) => p["Parceria"] === partnership && p["Serviço"] === service
      );
      setValue("Valor a pagar", price?.["Valor a pagar ao terapeuta"] || null, { shouldTouch: true, shouldValidate: true });
      valueToPayRef.current.focus();
      valueToPayRef.current.value = price?.["Valor a pagar ao terapeuta"];
      valueToPayRef.current.innerHTML = price?.["Valor a pagar ao terapeuta"];
    }
  }, [partnership, service]);

  const localHandleClose = () => {
    handleClose();
  };

  const addReservation = useAddSheetRowData(VENTURACARE_SPREADSHEET.tabs.Reservas);

  const onError = (errors, e) => {
    enqueueSnackbar("Aconteceu um erro", { variant: "error" });
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const now = moment().format("YYYY/MM/DD hh:mm");

    const price = precario?.data?.find(
      (p) => p["Parceria"] === data['Parceria'] && p["Serviço"] === data['Serviço']
    );

    const newRow = {
      Timestamp: now,
      ...data,
      'Serviço preço total': price['Preço total'],
      'Pago em dinheiro': data['Pago em dinheiro'] ? 'VERDADEIRO' : ' FALSO',
    };

    newRow["Estado"] = moment(newRow["Data da sessão"].toLocaleString()).isAfter(moment())
      ? "Pendente"
      : "Efetuado";

    addReservation.mutate(onSubmitDataProcessing(newRow), {
      onSuccess: () => {
        enqueueSnackbar("Registado com sucesso", { variant: "success" });
        localHandleClose();
        setIsLoading(false);
      },
      onError: (error) => {
        enqueueSnackbar("Aconteceu um erro ao registrar", { variant: "error" });
        setIsLoading(false);
      },
    });
  };

  const clientNames = useMemo(() => {
    const result = _.uniq([...(preAppointment?.data || []), ...(massageClients?.data || []), ...(clients?.data || [])]?.map((e) => e?.Nome || e?.Name)) || [];
    return result.filter((v) => v);
  }, [preAppointment, massageClients, clients])

  const paidWithMoney = watch('Pago em dinheiro');

  return (
    <MyDialog
      stylesProps={{ width: "100%" }}
      open={open}
      onClose={localHandleClose}
      scroll="paper"
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div
        style={{
          padding: theme.spacing(3),
          paddingLeft: 20,
          paddingRight: 20,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Typography variant="body1" fontWeight={700} color={BONDI_BLUE}>
            Marcação de sessão
          </Typography>
          {showReset && <VButton
            showDot={null}
            onClick={() => {
              reset({
                "Prestador": null,
                "Serviço": null,
                "Parceria": null,
                "Nome do cliente": 'Desconhecido',
                "Data da sessão": null,
                "Valor a pagar": null,
                "Hora": null,
                "Observações": null,
                "Desconto": null,
              });
            }}>
            <RestartAltIcon />
          </VButton>}
        </div>
        {!isLoadingAll && <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <Typography variant="body2">Prestador</Typography>
          <div style={{ display: "flex", gap: theme.spacing(2) }}>
            <Controller
              {...register('Prestador', { disabled: hardCodedValues['Prestador'], required: 'Selecione uma prestador' })}
              control={control}
              name="Prestador"
              render={({ field }) => (
                <Autocomplete
                  disabled={hardCodedValues['Prestador'] || isLoadingAll}
                  value={providerName}
                  options={workers?.data?.map((e) => e?.Nome)}
                  renderInput={(params) => (
                    <TextField {...params} label="Prestador" error={!!errors.Prestador} helperText={errors.Prestador ? "Prestador é obrigatório" : null} />
                  )}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                  sx={{ width: "100%" }}
                />
              )}
            />
            <TextField
              style={{ width: "100%" }}
              disabled
              {...register("Email Address", {
                disabled: hardCodedValues["Email Address"],
                required: true
              })}
              variant="outlined"
            />
          </div>
          <Typography variant="body2">Parceria</Typography>
          <Controller
            {...register('Parceria', { required: 'Selecione uma parceria' })}
            control={control}
            name="Parceria"
            render={({ field }) => (
              <Autocomplete
                disabled={isLoadingAll}
                options={partnerships?.data?.map((p) => p["Nome da empresa"]) || []}
                renderInput={(params) => (
                  <TextField {...params} label="Parceria" error={!!errors.Parceria} helperText={errors.Parceria ? "Parceria é obrigatória" : null} />
                )}
                value={partnership}
                onChange={(e, value) => field.onChange(value)}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Typography variant="body2">Serviço</Typography>
          <div style={{ display: "flex", gap: theme.spacing(2) }}>
            <Controller
              {...register('Serviço', { required: 'Selecione um serviço' })}
              control={control}
              name="Serviço"
              render={({ field }) => (
                <Autocomplete
                  disabled={!partnership || isLoadingAll}
                  value={service}
                  options={precario?.data?.filter((e) => e?.Parceria === partnership)?.map((e) => e?.['Serviço'])}
                  renderInput={(params) => (
                    <TextField {...params} label="Serviço" error={!!errors.Serviço} helperText={errors.Serviço ? "Serviço é obrigatório" : null} />
                  )}
                  onChange={(e, value) => field.onChange(value)}
                  sx={{ width: "100%" }}
                />
              )}
            />
            <TextField
              disabled={isLoadingAll}
              type="number"
              style={{ width: "70%" }}
              {...register("Desconto", { valueAsNumber: true })}
              label="Desconto"
              variant="outlined"
            />
          </div>
          <TextField
            inputProps={{
              ref: valueToPayRef,
              value: watch("Valor a pagar"),
            }}
            disabled={isLoadingAll}
            type="number"
            style={{ width: "100%" }}
            value={watch("Valor a pagar")}
            {...register("Valor a pagar", { required: true, valueAsNumber: true })}
            label="Valor a pagar ao terapeuta"
            variant="outlined"
            error={!!errors["Valor a pagar"]}
            helperText={errors["Valor a pagar"] ? "Valor a pagar é obrigatório" : null}
          />
          <FormControlLabel
            key={'paidWithMoney'}
            control={
              <Checkbox
                onChange={(e) => { setValue(`Pago em dinheiro`, !paidWithMoney) }}
                checked={paidWithMoney}
              />
            }
            label={'Terapeuta recebeu dinheiro do cliente'}
          />
          {paidWithMoney && <Alert severity="info">O dinheiro recebido será descontado no final do mês dos serviços que forneceu á VenturaCuida</Alert>}
          <Typography variant="body2">Cliente</Typography>
          <Controller
            control={control}
            name="Nome do cliente"
            render={({ field }) => (
              <Autocomplete
                disabled={isLoadingAll}
                value={client}
                options={clientNames}
                renderInput={(params) => (
                  <TextField {...params} label="Cliente" error={!!errors["Nome do cliente"]} helperText={errors["Nome do cliente"] ? "Cliente é obrigatório" : null} />
                )}
                onChange={(e, value) => field.onChange(value)}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Typography variant="body2">Data/Hora</Typography>
          <FormProvider {...{ control }}>
            <DateAndHour />
          </FormProvider>
          <TextField
            disabled={isLoadingAll}
            {...register("Observações")}
            multiline
            minRows={3}
            label="Observações"
            variant="outlined"
          />
          <div style={{ display: "flex", gap: 10, alignSelf: "flex-end" }}>
            <VButton
              style={{ alignSelf: "flex-end" }}
              variant="contained"
              color="secondary"
              showDot={"left"}
              onClick={localHandleClose}
            >
              Cancelar
            </VButton>
            <VButton
              disabled={isLoadingAll || isLoading}
              style={{ alignSelf: "flex-end" }}
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(onSubmit, onError)()}
            >
              Marcar
            </VButton>
          </div>
        </div>}
        {isLoadingAll && <div style={{ padding: 20, display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></div>}
      </div>
    </MyDialog>
  );
}

export default memo(withSnackbar(ScheduleSession));
