const configProd = {
  VENTURACUIDA_ENDPOINT: "https://ventura-cuida-backend.herokuapp.com",
};

const configDev = {
  VENTURACUIDA_ENDPOINT: "http://localhost:8080",
};

export const getConfig = () => {
  return configProd;
};
