import { Autocomplete, TextField, Typography, useTheme, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from "@mui/material";
import React, { memo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import VButton from "../../V/VButton/index.js";
import { withSnackbar } from "notistack";
import { MyDialog } from "../MyDialog/index.js";
import { useState } from "react";
import { useAddSheetRowData, useSheet } from "../../../hooks/useVenturaCuidaSheet/index.js";
import { BONDI_BLUE, VENTURACARE_SPREADSHEET } from "../../../constants.js/index.js";
import moment from "moment/moment";
import Auth from "../Auth/index.js";

function AddClientForm({ onSubmitDataProcessing = (data) => data, open = false, handleClose, enqueueSnackbar }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);

  const { control, watch, register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      Sexo: '',
    },
  });

  const { data: partnerships } = useSheet(VENTURACARE_SPREADSHEET.tabs.Parcerias, { enabled: step === 1 });

  const localHandleClose = () => {
    reset({});
    setStep(0);
    handleClose();
  };

  const addClient = useAddSheetRowData(VENTURACARE_SPREADSHEET.tabs.Clients);

  const onError = (errors, e) => {
    enqueueSnackbar("Aconteceu um erro", { variant: "error", });
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const now = moment().format("YYYY/MM/DD hh:mm");

    const newRow = {
      'Carimbo de data/hora': now,
      ...data,
    };

    addClient.mutate(onSubmitDataProcessing(newRow), {
      onSuccess: () => {
        enqueueSnackbar("Registado com sucesso", { variant: "success" });
        localHandleClose();
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        throw error;
      },
    });
  };

  return (
    <FormProvider {...{ control, watch }}>
      <MyDialog
        stylesProps={{ width: "100%" }}
        open={open}
        onClose={localHandleClose}
        scroll="paper"
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div
          style={{
            padding: theme.spacing(3),
            paddingLeft: 20,
            paddingRight: 20,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <Typography variant="body1" fontWeight={700} color={BONDI_BLUE}>
            Adicionar cliente
          </Typography>
          {step === 1 && (
            <>
              <Typography variant="body2">Nome*</Typography>
              <TextField
                {...register("Nome", { required: "Nome é obrigatório" })}
                label="Nome"
                variant="outlined"
                error={!!errors.Nome}
                helperText={errors.Nome ? errors.Nome.message : null}
              />
              <Typography variant="body2">Endereço de email</Typography>
              <TextField
                {...register("Endereço de email")}
                label="Endereço de email"
                variant="outlined"
              />
              <Typography variant="body2">Idade*</Typography>
              <TextField
                {...register("Idade", { required: "Idade é obrigatório" })}
                label="Idade"
                variant="outlined"
                error={!!errors.Idade}
                helperText={errors.Idade ? errors.Idade.message : null}
              />
              <FormControl error={!!errors.Sexo}>
                <FormLabel id="gender-radio-buttons-group-label">Sexo</FormLabel>
                <Controller
                  {...register('Sexo', { required: 'Este campo é obrigatório' })}
                  name="Sexo"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      aria-labelledby="gender-radio-buttons-group-label"
                    >
                      <FormControlLabel value="Mulher" control={<Radio />} label="Mulher" />
                      <FormControlLabel value="Homem" control={<Radio />} label="Homem" />
                    </RadioGroup>
                  )}
                />
                {errors.Sexo && <FormHelperText>{errors.Sexo.message}</FormHelperText>}
              </FormControl>
              <Typography variant="body2">Telemóvel</Typography>
              <TextField
                {...register("Telemóvel", { placeholder: "+351986345780", required: 'Número de telemóvel é obrigatório' })}
                label="Telemóvel"
                variant="outlined"
                placeholder="+351986345780"
                error={!!errors.Telemóvel}
                helperText={errors.Telemóvel ? errors.Telemóvel.message : null}
              />
              <Typography variant="body2">Profissão</Typography>
              <TextField
                {...register("Profissão")}
                label="Profissão"
                variant="outlined"
              />
              <Typography variant="body2">Seguro de Saúde</Typography>
              <TextField
                {...register("Seguro de Saúde")}
                label="Seguro de Saúde"
                variant="outlined"
              />
              <Typography variant="body2">Parceria</Typography>
              <FormControl error={!!errors.Parceria}>
                <Controller
                  control={control}
                  {...register('Parceria', { required: 'Selecione uma parceria' })}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={partnerships?.data?.filter((data) => data["Nome da empresa"])?.map((p) => p["Nome da empresa"]) || []}
                      renderInput={(params) => (
                        <TextField {...params} label="Parceria" />
                      )}
                      onChange={(e, data) => onChange(data)}
                      value={value}
                      sx={{ width: "100%" }}
                    />
                  )}
                />
                {errors.Parceria && <FormHelperText>{errors.Parceria.message}</FormHelperText>}
              </FormControl>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <VButton
                  style={{ alignSelf: "flex-end" }}
                  variant="contained"
                  color="secondary"
                  showDot={"left"}
                  onClick={() => {
                    localHandleClose();
                  }}
                >
                  Cancelar
                </VButton>
                <VButton
                  style={{ alignSelf: "flex-end" }}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  onClick={() => {
                    handleSubmit(onSubmit, onError)();
                  }}
                >
                  Registar
                </VButton>
              </div>
            </>
          )}
          {step === 0 && (
            <FormProvider
              {...{ control, register, handleSubmit, reset, watch }}
            >
              <Auth
                {...{
                  isLoading,
                  localHandleClose,
                  enqueueSnackbar,
                  onAuthSuccess: () => { setStep(1) },
                }}
              />
            </FormProvider>
          )}
        </div>
      </MyDialog>
    </FormProvider >
  );
}

export default memo(withSnackbar(AddClientForm));
