const ptLang = {
  main: "Rede de bem-estar",
  weSpeakYourLanguage: "Falamos a sua língua!",
  bookSession: {
    name: "Marque a sua sessão",
    talkWithPhysioterapist: "Marque a sua avaliação gratuita",
    talkWithPhysioterapistLink: "https://forms.gle/1ocKr6kRK47CirVo8",
    evaluation: {
      title: "Marque a sua avaliação gratuita",
    },
  },
  header: {
    home: "Início",
    services: "Serviços",
    aboutUs: "Sobre nós",
    pricing: "Preçário",
    corporate: "Corporativo",
    hospitality: "Hospitalidade",
    hotelAirbnb: "Hotel & AL",
    location: "Localização",
    blog: "Blog",
    recomendations: "Recomendações",
    therapist: "Terapeuta",
    partnership: "Parceria",
    admin: 'Administrador'
  },
  reservationFormLink: "https://forms.gle/i14cJEuocwP3NKjQA",
  makeReservation: "Faça a sua marcação",
  rehabilitation: "Comece o seu processo de reabilitação!",
  servicesHeader: "Serviços",
  aboutUsHeader: "Sobre nós",
  teamHeader: "A nossa equipa",
  pricingHeader: "Preçário",
  recomendationsHeader: "Recomendações de produtos",
  aboutUsText: `A Ventura Cuida é a sua rede de terapeutas, com fisioterapeutas, massagistas e especialistas em medicina tradicional chinesa, sempre ao seu dispor.
  Os nossos especialistas, com amplo conhecimento nas várias áreas terapêuticas, estão prontos para resolver os seus problemas e os da sua família.
  Na Ventura Cuida, não nos contentamos em apenas tratar sintomas, mas sim em encontrar e resolver a causa, visando melhorar a sua saúde de maneira abrangente. Num mundo agitado, buscamos facilitar o acesso a uma experiência de tratamento única, cuidando do seu corpo e promovendo um estilo de vida saudável.
  Somos uma empresa jovem, dedicada ao impacto positivo que podemos ter nas vidas das pessoas.`,
  aboutAna: `
  Ana Sofia Ventura é fisioterapeuta especializada em saúde da mulher, com foco em fisioterapia pélvica e drenagem linfática. Formada em Fisioterapia pela ESTeSL em 2019 e pós-graduada em Fisioterapia Materno-Infantil pela Egas Moniz em 2021, trabalhou em clínicas e centros materno-infantis, além de atuar como formadora. Fez estágios no Ospedale Maggiore di Novara, aprimorando suas técnicas em drenagem linfática e incontinência urinária. Em 2022, fundou a Ventura Cuida, iniciativa que oferece serviços como massagem terapêutica e fisioterapia ortopédica, com uma abordagem holística e personalizada, focada em promover bem-estar e acessibilidade.
  `,
  schedule: {
    name: "Marque a sua sessão",
  },
  more: "Ver mais",
  services: {
    ourServices: "Nossos serviços",
    ourServicesTitle: "Os Nossos Serviços para Hotéis e Alojamentos locais",
    enhance: "Ajudamo-lo na sua missão de proporcionar férias mais relaxantes aos seus clientes de forma fácil e eficiente.",
    orthopedic: {
      name: "Fisioterapia Ortopédica",
      description:
        "Experimente o poder transformador da Fisioterapia Ortopédica. Os nossos terapeutas especializados em condições musculoesqueléticas ajudam-no a recuperar de lesões, a gerir a dor e a restaurar a mobilidade ideal. Com planos de tratamento personalizados e técnicas avançadas, iremos guiá-lo para uma vida ativa e sem dores. Tome o controlo da sua saúde ortopédica. Reserve uma sessão hoje e inicie a sua jornada rumo a uma nova força e mobilidade.",
    },
    geriatric: {
      name: "Fisioterapia Geriátrica",
      description:
        "Aproveite os benefícios da Fisioterapia Geriátrica para melhorar a mobilidade e a qualidade de vida. Os nossos terapeutas experientes são especializados em enfrentar os desafios relacionados com a idade, promovendo força, equilíbrio e independência. Desfrute de cuidados personalizados que se concentram nas suas necessidades e objetivos específicos. Adote uma abordagem proativa para o seu bem-estar. Reserve já uma sessão e redescubra o prazer de se movimentar nos seus anos dourados.",
    },
    therapeuticMassage: {
      name: "Massagem Terapêutica",
      description:
        "Experimente o poder curativo da massagem terapêutica, uma prática rejuvenescedora que alivia a tensão muscular, o stress e promove o bem-estar geral. Deixe os nossos terapeutas especializados dissiparem as suas preocupações e restaurarem o equilíbrio do seu corpo e mente. Mime-se com uma fuga de puro prazer e descubra os benefícios transformadores da massagem terapêutica. Marque a sua sessão agora e embarque numa jornada de relaxamento e revitalização. O seu corpo merece!",
    },
    lymphaticDrainage: {
      name: "Drenagem Linfática",
      description:
        "Experimente os benefícios suaves, mas poderosos, da drenagem linfática. Esta técnica especializada promove a desintoxicação, reforça o sistema imunitário e reduz o inchaço. Mime-se com uma sessão relaxante que o deixará renovado e revitalizado. Abrace os efeitos transformadores da drenagem linfática. Reserve já e melhore o seu bem-estar.",
    },
    relaxingMassage: {
      name: "Massagem Relaxante",
      description:
        "Desfrute dos efeitos calmantes de uma massagem relaxante. Projetada para dissolver a tensão e o stress, este tratamento utiliza movimentos suaves e fluidos para promover um relaxamento profundo. Deixe de lado as preocupações do dia e restaure o corpo e a mente num ambiente tranquilo e sereno. Eleve o seu bem-estar com uma sessão revitalizante. Reserve a sua fuga hoje mesmo.",
    },
    acupuncture: {
      name: "Acupuntura",
      description:
        "Experimente os benefícios equilibrantes da acupuntura. Esta antiga prática utiliza agulhas finas para estimular pontos específicos do corpo, promovendo a cura natural e o alívio de dores. Equilibre o fluxo de energia, alivie o stress e restaure o seu bem-estar com uma sessão personalizada de acupuntura. Reserve já e descubra o poder da medicina tradicional.",
    }
  },
  pricing: {
    quote: "Consulta de preço",
    priceQuote: "Preço sobre consulta",
    session: "Sessão de 1h",
    perSession: "/sessão de 1h",
    description1:
      "Todos os nossos serviços, desde a massagem, drenagem linfática aos serviços de reabilitação têm o mesmo preço pois, consideramos que todos fazem parte da fisioterapia.",
    description2:
      "As nossas sessões têm a duração de 1h. Se quiser uma sessão com duração diferente do nosso período habitual, por favor contacte-nos para que possamos encontrar a melhor combinação possível.",
    oneSession60: {
      name: "Uma sessão",
      price: "40",
      duration: "60",
      ideal: {
        1: "Tratamento de urgência",
        2: "Experimentar o serviço",
        3: "Sessão de ensino",
      },
    },
    pack5: {
      name: "Pacote de cinco sessões",
    },
    pack10: {
      name: "Pacote de dez sessões",
      price: "38",
      duration: "60",
      ideal: {
        1: "Tratamento esporádico",
        2: "Lidar com um problema agudo",
      },
    },
    pack20: {
      name: "Pacote de vinte sessões",
      price: "36",
      duration: "60",
      ideal: {
        1: "Tratamento intensivo",
        2: "Lidar com uma situação crónica",
      },
    },
  },
  team: {
    executiveDirector: {
      name: "Ana Sofia Ventura",
      role: "Fisioterapeuta e Fundadora",
      quote:
        "Henry Ford escreveu que “Quando todos avançam juntos, o sucesso ocorre por si só”. O nosso sucesso é o sucesso do seu tratamento.",
      description: `As suas necessidades preocupam-me, 
        assim como me preocupa o trabalho dos fisioterapeutas. 
        A minha função é fazer a ponte entre os dois e encontrar o melhor equilíbrio 
        possível, para que todos possamos atingir os nossos objetivos`,
    },
    technicalDirector: {
      name: "Diretor Técnico",
      description:
        "“Sou engenheiro informático. A minha missão é cuidar da plataforma de forma a estar o mais acessível para si. O meu trabalho é facilitar o seu trabalho.”",
      role: "Engenheiro",
    },
    ourPhysiotherapist: {
      name: "Os nossos fisioterapeutas",
      description:
        "A nossa rede de fisioterapeutas é previamente selecionada. Apenas são admitidos fisioterapeutas inscritos na ordem dos fisioterapeutas. Todos os clientes realizam uma avaliação da performance dos fisioterapeutas, após a conclusão do tratamento.  Tendo em conta os rankings de avaliação dos fisioterapeutas e dependendo da condição clínica, selecionados o fisioterapeuta cujas características melhor se adequam à sua necessidade.",
    },
  },
  location: {
    name: "Localização",
    lisbon: "Lisboa",
    madeira: "Madeira",
    oporto: "Porto",
    noLocation: {
      question: "Não encontrou a sua localização?",
      foundPhysio: "Encontraremos um fisioterapeuta perto de si.",
      contactUs: "Contacte-nos",
    },
    closeToYou:
      "Desfrute de serviços domiciliares em Lisboa e Ilha da Madeira! Agende agora para máxima comodidade e relaxamento, sem sair de casa. Cuidamos de você onde quer que esteja!",
    ourLocation: "Nossa localização",
  },
  partnerships: "Nossas parcerias",
  blog: {
    whyPhisioterapy: {
      title: "Porquê fazer fisioterapia em casa?",
      img: "/images/blog/why-physio.webp",
      createdAt: "28/07/2022",
      createdBy: "Fisioterapeuta Ana Sofia Ventura",
      text: `Hoje estamos cada vez mais ligados à cultura do nosso corpo.
        Costuma-se dizer que o nosso corpo é o nosso templo, é a nossa casa.
        Infelizmente, cada vez mais o tempo que temos disponível no nosso dia-a-dia é menor.
        Vivemos numa vida atribulada. Temos uma alimentação deficiente, rápida. Não temos tempo de fazer exercício.
        Passamos todo o dia em posições desconfortáveis, muito tempo em pé ou muito tempo sentados.
        Temos cada vez mais dificuldades em cuidar do nosso templo e acabamos por priorizar outros aspetos da nossa vida.
        Acabamos por não cuidar de algo que deveria ser sagrado. Temos a consciência da sua importância mas não a disponibilidade e acessibilidade de cuidar.
        Devido ao estilo de vida que hoje em dia possuímos muitas vezes a nossa saúde passa
        para segundo plano, porque não há tempo de incluir tratamentos na nossa rotina diária. Por
        isso, a fisioterapia do domicílio pode ser a solução.
        <br><br><b>Com a fisioterapia ao domicílio é possível poupar</b>– tempo e dinheiro<br><br>
        Fazer fisioterapia em casa permite evitar perder tempo em filas de espera e
        deslocações desnecessárias.
        A fisioterapia ao domicilio acaba até por ser a forma mais fácil de realizar fisioterapia
        pois, não terá que gastar dinheiro em deslocações e pode aproveitar para realizar outros
        investimentos
        <br><br><b>A fisioterapia ao domicílio é personalizada</b><br><br>
        Durante a sua sessão de fisioterapia, este é o seu espaço para si. Não terá que dividir o
        tempo do fisioterapeuta com outros clientes e assim terá toda a atenção necessária e
        personalização para que o seu tratamento seja o melhor e mais eficaz.
        <br><br><b>A fisioterapia ao domicílio é cómoda e confortável</b><br><br>
        Permite também que o cliente esteja no seu espaço seguro e confortável. Ao estar no
        conforto da sua casa, o seu tratamento será adaptado à sua realidade do dia-a-dia e assim,
        mesmo quando iniciar o seu período de manutenção, terá todas as ferramentas necessárias
        para o mesmo.
        <br><br><b>A fisioterapia ao domicílio permite-lhe flexibilidade</b><br><br>
        Pode agendar a sessão no horário que lhe for mais conveniente, pois não estará
        depende da disponibilidade de um espaço físico.
        <br><br><b>A fisioterapia ao domicílio permite-lhe receber apoio familiar</b><br><br>
        A sua família pode estar presente durante a realização do seu tratamento, se assim o
        desejar, compreender as suas necessidades e ajuda-lo naquilo que for necessário.
        <br><br><b>A fisioterapia do domicílio é segura</b><br><br>
        Diminua as oportunidades de contrair uma infeção. Não necessita de encontrar-se com
        outros pacientes ou profissionais de saúde.`,
    },
    massage: {
      img: "/images/blog/massage.webp",
      createdAt: "30/07/2022",
      createdBy: "Fisioterapeuta Ana Sofia Ventura",
      title: "Massagem vs Drenagem",
      text: `Muitas pessoas conhecem estes termos e muitas vezes utilizam-nos como sinónimos.
        Mas será que conhecem as diferenças?<br/><br/>
        Embora ambas tenham resultados excelentes, são duas técnicas manuais utilizadas
        com objetivos diferentes.<br/><br/>
        A massagem trabalha vários sistemas corporais, como o sistema musculosquelético,
        pois mobiliza estruturas como os músculos e o sistema circulatório, por exemplo, através do
        aumento de circulação. Existem vários tipos de massagens, com objetivos diferentes. A
        massagem modeladora, tal como o nome indica, têm o objetivo de modelar.<br/><br/>
        Por outro lado, a drenagem linfática tem apenas o objetivo de trabalhar o sistema
        linfático e circulatório. A drenagem linfática têm o objetivo de diminuir o edema.<br/><br/>
        Existem muitos métodos de drenagem linfática. Em geral, o método de drenagem
        linfática utilizado pelos fisioterapeutas é o método de Leduc. Este método não utiliza creme
        para que possa existir uma tração provocada pelo contacto direto entre as mãos e a pele da
        superfície a ser drenada. A pele têm filamentos que estão ligados aos vasos linfáticos, e assim,
        ao mover a pele, é possível mobilizar estes vasos. A velocidade é sempre lenta, pois a
        velocidade a que a linfa circula é também ela lenta e a pressão é sempre suave, para que não
        haja encerramento dos vasos linfáticos e para que a linfa possa progredir de forma natural.<br/><br/>
        A massagem modeladora muitas vezes pode ter uma velocidade elevada e uma
        pressão forte pois, ela têm o objetivo de destabilizar as células adiposas, rompendo as suas
        estruturas, para que depois haja uma nova organização e que esta seja mais uniforme e
        esteticamente mais agradável. Pode ou não utilizar creme, assim como diversos outros
        materiais.<br/><br/>
        Assim sendo, a drenagem linfática geralmente é utilizada para situações em que há
        edema, inchaço e a massagem modeladora é utilizada para modelar, geralmente zonas onde
        existe gordura.<br/><br/>
        Como saber qual deles utilizar? O melhor será ser avaliado por um fisioterapeuta para
        que este possa decidir qual o tipo de técnica manual que melhor se adequa ao seu caso
        particular`,
    },
    pregnancy: {
      img: "/images/blog/pregnancy.webp",
      createdAt: "30/08/2022",
      createdBy: "Fisioterapeuta Ana Sofia Ventura",
      title: "Peso na gravidez",
      text: `
        <p>Quanto mais tarde na evolução da gravidez, maior deve ser o aumento de peso da gr&aacute;vida.</p>
        <p>O primeiro trimestre deve ser aquele em que o aumento de peso &eacute; menos significativo.</p>
        <p>No final da gravidez, a mulher deve apresentar um aumento de peso entre 11 e os 16kg.<br />Neste &ldquo;pacote&rdquo; est&aacute; o beb&eacute; e a m&atilde;e.</p>
        <ul>
          <li>4 a 5kg deste aumento pertencem ao beb&eacute; em conjunto com a placenta e l&iacute;quido<br />amni&oacute;tico (para um &ldquo;beb&eacute; m&eacute;dio&rdquo;)</li>
          <li>Sobram entre 7 a 12 kg para a m&atilde;e: o &uacute;tero, a mama e os l&iacute;quidos em circula&ccedil;&atilde;o<br />devem aumentar, assim como as reservas de gordura.</li>
        </ul>
        <p>Destes componentes, o &uacute;nico que podemos alterar, de modo a manter o aumento de peso<br />ideal para a gravidez, ser&aacute; o das reservas de gordura.</p>
        <p>Assim sendo, o aumento de peso v&aacute;ria com o IMC da m&atilde;e.</p>
        <ul>
          <li>Mulheres com IMC < normal podem ter um maior aumento de peso</li>
          <li>Mulheres com IMC > normal devem ter um menor aumento de peso</li>
        </ul>
        <p>A mulher não precisa de comer por dois, contrariamente ao ditado popular.</p>
        <p>Relembro que o acompanhamento na gravidez é essencial para que tudo corra bem.</p>
        <p>Numa situação ideal, a mulher deveria consultar uma fisioterapeuta e uma nutricionista
        ainda antes da conceção de modo a possuir uma maior quantidade de ferramentas de
        conhecimento.</p>
        <p>Como não vivemos num mundo ideal, deixo-vos aqui este post para vocês ou para quem
        vocês acham que estão a precisar de o ler.</p>
        <p>Cuidem da pessoa mais importante das vossas vidas, vocês!</p>
        <p>Nos primeiros 3 meses não deve haver um grande aumento de peso por parte da grávida.
        No entanto, é nesta fase que muitas mulheres aumentam grande parte da massa gorda.
        Porquê?</p>
        <p>Por um lado, pelo velho ditado de que a grávida deve comer por dois o que se certa forma
        faz com que a mulher grávida se desculpabilize pelos excessos alimentares cometidos.</p>
        <p>Por outro lado, nos primeiros meses, o crescimento do feto não é significativo o que faz
        com que a mulher, para que se note a barriga e consiga evidenciar o seu estatuto de grávida,
        coma mais.</p>
        <p>O que é que se pode fazer para prevenir o excesso de peso na gravidez?</p>
        <p>A fisioterapia pode desenhar um plano de exercícios adequados para cada mulher e
        que as mesmas podem realizar durante a gravidez. A gravidez é um estado de transformação
        em que os todos os sistemas fisiológicos sofrem alterações. Vão ocorrer alterações a nível
        urinário, cardiorrespiratório, musculosquelético, entre outros. Os exercícios propostos pelas
        fisioterapeutas têm em vista não só o estado atual de gravidez mas também o estado posterior
        de pós parto. Para além de terem em conta o excesso de peso são planeados também para
        prevenirem complicações relacionadas com a gravidez, por exemplo com questões urinárias,
        ginecológicas, respiratórias, musculares…</p>
        <p>A nutrição é de extrema importância porque para além de prevenir o excesso de peso,
        proporciona um plano alimentar adequado a cada mulher que para além fornecer alimentos
        que se adaptam às mudanças corporais e fisiológicas da mãe também têm em conta as
        necessidades nutricionais do feto em desenvolvimento.</p>
        `,
    },
    pelvic: {
      img: "/images/blog/pelvic.webp",
      createdAt: "19/01/2023",
      createdBy: "Fisioterapeuta Ana Sofia Ventura",
      title: "Como relaxar o Pavimento Pélvico?",
      text: `
          <p>O relaxamento do pavimento é tão ou mais importante que a sua contração. É
          importante perceber que relaxamento não significa o mesmo que fraqueza. De acordo
          com a infopédia relaxamento é:</p>
          <ol>
          <li>ato ou efeito de relaxar ou relaxar-se</li>
          <li>distensão</li>
          <li>serenidade, descontração</li>
          <li>frouxidão</li>
          <li>depravação, desregramento de costumes</li>
          <li>desmazelo, desleixo</li>
          </ol>
          <p>Esta definição parece um pouco contraditória pois tanto remete para sentimentos
          positivos como negativos. Para efeitos de contração muscular vamos considerar que o
          relaxamento é o estado inicial, uma posição neutra de tensão normal. Da mesma forma
          forma que contração não é o mesmo que tensão, relaxamento não é o mesmo que
          fraqueza. É suposto que os músculos tenham tensão, mas esta deve estar dentro de um
          padrão normal. Uma contração muscular é o “trabalho do músculo”, podendo ou não
          implicar movimento. Assim sendo, relaxamento é quando o músculo não está em
          contração, ou seja, não está a trabalhar, não está em esforço. Quando há uma contração
          máxima, há uma maior probabilidade de haver um relaxamento máximo e quando há
          um relaxamento máximo, há uma maior probabilidade de haver uma contração máxima.</p>
          <p>Assim, se os músculos do pavimento pélvico tiverem a capacidade de regressar à sua
          posição inicial, sem estarem em contração, vão conseguir contrair mais.
          Os orgasmos são excelentes exemplos em que quando há uma forte contração (o
          orgasmo é uma série de contrações rítmicas) há também um relaxamento máximo de
          todo o corpo.</p>
          <p>Porém, no pavimento pélvico, o ato de relaxar ocorre também quando urinamos
          ou defecamos. Ou seja, é o relaxamento do pavimento pélvico que nos permite realizar
          as nossas necessidades. O relaxamento deve ser visto como algo positivo.</p>
          <p>O relaxamento deve ser voluntário e controlado. É suposto que nós consigamos
          apenas relaxar o pavimento
        `,
    },
  },
  massage: {
    title: "Massagem <br/> em sua casa",
    lastMinute: "à ultima hora",
    bookNow: "Marque já",
    formLink: "https://forms.gle/APKKNrKxyVpWcEuU9",
    knowMore: "Saiba mais",
    avaliableService: "Serviço disponível na ilha da Madeira",
    only: "Apenas",
    ideal: {
      header: "Ideal para:",
      relax: "Quem quer relaxar",
      hardDay: "Teve um dia difícil",
      lastMinute: "Não fez marcação prévia",
      pain: "”Deu um mau jeito”",
      tension: "Quer resolver a tensão o mais depressa possível",
    },
  },
  author: "Autor",
  date: "Data",
  contactUs: {
    send: "Enviar",
    messageSuccess: "Enviado, entraremos em contacto em breve.",
    name: "Nome",
    email: "Email",
    phone: "Telemóvel",
    message: "Mensagem",
    close: "Fechar",
    removeConsent:
      "Remoção de consentimento de armazenamento e tratamento de dados.",
    rgdpConsent:
      "Autorizo o armazenamento e tratamento de dados para a avaliação, e outras possíveis reservas futuras.",
  },
  categories: {
    womenMom: "Mulher & Mãe",
    baby: "Bebé",
    elderly: "Saúde para Idosos",
    wellness: "Bem estar",
    help: "Problemas em que a fisioterapia pode ajudar.",
    muscleTension: {
      name: "Tensão Muscular",
    },
    swelling: {
      name: "Retenção de Líquidos",
    },
    contractures: {
      name: "Contraturas",
    },
    posturalAlterations: {
      name: "Alterações Posturais",
    },
    mobility: {
      name: "Mobilidade",
    },
    colics: {
      name: "Cólicas",
    },
    breathingDifficulties: {
      name: "Dificuldades Respiratórias",
    },
    congenitalTorticollis: {
      name: "Torcicolo Congénito",
    },
    developmentDisorders: {
      name: "Distúrbios de Desenvolvimento",
    },
    brachialPlexusInjury: {
      name: "Lesão do Plexo Braquial",
    },
    plagiocephaly: {
      name: "Plágiocefalia",
    },
    urinaryIncontinence: {
      name: "Incontinência Urinária",
    },
    painDuringIntercourse: {
      name: "Dor na relação sexual",
    },
    pelvicOrganProlapse: {
      name: "Prolapso dos Órgãos Pélvicos",
    },
    pelvicPain: {
      name: "Dor Pélvica",
    },
    difficultyReachingOrgasm: {
      name: "Dificuldade em atingir o orgasmo",
    },
  },
  phoneInfo: "Chamada para rede móvel nacional",
  reviews: {
    natalyBellomia: {
      text: `
        Eu experimentei a massagem ao domicílio e gostei muito. Excelente profissionalismo e conhecimentos. Recomendo!
        `,
    },
    carolinaGomes: {
      text:
        "Como sou estudante deslocada, tenho sempre muita dificuldade em encontrar fisioterapeutas de confiança que me consigam acompanhar tanto em Lisboa como na Madeira. Mas desde que conheci a VenturaCare recebo sempre um atendimento personalizado às minhas necessidades, tanto na ilha como em Lisboa. Serviço e comunicação 5 estrelas! Em relação aos preços também considero ser justos para o trabalho, ou seja, qualidade/preço ótimo!",
    },
    elisaVeronica: {
      text: "Adorei a experiência! Para repetir sem dúvida 😍",
    },
  },
  home: {
    networkOf: "Rede de",
    wellness: "Bem-estar",
    physiotherapists: "fisioterapeutas",
    transformYourLife: "Prepare o seu hotel para oferecer uma experiência de excelência aos seus hóspedes com as nossas massagens de alta qualidade. Proporcione momentos de relaxamento e bem-estar incomparáveis durante a estadia, com terapeutas especializados e técnicas que garantem o máximo conforto e revitalização",
    getInTouch: "Entre em contacto",
    weProvide: "Serviços ao domicílio",
    weProvideDescription:
      "A Ventura Cuida garante o seu cuidado, bem-estar e independência. Confie em nós para cuidar da sua saúde e felicidade, tudo no conforto do seu lar. Aventure-se numa experiência de cuidado excepcional.",
    blogExplore:
      "Explore o nosso blog para obter conselhos especializados, dicas e inspiração para otimizar o seu bem-estar.",
    seeWhatOthersSay: "Veja o que outros clientes dizem",
    freeEvaluation: "AVALIAÇÃO GRATUITA",
    letsDoBusiness: "Vamos trabalhar juntos.",
    takeCharge:
      "Assuma o controlo do seu bem-estar. Faça uma avaliação gratuita",
    appointment: "Marque uma consulta",
    areYouBusiness: "Tem uma empresa?",
    guestExperience:
      "Eleve as experiências dos seus clientes com terapias premium.",
    relaxationWellBeing:
      "Venha descobrir oportunidades para enriquecer e complementar os seus serviços, construindo uma parceria que promete encantar os seus clientes através de fisioterapia e massagens no seu negócio.",
    contactUs: "Contacte-nos",
    wellBeing: {
      title: "Bem-estar",
      description1: "Tensão e contraturas musculares",
      description2: "Retenção de líquidos",
      description3: "Alterações posturais",
    },
    elderly: {
      title: "Saúde para Idosos",
      description1: "Mobilidade",
      description2: "Retenção de líquidos",
    },
    woman: {
      title: "Mulher e Maternidade",
      description1: "Incontinência urinária",
      description2: "Dor durante a relação sexual",
      description3: "Prolapso de órgãos pélvicos",
      description4: "Dificuldade em atingir o orgasmo",
    },
    phisioterapist: "Fisioterapeuta Ana Sofia Ventura",
    clinicalDirector: "Diretora clínica",
  },
  aboutUs: {
    whatIs: "O que é a VenturaCuida?",
  },
  recomendations: {
    description:
      "Eleve a sua experiência de fisioterapia com produtos essenciais! Descubra itens selecionados, desde ferramentas de massagem até auxiliares de relaxamento, especialmente pensados para complementar as suas sessões de terapia. Compre agora e abrace o verdadeiro potencial do seu bem-estar!",
  },
  therapist: {
    workUs: "Trabalhe conosco, como",
    workWithUs: "Trabalhe conosco",
    physiotherapist: "Fisioterapeuta",
    massagist: "Massagista",
    location1: "Estamos á sua espera em",
    location2: "Lisboa e na",
    location3: "Ilha da Madeira",
    paymentFair: "Pagamento justo",
    nonExclusivity: "Sem exclusividade",
    flexibility: "Flexibilidade de horário",
    airtable: "Nós fornecemos gratuitamente, um sistema de gestão pessoal dos seus serviços freelancing",
  },
  consent: {
    authorization: "Li e concordo com a Política de Proteção de Dados e Privacidade, assim como com os Termos de Serviço.",
    remove: "Retirar o consentimento de dados",
  },
  massage: "Peça uma massagem",
  sector: {
    hotel: "Hotel",
    gym: "Ginásio",
    "mother-child-center": "Centro Mãe e Criança",
    "medical-clinic": "Clínica Médica",
    insurance: "Seguradora",
  },
  policy: {
    privacy: {
      id: 'Privacidade',
      content: `<p>Política de Privacidade da Ventura Cuida</p>

      <p>A Ventura Cuida está empenhada em proteger a sua privacidade. Esta política de privacidade esclarece como recolhemos, utilizamos e protegemos as informações pessoais que nos fornece ao utilizar o nosso site.</p>
      
      <p><strong>1. Informações Recolhidas</strong></p>
      
      <p>Todas as informações pessoais fornecidas serão utilizadas para tornar a sua visita ao nosso site o mais produtiva e agradável possível. Garantimos a confidencialidade de todos os dados fornecidos pelos utilizadores. As informações pessoais recolhidas podem incluir o seu nome, e-mail, número de telefone, morada e data de nascimento, entre outros. Os dados obrigatórios fornecidos no formulário de registo são necessários para a prestação dos nossos serviços. A omissão ou inexatidão desses dados são da responsabilidade exclusiva do utilizador e podem resultar na recusa da prestação do serviço.</p>
      
      <p><strong>2. Utilização das Informações</strong></p>
      
      <p>Os dados pessoais dos utilizadores serão processados e armazenados informaticamente para serem utilizados no âmbito da relação contratual e/ou comercial com cada utilizador.</p>
      
      <p><strong>3. Direitos dos Utilizadores</strong></p>
      
      <p>De acordo com a legislação aplicável, garantimos ao utilizador o direito de acesso, retificação, atualização e oposição ao uso dos seus dados pessoais. Para exercer esses direitos, pode contactar-nos através do e-mail <a href="mailto:venturacuida@gmail.com">venturacuida@gmail.com</a>.</p>
      
      <p><strong>4. Cookies e Tecnologias Semelhantes</strong></p>
      
      <p>Utilizamos cookies para armazenar informações, como preferências pessoais, durante a sua visita ao nosso site. O utilizador pode optar por desativar os cookies nas configurações do navegador, mas isso pode afetar a interação com o site.</p>
      
      <p><strong>5. Anúncios e Informações de Navegação</strong></p>
      
      <p>Recolhemos informações contidas nos anúncios, incluindo endereço IP, provedor de serviços de Internet, navegador utilizado e páginas visitadas. Essas informações são utilizadas para melhorar a experiência do utilizador e não são compartilhadas com terceiros.</p>
      
      <p><strong>6. Links para Sites de Terceiros</strong></p>
      
      <p>O nosso site pode conter links para sites de terceiros. A nossa política de privacidade não se aplica a esses sites, por isso recomendamos que reveja as políticas de privacidade desses sites antes de fornecer qualquer informação pessoal.</p>
      
      <p><strong>7. Alterações na Política de Privacidade</strong></p>
      
      <p>Reservamo-nos o direito de alterar esta política de privacidade sem aviso prévio. Recomendamos que consulte esta página regularmente para estar atualizado sobre quaisquer mudanças.</p>
      
      <p>Ao utilizar o nosso site, concorda com esta política de privacidade.</p>
      `
    },
    protection: {
      id: 'Proteção de dados',
      content: `<p>Política de Proteção de Dados da Ventura Cuida</p>

      <p>Na Ventura Cuida, estamos empenhados em proteger a sua privacidade. Como responsáveis pelo tratamento dos seus dados pessoais, iremos recolhê-los e armazená-los cuidadosamente.</p>
      
      <p>Os dados pessoais que recolhemos serão tratados por nós, juntamente com outras entidades, se aplicável. O nosso compromisso é claro: utilizaremos os seus dados apenas para os fins necessários, como a prestação de serviços na área da saúde e avaliação do serviço prestado. Além disso, poderemos utilizar os seus dados para cumprir obrigações legais, como arquivo fiscal ou cobrança de dívidas. Neste contexto, poderemos partilhar os seus dados com entidades subcontratadas para a prestação de serviços, conforme os contratos estabelecidos, ou com outras entidades terceiras, se necessário por motivos legais ou judiciais.</p>
      
      <p>Os dados de saúde dos pacientes serão tratados com a máxima confidencialidade e apenas por profissionais de saúde autorizados. Em certas circunstâncias previstas na lei, poderão ser partilhados com familiares do paciente.</p>
      
      <p>Se desejar que os serviços prestados sejam cobertos pelo seu seguro de saúde, poderemos partilhar os seus dados pessoais, incluindo os dados de saúde relacionados com esses serviços, com a sua companhia de seguros ou subsistema de saúde. É importante referir que estas entidades também estão sujeitas a obrigações de sigilo.</p>
      
      <p>No caso de um utilizador ou paciente ser menor de idade ou incapaz, a autorização para o tratamento dos seus dados deverá ser dada pelo titular da responsabilidade parental ou da tutela.</p>
      
      <p>Os seus dados pessoais necessários para a prestação de cuidados de saúde serão conservados de acordo com a legislação aplicável.</p>
      
      <p>Nos termos da lei, garantimos-lhe o direito de aceder, atualizar, retificar, transferir e opor-se ao tratamento dos seus dados pessoais. Para exercer estes direitos, poderá contactar-nos por telefone ou por e-mail.</p>
      
      <p>Na Ventura Cuida, tomamos todas as medidas técnicas e organizacionais necessárias para garantir a segurança e confidencialidade dos seus dados pessoais. Os nossos trabalhadores e prestadores de serviços são obrigados a respeitar a confidencialidade, e implementamos formas de arquivo que garantem o acesso aos dados apenas por pessoas autorizadas.</p>
      
      <p>Para obter mais informações sobre como tratamos os seus dados, consulte a nossa Política de Privacidade ou contacte-nos através do e-mail <a href="mailto:venturacuida@gmail.com">venturacuida@gmail.com</a>.</p>
      `
    },
    terms: {
      id: 'Termos e Condições',
      content: `
    <p>Visão Geral</p>

<p>O domínio <a href="http://www.venturacuida.com">www.venturacuida.com</a> é administrado pela Ventura Cuida, Lda, sendo a responsável pela gestão deste site, incluindo todas as informações, ferramentas e serviços disponíveis para os utilizadores. Ao utilizar os nossos serviços ou visitar o nosso site, concorda com os termos, condições, políticas e avisos aqui estabelecidos.</p>

<p>Por favor, leia atentamente os Termos e Condições do Serviço antes de utilizar o nosso site ou solicitar qualquer serviço de cuidados de saúde. Ao aceder ou utilizar qualquer parte do site, concorda com estes termos e condições. Caso não concorde com todos os termos e condições, deverá abster-se de navegar pelo site ou solicitar serviços.</p>

<p>Pode consultar a versão mais recente dos Termos e Condições do Serviço nesta mesma página. Reservamos o direito de atualizar, alterar ou substituir qualquer parte dos Termos e Condições do Serviço ao publicar atualizações e/ou alterações no nosso site. É sua responsabilidade verificar periodicamente as alterações feitas nesta página. A utilização contínua ou acesso ao site após a publicação de quaisquer alterações constitui aceitação dessas alterações.</p>

<p>Objetivo</p>

<p>O site da Ventura Cuida destina-se a fornecer informações sobre serviços de cuidados de saúde domiciliários, permitindo aos utilizadores aceder a esses cuidados através de meios eletrónicos ou telefónicos, de acordo com os termos e condições aqui descritos.</p>

<p>Acesso ao Serviço</p>

<p>A marcação de consultas está disponível para utilizadores com idade igual ou superior a dezoito anos. Indivíduos com idade inferior devem obter autorização dos seus representantes legais. Os dados fornecidos pelo utilizador são legalmente reconhecidos e obrigatórios para a conclusão dos serviços. Reservamos o direito de recusar o serviço a qualquer pessoa, por qualquer motivo e a qualquer momento.</p>

<p>Todos os serviços da Ventura Cuida estão sujeitos à disponibilidade local.</p>

<p>Os serviços podem ser cancelados sem custo com um aviso mínimo de 24 horas antes da data e hora agendadas. Cancelamentos feitos após este período incorrerão em uma taxa. Se o terapeuta se deslocar até o local e o cliente não puder receber a massagem ou esquecer, o preço total será cobrado.</p>

<p>Preços</p>

<p>Os preços dos nossos serviços estão sujeitos a alterações sem aviso prévio. Reservamos o direito de modificar ou descontinuar serviços a qualquer momento, sem aviso prévio.</p>

<p>Transmissão de Dados</p>

<p>O utilizador concorda em fornecer os dados necessários para criar uma conta de utilizador e para aceder aos serviços de saúde disponibilizados. Deve manter os dados atualizados para garantir a prestação adequada dos serviços. Todos os dados transmitidos são protegidos por certificação digital para garantir a sua segurança.</p>

<p>Para mais informações sobre o envio de informações pessoais, consulte a nossa Política de Privacidade.</p>

<p>Informação de Serviços e Conteúdos</p>

<p>Esforçamo-nos por garantir que todas as informações apresentadas estejam corretas e atualizadas. Contudo, em casos excecionais, podem ocorrer erros ou indisponibilidade de serviços devido a circunstâncias fora do nosso controlo.</p>

<p>Todas as informações sobre preços, produtos, especificações e serviços estão sujeitas a alterações a qualquer momento.</p>

<p>Responsabilidades</p>

<p>Todos os serviços oferecidos pela Ventura Cuida estão em conformidade com a legislação portuguesa e regulamentados pela Entidade Reguladora da Saúde. Garantimos os adequados níveis de segurança do site, mas não nos responsabilizamos por prejuízos decorrentes de fatores externos, como interrupções de comunicação ou falhas de hardware.</p>

<p>Obrigações do Utilizador</p>

<p>O utilizador compromete-se a fornecer informações corretas e atualizadas e a não utilizar identidades falsas. Caso os dados fornecidos estejam incorretos ou insuficientes, a responsabilidade será do utilizador.</p>

<p>Com o objetivo de garantir a continuidade dos serviços e a privacidade dos profissionais de saúde, qualquer contacto telefónico direto deve ser feito através do serviço de apoio ao cliente da Ventura Cuida e está sujeito à disponibilidade.</p>

<p>Marcação de Consultas e Tratamentos</p>

<p>As marcações podem ser solicitadas telefonicamente ou através de outros meios de contacto disponíveis. O utilizador deve criar uma conta de cliente e fornecer os dados do paciente para aceder aos serviços.</p>

<p>Política de Cancelamento</p>

<p>O cancelamento de sessões deve ser feito com antecedência mínima de duas horas através do portal da Ventura Cuida ou por contacto telefónico direto. O não cumprimento deste prazo pode implicar o pagamento de uma taxa de cancelamento.</p>

<p>Avaliação do Serviço Prestado</p>

<p>Os utilizadores são incentivados a avaliar os serviços prestados através de formulários de avaliação ou chamadas telefónicas. Este processo faz parte do nosso compromisso com a melhoria contínua da qualidade dos nossos serviços.</p>

<p>Litígio</p>

<p>Em caso de litígio, os utilizadores podem recorrer a um dos Centros de Arbitragem de Conflitos de Consumo disponíveis. Mais informações estão disponíveis no Portal do Consumidor.</p>

<p>Alterações aos Termos e Condições do Serviço</p>

<p>Os utilizadores podem consultar a versão mais recente dos Termos e Condições do Serviço a qualquer momento nesta página. Reservamos o direito de atualizar, modificar ou substituir qualquer parte destes termos e condições mediante publicação no site.</p>

<p>Informações de Contacto</p>

<p>Quaisquer questões sobre os Termos e Condições do Serviço podem ser enviadas para o endereço de email <a href="mailto:venturacuida@gmail.com">venturacuida@gmail.com</a>.</p>
`,
    }
  },
  whatsApp: {
    openWeb: 'Abrir na web',
    openPhone: 'Abrir no telemóvel',
    fastResponse: 'Respondemos em 10 minutos'
  },
  preAppointment: {
    title: 'Pré-Agendamento',
    personalInfo: 'Informação pessoal',
    name: 'Nome',
    nameRequired: 'Nome é obrigatório',
    phone: 'Telefone',
    phoneNumber: 'Número de telefone',
    phoneNumberRequired: 'Número de telefone é obrigatório',
    email: 'Email',
    emailRequired: 'Email é obrigatório',
    birthday: 'Data de nascimento',
    birthdayRequired: 'Data de nascimento é obrigatória',
    gender: 'Género',
    genderRequired: 'Género é obrigatório',
    woman: 'Mulher',
    men: 'Homem',
    medicalConditionText: 'Condição médica',
    paymentCashInfo: `Da lista seguinte, por favor, assinale qualquer condição que se aplique a si.<br />
      A sua resposta honesta é muito importante para garantir condições de segurança.<br />
        Nem todas as condições seguintes são contraindicações absolutas para uma massagem, mas são situações que devemos estar cientes e, talvez, realizar a massagem com cuidado especial.`,
    pleaseMedicalCondition: 'Por favor, diga-nos outras condições médicas que tem.',
    otherConditions: "Outras condições",
    otherIsRequired: 'Outras são obrigatórias',
    preferences: 'Preferências',
    areasOfTheBody: 'Áreas do corpo nas quais gostaria que nos concentrássemos durante a massagem',
    massagePreferences: 'Preferência de massagem',
    preferencesOptions: {
      pressure: 'Com muita pressão',
      toRelax: 'Para relaxar',
      swollen: 'Sinto-me inchado(a) e gostaria de uma massagem drenante',
      pain: 'Dor específica que gostaria de resolver'
    },
    extraInformation: 'Informação extra',
    relevantInformation: 'Deixe-nos saber qualquer informação relevante para a massagem.',
    information: 'Informação',
    termsAndConditionsText: 'Consentimento e Termos de condições',
    checkboxsChecked: 'Todas as caixas devem ser assinaladas',
    reset: 'Reiniciar',
    submit: 'Submeter',
    medicalConditions: {
      pregnancy: 'Gravidez',
      injuries: 'Lesões ou fraturas nos últimos 6 meses',
      uncontrolledHeart: 'Doença cardíaca ou problemas de pressão arterial não controlados',
      deepVein: "Trombose venosa profunda (TVP)",
      bloodClotting: "Distúrbios de coagulação sanguínea",
      skinInfections: "Infeções de pele, feridas abertas ou erupções cutâneas",
      severeVaricose: "Veias varicosas graves",
      osteoporosis: 'Osteoporose grave',
      cancer: 'Cancro',
      others: 'Outros'
    },
    areasBody: {
      feet: 'Pés',
      legs: 'Pernas',
      back: 'Costas',
      abdomen: 'Abdómen',
      neckAndHead: 'Pescoço e cabeça',
    },
    termsAndConditions: {
      acceptReceiveMassage: 'Confirmo e aceito receber uma massagem de um dos terapeutas, da VenturaCuida',
      massagePurpose: 'Entendo e aceito que os serviços prestados são estritamente terapêuticos e para fins de relaxamento; não são oferecidos serviços sexuais.',
      agreeToRespect: "Concordo em respeitar todos os terapeutas e a abster-me de qualquer comportamento inadequado, sugestivo ou sexual.",
      inappropriateBehaviour: "Compreendo que qualquer comportamento inadequado resultará na terminação imediata do serviço.",
      terms: "Li e concordo com a Política de Proteção de Dados e Privacidade, bem como os Termos de Serviço.",
    },
    success: 'Pré-agendamento concluído com sucesso',
    respondToAll: 'Por favor, responda a todos os campos obrigatórios',
  },
  errorHappened: 'Ocorreu um erro, contacte-nos por email.',
  canLoggin: 'Já pode se autenticar',
  submit: 'Submeter',
  recoverPassword: 'Recuperar password',
  reservationsMade: "Aqui pode aceder a todas as reservas efectuadas nas suas instalações.",
  askForPassword: "Se ainda não tem uma palavra-passe peça uma nova a baixo.",
  partnership: "Parceria",
  forgotPassword: "Esqueci da password, ou não tenho password",
  login: "Entrar",
  close: "Fechar",
  exclusiveService: "Serviços Premium Exclusivos para Hotéis e Alojamentos Locais",
  guestLuxury: "Ofereça aos seus hóspedes um toque de luxo e relaxamento com os nossos serviços de massagens premium. Ideal para hotéis, casas de hóspedes e alojamentos locais, o VenturaCuida garante uma experiência excecional com cuidados personalizados e terapeutas especializados.",
  whyVentura: {
    title: 'Porque escolher o VenturaCuida?',
    availability: {
      title: 'Maior Disponibilidade e Rapidez no Serviço:',
      description: 'Com uma equipa de terapeutas qualificados, podemos acomodar pedidos com pouca antecedência e manter um fluxo de serviço contínuo.'
    },
    diversity: {
      title: 'Diversidade de Especialidades e Serviços:',
      description: 'Os nossos terapeutas são formados em várias especialidades, incluindo fisioterapia, garantindo que atendemos eficazmente as diversas necessidades dos hóspedes.'
    },
    continuous: {
      title: 'Formação Contínua e Atualizada:',
      description: 'Sob a orientação da nossa Diretora Clínica, os terapeutas passam por formações regulares para manter altos padrões e incorporar as mais recentes práticas.'
    }
  },
  ourPartners: 'Os Nossos Parceiros',
  versatile: "Adaptação Versátil a Hotéis & Alojamentos Locais",
  hotelAccomodations: {
    confort: {
      title: 'Conforto e Conveniência',
      description: "Levamos todo o equipamento necessário diretamente aos quartos dos hóspedes, garantindo relaxamento e conveniência sem que precisem sair do alojamento."
    },
    onDemand: {
      title: "Serviço Sob Demanda",
      description: "O nosso sistema de agendamento flexível adapta-se aos horários dos hóspedes, garantindo a entrega pontual dos serviços de acordo com as preferências individuais."
    },
    monitoring: {
      title: "Monitorização e Feedback",
      description: "A monitorização em tempo real de reservas e feedback, através do nosso painel de administração, permite-lhe acompanhar facilmente a qualidade do serviço e a satisfação dos hóspedes."
    },
    flexible: {
      title: "Serviços Flexíveis e Personalizados",
      description: "Desde o apoio ao spa em hotéis de 5 estrelas até massagens nos quartos em alojamentos sem spa, adaptamo-nos de forma flexível às necessidades do seu estabelecimento."
    }
  },
  advantages: "Vantagens para o Seu Estabelecimento",
  accomodation: {
    moreClients: { title: 'Atraia Mais Clientes:', description: 'Destaque-se com serviços de bem-estar premium que elevam a experiência dos hóspedes.' },
    increase: { title: 'Aumente a Satisfação dos Hóspedes:', description: 'Hóspedes felizes e relaxados traduzem-se em avaliações positivas e visitas repetidas.' },
    earnings: { title: 'Ganhos Adicionais:', description: 'Ganhe comissões por cada serviço no seu alojamento, sem encargos logísticos.' },
    massages: { title: 'Variedade de Massagens:', description: 'Tratamentos personalizados, desde massagens relaxantes a sessões terapêuticas, para atender às diversas preferências dos hóspedes.' },
  },
  testimonials: 'Testemunhos',
  venturaCuidaServices: '--- Serviços VenturaCuida',
  exploreVenturaCuida: "Explore os serviços de massagens premium do VenturaCuida, adaptados para hotéis e alojamentos locais em Lisboa e na Madeira. Terapeutas especializados, formação contínua e cuidados personalizados para uma experiência superior dos hóspedes.",
  errorHappened: "Ocorreu um erro",
  scheduleACall: "--- Agende uma chamada connosco!",
  buildAPartnership: "Vamos construir uma Parceria que torne os seus clientes mais felizes e relaxados",
  addClient: "Adicionar cliente",
  clientEvaluation: "Avaliação do cliente",
  evaluationPlan: 'Plano de avaliação',
  serviceArea: {
    title: "Service Areas",
    subTitle: "The Lisbon area & Madeira Island",
    description: "VenturaCuida serves the Lisbon area and Madeira Island, ensuring top-tier massage services directly for your guests' convenience."
  },
  terms: 'termos',
  conditions: 'condições',
  iAccept: 'Eu aceito os',
  and: 'e',
  workMethods: {
    title: 'Formas como podemos colaborar.',
    byBooking: {
      title: 'Serviços por Marcação:',
      description: 'Rápida disponibilidade de terapeutas para atender às necessidades dos seus clientes com eficiência e qualidade.'
    },
    coupleMassage: {
      title: 'Suporte para Massagem de Casal:',
      description: 'Quando só tem um terapeuta disponível, oferecemos ajuda imediata para garantir o atendimento completo.'
    },
    substitution: {
      title: 'Substituição de Terapeutas:',
      description: 'Se o seu terapeuta estiver de férias ou de baixa, oferecemos soluções para garantir que seus serviços continuem sem interrupções.'
    },
    fullTime: {
      title: 'Terapeuta a Tempo Inteiro no SPA:',
      description: 'Oferecemos pacotes especiais para contratar um terapeuta em exclusividade durante a época alta, garantindo que o seu SPA responda à elevada procura com excelência e sem sobrecarga.'
    }
  }
};

export default ptLang;
