import { useBreakpoints } from "react-match-breakpoints";
import { Typography, useTheme } from "@mui/material";
import { forEach, sortBy } from "lodash";
import { useMemo } from "react";
import { useLanguage } from "../../../contexts/Language/index.js";
import { useSheet } from "../../../hooks/useVenturaCuidaSheet/index.js";
import MySwiper from "../../MySwiper/index.js";
import { Review } from "../../Review/index.js";
import { BONDI_BLUE, VENTURACARE_SPREADSHEET } from "../../../constants.js/index.js";
import opacity from "hex-color-opacity";
import { useResponsive } from "../../../hooks/useResponsive/index.js";
import { Text } from "react-internationalization";

export const Reviews = () => {
    const { tabletDown, mobile } = useBreakpoints();
    const { spacing, ...theme } = useTheme();
    const res = useResponsive();

    const { data: reviews } = useSheet(VENTURACARE_SPREADSHEET.tabs.Reviews);
    const { language } = useLanguage();

    const reviewsDesc = useMemo(() => {
        if (!reviews) return null;

        forEach(reviews?.data, (review) => {
            review.text = review[`text-${language || "en"}`];
        });

        const sorterFunc = (item) =>
            new Date().getTime() - new Date(item.createdAt).getTime();
        const sorted = sortBy(reviews?.data, [sorterFunc]);

        return sorted;
    }, [reviews]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: opacity("#FFFFFF", 0.03),
                alignItems: "center",
                paddingTop: spacing(5),
                width: "100%",
            }}
        >
            <div style={{ display: "flex" }}>
                <Typography
                    style={{
                        color: BONDI_BLUE,
                        fontWeight: 700
                    }}
                >
                    <Text id="testimonials" />
                </Typography>
            </div>
            <div style={{ width: "100%" }}>
                {reviewsDesc ? (
                    <MySwiper
                        SlideComponent={({ isFirst, isLast, ...props }) => (
                            <div
                                style={{
                                    margin: spacing(res(1, 3)),
                                    marginLeft: spacing(res(2, 10)),
                                    marginRight: spacing(res(2, 20))
                                }}
                            >
                                <Review {...props} />
                            </div>
                        )}
                        navigation={{
                            enabled: true,
                            ...(!mobile && { nextEl: "#next", prevEl: "#prev" }),
                        }}
                        contents={reviewsDesc}
                        slidesPerView={1}
                    />
                ) : (
                    <div style={{ width: 200, height: 400 }}></div>
                )}
            </div>
        </div>
    );
};
