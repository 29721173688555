import { ClickAwayListener, Fab, Typography } from "@mui/material";
import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Text } from "react-internationalization";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Close, InfoOutlined } from "@mui/icons-material";
import VButton from "../V/VButton";
import { useBreakpoints } from "react-match-breakpoints";

export const WhatsAppWidget = () => {
    const { tabletDown, mobile } = useBreakpoints();
    const [showAllForms, setShowAllForms] = useState(false);

    return <div
        style={{
            position: "fixed",
            right: 0,
            bottom: 0,
            margin: "20px",
            zIndex: 999,
            display: "flex",
            gap: 20,
        }}
    >
        <motion.div>
            <Fab
                onClick={() => {
                    tabletDown
                        ? window.open("https://api.whatsapp.com/send?phone=351928095512")
                        : setShowAllForms(!showAllForms);
                }}
                size={"large"}
                style={{ zIndex: 100, backgroundColor: showAllForms ? "white" : "#25D366" }}
            >
                {showAllForms ? <Close /> : <WhatsAppIcon style={{ color: 'white' }} />}
                {!showAllForms && <>
                    <motion.div
                        initial={{ scale: 0, opacity: 1 }}
                        animate={{ scale: 1, opacity: 0 }}
                        transition={{ duration: 3, repeat: Infinity }}
                        style={{
                            zIndex: -100,
                            background: "#25D366", position: 'absolute', height: 100, width: 100, borderRadius: 50
                        }}
                    />
                    <motion.div
                        initial={{ scale: 0, opacity: 1 }}
                        animate={{ scale: 1, opacity: 0 }}
                        transition={{ delay: 10, duration: 3, repeat: Infinity }}
                        style={{
                            zIndex: -100,
                            background: "#25D366", position: 'absolute', height: 100, width: 100, borderRadius: 50
                        }}
                    />
                </>}
            </Fab>
        </motion.div>
        <AnimatePresence>
            {showAllForms && (
                <ClickAwayListener
                    onClickAway={() => {
                        setShowAllForms(false);
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            bottom: 70,
                            right: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            gap: 20,
                        }}
                    >
                        <motion.div
                            initial={{ scale: 0.5, y: 130, x: 50, opacity: 0 }}
                            animate={{ scale: 1, y: 0, x: 0, opacity: 1 }}
                            exit={{ y: 200, y: 130, x: 50, scale: 0.5, opacity: 0 }}
                            transition={{ delay: 0.1 }}
                            style={{ padding: 20, borderRadius: 20, width: 200, flexDirection: 'column', backgroundColor: "#25D366", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <div style={{ alignItems: 'center', display: 'flex', gap: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
                                <Typography variant="body2" color={'white'}>
                                    <Text id="whatsApp.openPhone" />
                                </Typography>
                                <WhatsAppIcon style={{ color: 'white' }} />
                            </div>
                            <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                                <img loading="lazy" src="/images/whatsapp/link.png" style={{ width: '100%', height: '100%' }}>
                                </img>
                            </div>
                            <span style={{ alignItems: 'center', display: 'inline-flex', gap: 10, opacity: 0.8 }}>
                                <InfoOutlined style={{ color: 'white' }} />
                                <Typography variant="caption" style={{ fontSize: 10 }} color={'white'}>
                                    <Text id="whatsApp.fastResponse" />
                                </Typography>
                            </span>
                        </motion.div>
                        <motion.div
                            initial={{ scale: 0.5, y: 40, opacity: 0, x: 40 }}
                            animate={{ scale: 1, y: 0, opacity: 1, x: 0 }}
                            exit={{ y: 40, scale: 0.5, opacity: 0, x: 40 }}
                        >
                            <VButton
                                onClick={() => window.open("https://api.whatsapp.com/send?phone=351928095512")}
                                showDot={null}
                                style={{ display: 'flex', gap: 10, fontSize: 12, backgroundColor: "#25D366" }}
                            >
                                <Text id="whatsApp.openWeb" />
                                <WhatsAppIcon style={{ color: 'white' }} />
                            </VButton>
                        </motion.div>
                    </div>
                </ClickAwayListener>
            )}
        </AnimatePresence>
    </div>
}