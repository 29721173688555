import { Typography, useTheme } from "@mui/material";
import React from "react";
import { useBreakpoints } from "react-match-breakpoints";
import { map } from 'lodash';

const RegionList = ({ regions }) => {
    const { mobile } = useBreakpoints();
    const theme = useTheme();

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            position: 'relative',
            gap: theme.spacing(1),
        }}>
            {map(regions, (region, index) => {
                return (
                    <div key={`region-${index}`} style={{ display: 'flex', width: 'auto', gap: 5 }}>
                        <img loading="lazy" src="images/icons/location.png" style={{ height: 20, width: 'auto' }} alt='region'></img>
                        <Typography style={{ color: 'white' }}>{region}</Typography>
                    </div>
                )
            })}
        </div>
    );
};

export default RegionList;
