import { FormControl, MenuItem, Select, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { VButton } from "../VButton/index.js";
import { GUNMETAL, pages } from "../../../constants.js/index.js";
import { Text, translate } from "react-internationalization";
import { useBreakpoints } from "react-match-breakpoints";
import { useLanguage } from "../../../contexts/Language/index.js";
import { Mobile } from "./Mobile/index.js";
import { startTransition } from "react";

export const NewHeader = () => {
    const { spacing } = useTheme();
    const navigate = useNavigate();

    const { setLanguage, language } = useLanguage();

    const { tabletDown } = useBreakpoints();

    if (tabletDown) {
        return <Mobile />;
    }

    return <div style={{
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        backgroundColor: GUNMETAL,
        gap: spacing(2),
        flexWrap: 'wrap',
    }}>
        <img
            loading="lazy"
            src={`${window.location.origin}/images/logo/logo_branco.png`}
            onClick={
                () => startTransition(() => {
                    navigate("/");
                })
            }
            width="100px"
            style={{ objectFit: 'contain', marginRight: 10 }}
            alt="Home page"
        />
        <div style={{ display: 'inline-flex', gap: spacing(2) }}>
            {pages.map(({ pageName, href }, index) => {
                return <VButton
                    key={'page-' + index}
                    style={{ color: 'white', fontSize: '1vw', opacity: 0.6, minWidth: 80 }}
                    variant={null}
                    onClick={() => {
                        navigate(href)
                    }}
                >
                    {translate(pageName)}
                </VButton>
            })}
        </div>
        <div style={{ display: 'flex', gap: spacing(1), alignItems: 'center' }}>
            <FormControl fullWidth>
                <Select
                    variant="standard"
                    defaultValue={language}
                    style={{ width: 100, color: 'white' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    onChange={(e) => {
                        setLanguage(e.target.value);
                        navigate(0)
                    }}
                    renderValue={(value, index) => {
                        return <MenuItem key={'header-item' + index} style={{ display: 'flex', justifyContent: 'center' }} value={'en'}>
                            <img loading="lazy" style={{ width: '100%', objectFit: 'contain' }} src={`images/flags/${value}.png`} ></img>
                        </MenuItem>
                    }}
                >
                    {['en', 'pt'].map((value) => <MenuItem key={'lang-' + value} style={{ display: 'flex', justifyContent: 'center' }} value={value}>
                        <img loading="lazy" style={{ width: '70%', objectFit: 'contain' }} src={`images/flags/${value}.png`} ></img>
                    </MenuItem>)}
                </Select>
            </FormControl>
        </div >
    </div >

}