import { Typography } from "@mui/material";
import VButton from "../../../../../components/V/VButton/index.js";
import { CULTERED } from "../../../../../constants.js";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BusinessIcon from '@mui/icons-material/Business';
import EventIcon from '@mui/icons-material/Event';
import { size } from 'lodash';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

export default function ReservationItem({ reservation, index }) {
    const provider = reservation['Prestador']?.split(' ');
    const partnership = reservation['Parceria'];

    if (!provider) return;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                backgroundColor: CULTERED,
                padding: 20,
                borderRadius: 3,
                position: 'relative',
            }}
        >
            <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
                <VButton
                    variant="text"
                    showDot={null}
                    onClick={() => {
                        window.open(`https://api.whatsapp.com/send?phone=351928095512&text=${encodeURIComponent(
                            `Reserva\n
Dia: ${reservation['Data da sessão']} ${reservation['Hora']}\n
Serviço: ${reservation['Serviço']}\n
Terapeuta: ${provider[0]} ${provider[size(provider) - 1]}\n
Parceria: ${partnership}\n
Estado: ${reservation['Estado']}\n
Gostaria de informar que,\n`
                        )}`)
                    }}
                >
                    <ThumbDownOffAltIcon style={{ opacity: 0.6, color: 'gray' }} />
                </VButton>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 10,
                }}
            >
                <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        <img loading="lazy" src='/images/icons/physiotherapist.png' alt='VenturaCuida' height={20} />
                        <Typography
                            style={{
                                width: 'min-content',
                            }}
                        >
                            {`${provider[0]} ${provider[size(provider) - 1]}`}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <ArrowRightAltIcon />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <BusinessIcon />
                        <Typography>{partnership}</Typography>
                    </div>
                </div>
                <Typography color={reservation['Estado'] !== 'Cancelado' ? 'none' : 'red'}>Estado: {reservation['Estado']}</Typography>
                <Typography>Serviço: {reservation['Serviço']}</Typography>
                {reservation['Data da sessão'] && <div style={{ display: 'flex', gap: 8 }}>
                    <EventIcon />
                    <Typography>
                        {reservation['Data da sessão']} {reservation['Hora']}
                    </Typography>
                </div>}
            </div>
        </div >
    )
}