import {
  Autocomplete,
  Slider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import VButton from "../../V/VButton";
import { withSnackbar } from "notistack";
import { MyDialog } from "../MyDialog";
import { useState } from "react";
import {
  useAddSheetRowData,
  useSheet,
} from "../../../hooks/useVenturaCuidaSheet";
import { BONDI_BLUE, VENTURACARE_SPREADSHEET } from "../../../constants.js";
import moment from "moment/moment";
import { DateAndHour } from "../DateAndHour/index.js";
import { filter } from "lodash";
import NameLogin from "../NameLogin/index.js";
import FormControlContext from "@mui/material/FormControl/FormControlContext.js";
import AuthTherapist from "../Auth/index.js";

function closestValue(array, value) {
  var result, lastDelta;

  array.some(function (item) {
    var delta = Math.abs(value - item);
    if (delta > lastDelta) {
      return true;
    }
    result = item;
    lastDelta = delta;
  });
  return result;
}

function EvaluationForm({ open = false, handleClose, enqueueSnackbar }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);

  const { data: workers } = useSheet(VENTURACARE_SPREADSHEET.tabs.Fisioterapeutas, { enabled: step === 1 });
  const { data: clients } = useSheet(VENTURACARE_SPREADSHEET.tabs.Clients, { enabled: step === 1 });

  const clientsFiltred = useMemo(() => {
    if (clients?.data) {
      return window?.env?.NODE_ENV === "development"
        ? clients.data
        : filter(clients?.data, ({ Nome }) => !Nome.includes("Teste"));
    }
  }, [clients]);

  const { control, register, handleSubmit, reset, watch } = useForm({
    defaultValues: {},
  });

  const localHandleClose = () => {
    reset({});
    setStep(0);
    handleClose();
  };

  const addAvaliacao = useAddSheetRowData(
    VENTURACARE_SPREADSHEET.tabs.Avaliacao
  );

  const onError = (errors, e) => {
    enqueueSnackbar("Aconteceu um erro", { variant: "error" });
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const now = moment().format("YYYY/MM/DD hh:mm");

    const newRow = {
      Timestamp: now,
      ...data,
      "Data da sessão": data["Data da sessão"]
        ? data["Data da sessão"]
        : moment().format("YYYY/MM/DD"),
    };

    addAvaliacao.mutate(newRow, {
      onSuccess: () => {
        enqueueSnackbar("Registado com sucesso", {
          variant: "success",
        });
        localHandleClose();
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        throw error;
      },
    });
  };

  return (
    <MyDialog
      stylesProps={{ width: "100%" }}
      open={open}
      onClose={localHandleClose}
      scroll="paper"
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div
        style={{
          padding: theme.spacing(3),
          paddingLeft: 20,
          paddingRight: 20,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <Typography variant="body1" fontWeight={700} color={BONDI_BLUE}>
          Avaliação do cliente
        </Typography>
        {step === 1 && (
          <>
            <Typography variant="body2">Terapeuta*</Typography>
            <TextField
              disabled
              {...register("Terapeuta")}
              label="Terapeuta"
              variant="outlined"
            />
            <Typography variant="body2">Cliente*</Typography>
            <div style={{ display: "flex", gap: theme.spacing(2) }}>
              <Controller
                control={control}
                name="Cliente"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    options={clientsFiltred?.map((e) => e?.Nome)}
                    renderInput={(params) => (
                      <TextField {...params} label="Cliente" />
                    )}
                    onChange={(e) => {
                      onChange(e?.target?.innerText);
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </div>
            <Typography variant="body2">Data da sessão*</Typography>
            <FormProvider {...{ control }}>
              <DateAndHour />
            </FormProvider>
            <Typography variant="body2">Motivo da consulta</Typography>
            <TextField
              {...register("Motivo da consulta")}
              multiline
              minRows={3}
              label="Motivo da consulta"
              variant="outlined"
            />
            <Typography variant="body2">Intensidade da dor</Typography>
            <div
              style={{
                width: "70%",
                display: "flex",
                marginLeft: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Controller
                control={control}
                name="Intensidade da dor"
                render={({ field: { onChange } }) => (
                  <Slider
                    size="medium"
                    aria-label="Dor"
                    defaultValue={0}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={10}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
              <img
                loading="lazy"
                src={`images/icons/pain/${closestValue(
                  [0, 3, 5, 7, 9],
                  watch("Intensidade da dor") || 0
                )}.png`}
                style={{ marginLeft: 40, height: 40, width: "auto" }}
                alt="region"
              ></img>
            </div>
            <Typography variant="body2">Avaliação*</Typography>
            <TextField
              {...register("Avaliação", { required: true })}
              multiline
              minRows={3}
              label="Avaliação"
              variant="outlined"
            />
            <div style={{ display: "flex", gap: 10, alignSelf: "flex-end" }}>
              <VButton
                style={{ alignSelf: "flex-end" }}
                variant="contained"
                color="secondary"
                showDot={"left"}
                onClick={() => {
                  localHandleClose();
                }}
              >
                Cancelar
              </VButton>
              <VButton
                style={{ alignSelf: "flex-end" }}
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={() => {
                  handleSubmit(onSubmit, onError)();
                }}
              >
                Registar
              </VButton>
            </div>
          </>
        )}
        {step === 0 && (
          <FormProvider {...{ control, register, handleSubmit, reset, watch }}>
            <AuthTherapist
              {...{
                localHandleClose,
                enqueueSnackbar,
                onAuthSuccess: () => { setStep(1) },
              }}
            />
          </FormProvider>
        )}
      </div>
    </MyDialog>
  );
}

export default withSnackbar(EvaluationForm);
