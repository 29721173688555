import { QueryClient, QueryClientProvider } from "react-query";
import { LanguageProvider } from "./Language";
import { MyThemeProvider } from "./MyThemeProvider";
import { Blur } from "../components/Blur";
import { initBreakpoints } from "react-match-breakpoints";
import * as languages from "../Translations";
import { InternationalizationProvider } from "react-internationalization";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./AuthProvider";

const breakpointsConfig = {
  mobile: "screen and (max-width: 767px)",
  tablet: "screen and (min-width: 768px) and (max-width: 1024px)",
  tabletDown: "screen and (max-width: 1024px)",
};

const queryClient = new QueryClient();
const BreakpointsProvider = initBreakpoints(breakpointsConfig);

export function Providers({ children }) {
  return (
    <InternationalizationProvider defaultLanguage="en" languages={languages}>
      <BreakpointsProvider>
        <SnackbarProvider maxSnack={3}>
          <Blur>
            <QueryClientProvider client={queryClient}>
              <LanguageProvider>
                <AuthProvider>
                  <MyThemeProvider>{children}</MyThemeProvider>
                </AuthProvider>
              </LanguageProvider>
            </QueryClientProvider>
          </Blur>
        </SnackbarProvider>
      </BreakpointsProvider>
    </InternationalizationProvider>
  );
}
