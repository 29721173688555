import { Typography } from "@mui/material";
import { CULTERED } from "../../../../../constants.js/index.js";
import BusinessIcon from '@mui/icons-material/Business';
import { EmailOutlined, Person, PhoneIphone } from "@mui/icons-material";

export function ClientItem({ client, index }) {

    return (
        <div
            key={`client-${index}`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: CULTERED,
                padding: 20,
                borderRadius: 3,
                position: 'relative',
            }}
        >
            <div style={{ flexDirection: 'column', display: 'flex', gap: 20 }}>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <Person />
                    <Typography
                        style={{}}
                    >
                        {client.Nome}
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <BusinessIcon />
                    <Typography>{client.Parceria}</Typography>
                </div>
                {client['Telemóvel'] && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <PhoneIphone />
                    <Typography>{client['Telemóvel']}</Typography>
                </div>}
                {client['Endereço de email'] && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <EmailOutlined />
                    <Typography>{client['Endereço de email']}</Typography>
                </div>}
            </div>
        </div >
    )
}