import React from "react";
import PageHeader from "../../../components/PageHeader";
import { useBreakpoints } from "react-match-breakpoints";
import { useNavigate } from "react-router-dom";
import { map } from "lodash";
import { Typography } from "@mui/material";
import { BABY_PINK } from "../../../constants.js";
import { Text, translate } from "react-internationalization";
import styled from "styled-components";
import ImageWithText from "../../../components/ImageWithText";

const BlogContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const getBlogContent = (post, type) => {
  return translate(`blog.${post}.${type}`);
};

const Blog = () => {
  return (
    <>
      <PageHeader name="header.blog" />
      <BlogExplorer></BlogExplorer>
      <div style={{ height: 300 }} />
    </>
  );
};

const BlogExplorer = () => {
  const { mobile } = useBreakpoints();
  const navigate = useNavigate();
  const contents = [
    {
      name: "blog.whyPhisioterapy.title",
      img: getBlogContent("whyPhisioterapy", "img"),
      description: "blog.whyPhisioterapy.text",
      id: "whyPhisioterapy",
    },
    {
      name: "blog.massage.title",
      img: getBlogContent("massage", "img"),
      description: "blog.massage.text",
      id: "massage",
    },
    {
      name: "blog.pregnancy.title",
      img: getBlogContent("pregnancy", "img"),
      description: "blog.pregnancy.text",
      id: "pregnancy",
    },
    {
      name: "blog.pelvic.title",
      img: getBlogContent("pelvic", "img"),
      description: "blog.pelvic.text",
      id: "pelvic",
    },
  ];

  const Title = ({ name }) => {
    return (
      <div
        style={{
          bottom: 0,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontSize: mobile ? "14px" : "18px",
            fontWeight: 600,
            color: "white",
            zIndex: 30,
            padding: 10,
          }}
        >
          <Text id={name}></Text>
          <div
            style={{
              zIndex: -1,
              bottom: 0,
              left: 0,
              position: "absolute",
              backgroundColor: "black",
              opacity: "0.7",
              width: "100%",
              height: "100%",
            }}
          ></div>
        </Typography>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "2%",
          display: "flex",
          flexDirection: "row",
          gap: 20,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {map(contents, (content, index) => {
          return (
            <BlogContainer
              key={`blog-${index}`}
              onClick={() => {
                navigate(`/blog/${content.id}`);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                maxWidth: 300,
                backgroundColor: BABY_PINK,
                boxShadow: "15px 17px 32px -25px rgba(0,0,0,0.26)",
                position: "relative",
              }}
            >
              <ImageWithText
                img={content.img}
                imageStyle={{
                  height: 250,
                  width: 300,
                }}
              >
                <Title {...content} />
              </ImageWithText>
            </BlogContainer>
          );
        })}
      </div>
    </div>
  );
};

export default Blog;
