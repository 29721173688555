import { Slide, Dialog } from "@mui/material";
import React from "react";
import { useBreakpoints } from "react-match-breakpoints";

const Transition = React.forwardRef(function (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const MyDialog = ({ open, onClose, stylesProps, children, ...props }) => {

  const { mobile } = useBreakpoints();

  return (
    <Dialog
      style={stylesProps}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      fullWidth
      fullScreen={mobile}
      {...props}
    >
      {children}
    </Dialog>
  );
};
