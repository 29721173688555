import * as React from "react";
import { useLanguage } from "../../../contexts/Language";
import { motion } from "framer-motion";
import {
  AQUA_SQUEEZE,
  BONDI_BLUE,
  CLOUD_BURST,
  SPACE_CADET,
  WHITE_SMOKE,
} from "../../../constants.js";
import hexRgb from "hex-rgb";

export function Language({ bgColor = SPACE_CADET }) {
  const { language, setLanguage } = useLanguage();

  const handleChange = (lang) => {
    setLanguage(lang);
    setTimeout(() => window.location.reload(), 200);
  };

  const spring = {
    type: "spring",
    stiffness: 1000,
    damping: 20,
  };

  const toggleSwitch = () => handleChange(language === "pt" ? "en" : "pt");

  return (
    <div>
      <div
        style={{
          position: "relative",
          width: 50,
          backgroundColor: hexRgb(bgColor, { format: "css", alpha: 0.1 }),
          display: "flex",
          justifyContent: language === "pt" ? "flex-start" : "flex-end",
          borderRadius: "50px",
          padding: "10px",
          cursor: "pointer",
        }}
        onClick={toggleSwitch}
      >
        <motion.div
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "40px",
            backgroundSize: "cover",
            backgroundImage: `url('${window.location.origin}/images/icons/${language === "pt" ? "portugal" : "united-kingdom"
              }.png')`,
          }}
          layout
          transition={spring}
        />
      </div>
    </div>
  );
}
