import { useQuery } from "react-query";
import { venturaCuidaApi } from "../../api/venturaCuida";
import { useAuth } from "../../contexts/AuthProvider";
import difference from "lodash/difference";

export const useDashboardData = ({ resource, param = '' }) => {
    const { user } = useAuth();
    const queryKey = `${resource}-${user?._id}${param ? '-' + param : ''}`;
    const data = useQuery(
        queryKey,
        async () => {
            const paramStr = param ? `/${param} ` : '';
            const data = await venturaCuidaApi().get(`/api/${user?.role}/${resource}${paramStr}`);
            return data?.data;
        },
        { enabled: !!user?._id }
    );

    return { ...data, queryKey }
}