import { MenuItem, Select, Typography } from "@mui/material"
import { useBreakpoints } from "react-match-breakpoints"
import { useDashboardPages } from "../../hooks/useDashboardPages";

export const TabletDownDashboardChanger = ({ currentPage }) => {
    const { tabletDown } = useBreakpoints();
    const pages = useDashboardPages();
    return <>
        {!tabletDown && <Typography style={{ color: 'white' }}>{currentPage}</Typography>}
        {
            tabletDown && <Select
                sx={{ color: 'white', height: 40 }}
                value={currentPage}
                label="Tabelas"
                onChange={() => { }}
            >
                {pages.map(({ name, onClick }, index) => <MenuItem key={'pages' + index} onClick={onClick} value={name}>{name}</MenuItem>)}
            </Select>
        }
    </>
}