import React, { useState } from "react";
import PageHeader from '../../../components/PageHeader';
import { useBreakpoints } from 'react-match-breakpoints';
import ImageWithText from "../../../components/ImageWithText";
// Import Swiper styles
import MySwiper from "../../../components/MySwiper";
import { Text, translate } from "react-internationalization";
import { Box, Typography, useTheme } from "@mui/material";
import RegionList from "../../../components/RegionList";
import { BONDI_BLUE, BRIGHT_GRAY, CLOUD_BURST, MAIN_FONT, REVIEWS } from "../../../constants.js";
import ContactFormModal from "../../../components/Modal/ContactFormModal";
import VButton from "../../../components/V/VButton";
import { Review } from "../../../components/Review";
import hexRgb from "hex-rgb";
import { ClickableLogo } from "../../../components/ClickableLogo";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { WhatsAppWidget } from "../../../components/WhatsappWidget/index.js";

// import required modules

const Location = () => {
  const theme = useTheme();
  const { tabletDown } = useBreakpoints();
  const { mobile } = useBreakpoints();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const contents = [
    {
      name: translate('location.lisbon'),
      img: '/images/location/lisbon.jpeg',
      regions: ['Almada', 'Seixal', 'Barreiro', 'Alcochete', 'Moita', 'Montijo', translate('location.lisbon'), 'Oeiras', 'Cascais', 'Sintra', 'Odivelas', 'Loures', 'Mafra'],
    },
    {
      name: translate('location.madeira'),
      img: '/images/location/madeira.jpeg',
      regions: ['Funchal', 'Caniço', 'Câmara de lobos', 'Ribeira Brava', 'Ponta de Sol'],
    },
    // {
    //   name: translate('location.oporto'),
    //   img: '/images/location/oporto.jpg',
    //   regions: ['Porto', 'Vila Nova de Gaia', 'Matosinhos', 'Maia', 'Valongo', 'Gondomar'],
    // },
  ]

  return (
    <>
      <ContactFormModal
        open={openModal}
        handleClose={(event, reason) => {
          setOpenModal(false);
        }}
      />
      <WhatsAppWidget />
      <div
        style={{
          backgroundColor: BRIGHT_GRAY,
          height: 500,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: theme.spacing(2),
          paddingTop: theme.spacing(10)
        }}
      >
        {tabletDown && <ClickableLogo imgStyles={{ width: 200 }} />}
        <Typography
          fontSize={tabletDown ? 40 : 74}
          fontFamily={"Poppins"}
          fontWeight={700}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: BONDI_BLUE,
            textAlign: "center",
          }}
        >
          <Text id="location.ourLocation" />
        </Typography>
        <Typography
          style={{
            maxWidth: 900,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            textAlign: "center",
          }}
          fontSize={18}
          color={CLOUD_BURST}
          fontFamily={"Poppins"}
        >
          <Text id="location.closeToYou" />
        </Typography>
        <VButton variant={'contained'} showDot={false}
          onClick={() => {
            const el = document.getElementById('locations');
            el.scrollIntoView({ block: "center", behavior: "smooth" });
          }}
        >
          <ArrowDownwardIcon />
        </VButton>
      </div>
      <Box id="locations" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
        <Box sx={{
          "&:hover": {
            cursor: "move",
          },
          width: tabletDown ? '100%' : "60%"
        }}>
          <MySwiper
            SlideComponent={({ isFirst, isLast, img, name, regions, ...props }) => {
              return (<div
                style={{
                  margin: tabletDown ? 10 : 40,
                }}
              >
                <ImageWithText
                  imageStyle={{
                    "&:hover": {
                      cursor: "move",
                    },
                    width: '100%', height: 300, displa: 'flex',
                    backgroundImage: `linear-gradient(
                  0deg,
                  ${hexRgb(CLOUD_BURST, { format: "css", alpha: 0.5 })},
                  ${hexRgb(CLOUD_BURST, { format: "css", alpha: 0.5 })}
                ), url(${img}
              )`,
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), padding: theme.spacing(1) }}>
                    <Typography color={'white'} fontWeight={700} variant={tabletDown ? 'h3' : "h2"} style={{ paddingTop: 20 }}>{name}</Typography>
                    <RegionList regions={regions}></RegionList>
                  </div>
                </ImageWithText>
              </div>)
            }}
            contents={contents}
            spaceBetween={tabletDown ? 30 : 10}
            slidesPerView={tabletDown ? 1.2 : 2.5}
          />
        </Box>
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '20px 0',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
            alignSelf: 'center',
            fontFamily: 'Alfa Slab One',
            gap: 10,
            color: CLOUD_BURST,
            padding: theme.spacing(1),
          }}>
            <Typography variant='h3' fontSize={mobile ? 15 : 25} fontFamily={'inherit'} color={'inherit'}><Text id='location.noLocation.question' /></Typography>
            <Typography variant='h3' fontSize={mobile ? 12 : 20} fontFamily={'inherit'} color={'inherit'}><Text id='location.noLocation.foundPhysio' /></Typography>
            <Typography variant='h3' fontSize={mobile ? 12 : 20} fontFamily={'inherit'} color={'inherit'}><Text id='location.noLocation.contactUs' />.</Typography>
            <VButton
              variant='contained'
              color='primary'
              onClick={() => setOpenModal(true)}
              style={{ fontSize: tabletDown ? '12px' : '20px' }}
            >
              <Text id='schedule.name' />
            </VButton>
          </div>
        </div>
      </Box>
    </>);
};

export default Location;