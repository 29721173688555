import { Typography, useTheme } from "@mui/material";
import VButton from "../../../../components/V/VButton/index.js";
import {
  BONDI_BLUE,
  BRIGHT_GRAY,
  CLOUD_BURST,
  FUZZY_WUZZY,
  LIGHT_CYAN,
} from "../../../../constants.js/index.js";
import LaunchIcon from "@mui/icons-material/Launch";
import hexRgb from "hex-rgb";
import { useBreakpoints } from "react-match-breakpoints";

export default function Product({ name, description, img, link }) {
  const theme = useTheme();
  const { mobile } = useBreakpoints();

  return (
    <div
      style={{
        padding: theme.spacing(mobile ? 2 : 2),
        margin: theme.spacing(mobile ? 1 : 0),
        display: "flex",
        flexWrap: "wrap",
        gap: theme.spacing(2),
        borderRadius: 10,
        border: `2px solid ${hexRgb(BRIGHT_GRAY, {
          alpha: 0.4,
          format: "css",
        })}`,
        justifyContent: "center",
      }}
    >
      <img
        style={{
          borderRadius: 10,
          objectFit: "contain",
        }}
        width={mobile ? "100px" : "100px"}
        src={img}
        alt=""
      />
      <div
        style={{
          borderRadius: 10,
          border: `1px solid ${hexRgb(BRIGHT_GRAY, {
            alpha: 0.4,
            format: "css",
          })}`,
          padding: theme.spacing(mobile ? 1 : 2),
          backgroundColor: hexRgb(BRIGHT_GRAY, { alpha: 0.2, format: "css" }),
          width: mobile ? "100%" : 300,
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(mobile ? 2 : 2),
        }}
      >
        <Typography
          variant="body1"
          color={BONDI_BLUE}
          style={{ fontWeight: 700 }}
        >
          {name}
        </Typography>
        <Typography variant="body2" color={CLOUD_BURST}>
          {description}
        </Typography>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <VButton
            variant="contained"
            color="primary"
            showDot={false}
            style={{
              padding: theme.spacing(1),
              alignSelf: "flex-end",
            }}
            onClick={() => window.open(link)}
          >
            <div
              style={{ display: "flex", gap: theme.spacing(1), padding: 10 }}
            >
              <Typography>Mais</Typography>
              <LaunchIcon />
            </div>
          </VButton>
        </div>
      </div>
    </div>
  );
}
