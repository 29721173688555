import React, { useState } from "react";
import { Button, useTheme } from "@mui/material";
import { BONDI_BLUE, CLOUD_BURST } from "../../../constants.js";
import hexRgb from "hex-rgb";
import { motion } from "framer-motion";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { KeyboardArrowUp } from "@mui/icons-material";

const Arrow = ({ Arrow, color }) => {
  const theme = useTheme();
  {
    return (
      <div
        style={{
          width: 20,
          height: 20,
          position: "relative",
          background: color === "secondary" ? BONDI_BLUE : "white",
          borderRadius: "50%",
          display: "flex",
        }}
      >
        <Arrow
          style={{
            width: "100%",
            height: "100%",
            color: color === "secondary" ? "white" : BONDI_BLUE,
          }}
        />
      </div>
    );
  }
};

const VButton = ({
  variant = "contained",
  color = "primary",
  style,
  children,
  motionProps,
  showDot = "right",
  ...props
}) => {
  const theme = useTheme();
  const [isHover, setIsHover] = useState(false);

  const variants = {
    contained: {
      ...(color === "primary" && {
        backgroundColor: BONDI_BLUE,
      }),
      ...(color === "secondary" && {
        color: BONDI_BLUE,
        backgroundColor: "white",
      }),
    },
    text: {
      ...(color === "primary" && {
        color: BONDI_BLUE,
        ...(isHover && {
          backgroundColor: hexRgb(BONDI_BLUE, { format: "css", alpha: 0.1 }),
        }),
      }),
      ...(color === "secondary" && {
        color: CLOUD_BURST,
        ...(isHover && {
          backgroundColor: hexRgb(CLOUD_BURST, { format: "css", alpha: 0.1 }),
        }),
      }),
    },
    outlined: {
      ...(color === "primary" && {
        color: BONDI_BLUE,
        borderColor: BONDI_BLUE,
        ...(isHover && {
          backgroundColor: hexRgb(BONDI_BLUE, { format: "css", alpha: 0.1 }),
        }),
      }),
      ...(color === "secondary" && {
        color: CLOUD_BURST,
        borderColor: CLOUD_BURST,
        ...(isHover && {
          backgroundColor: hexRgb(CLOUD_BURST, { format: "css", alpha: 0.1 }),
        }),
      }),
    },
  };

  return (
    <Button
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      variant={variant}
      style={{
        width: "fit-content",
        borderRadius: 38,
        fontWeight: 600,
        padding: theme.spacing(2),
        ...variants[variant],
        ...style,
      }}
      {...props}
    >
      {showDot === "left" && (
        <div style={{ marginRight: theme.spacing(1) }}>
          <Arrow Arrow={KeyboardArrowLeftIcon} color={color} />
        </div>
      )}
      {children}
      {showDot === "right" && (
        <div style={{ marginLeft: theme.spacing(1) }}>
          <Arrow Arrow={KeyboardArrowRightIcon} color={color} />
        </div>
      )}
      {showDot === "up" && (
        <div style={{ marginLeft: theme.spacing(1) }}>
          <Arrow Arrow={KeyboardArrowUp} color={color} />
        </div>
      )}
    </Button>
  );
};

export default VButton;
