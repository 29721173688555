import { Fab, Typography, useTheme } from "@mui/material";
import AddClientForm from "../Modal/AddClientForm";
import ContactFormModal from "../Modal/ContactFormModal";
import TherapyPlatForm from "../Modal/TherapyPlatForm";
import { CLOUD_BURST } from "../../constants.js";
import VButton from "../V/VButton/index.js";
import { useState } from "react";
import EvaluationForm from "../Modal/EvaluationForm/index.js";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RemoveIcon from "@mui/icons-material/Remove";
import { Text } from "react-internationalization";

export const TherapistWidget = () => {
    const [evaluationModal, setEvaluationModal] = useState(false);
    const [addClientForm, setAddClientForm] = useState(false);
    const [therapyModal, setTherapyModal] = useState(false);
    const [openContactModal, setOpenContactModal] = useState(false);


    const [showAllForms, setShowAllForms] = useState(false);

    const theme = useTheme();


    return (<>
        <EvaluationForm
            open={evaluationModal}
            handleClose={(event, reason) => {
                setEvaluationModal(false);
            }}
        />
        <AddClientForm
            open={addClientForm}
            handleClose={(event, reason) => {
                setAddClientForm(false);
            }}
        />
        <TherapyPlatForm
            open={therapyModal}
            handleClose={(event, reason) => {
                setTherapyModal(false);
            }}
        />
        <ContactFormModal
            open={openContactModal}
            handleClose={(event, reason) => {
                setOpenContactModal(false);
            }}
            HeaderComponent={() => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: theme.spacing(2),
                            marginRight: theme.spacing(3),
                        }}
                    >
                        <img
                            loading="lazy"
                            width="40px"
                            height={"100%"}
                            src="images/logo_circulo.webp"
                            alt="Home page"
                        />
                        <Typography
                            variant="body1"
                            style={{
                                color: CLOUD_BURST,
                                fontWeight: 700,
                            }}
                        >
                            <Text id="therapist.workWithUs" />
                        </Typography>
                    </div>
                );
            }}
        />
        <div
            style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                margin: "20px",
                zIndex: 999,
                display: "flex",
                gap: 20,
            }}
        >
            <Fab
                onClick={() => {
                    setShowAllForms(!showAllForms);
                }}
                size={"large"}
                color={showAllForms ? "gray" : "primary"}
            >
                {showAllForms ? <RemoveIcon /> : <SummarizeIcon />}
            </Fab>
            {showAllForms && (
                <>
                    <div
                        style={{
                            position: "absolute",
                            bottom: 70,
                            right: 0,
                            display: "flex",
                            flexDirection: "column",
                            width: 150,
                            gap: 20,
                        }}
                    >
                        <VButton
                            showDot="up"
                            style={{ fontSize: 12 }}
                            onClick={() => setAddClientForm(true)}
                        >
                            <Text id="addClient" />
                        </VButton>
                        <VButton
                            showDot="up"
                            style={{ fontSize: 12 }}
                            onClick={() => setEvaluationModal(true)}
                        >
                            <Text id="clientEvaluation" />
                        </VButton>
                        <VButton
                            onClick={() => setTherapyModal(true)}
                            showDot="up"
                            style={{ fontSize: 12 }}
                        >
                            <Text id="evaluationPlan" />
                        </VButton>
                    </div>
                </>
            )}
        </div>
    </>
    )
}