import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const withConfirmationDialog = (WrappedComponent) => {
    return (props) => {
        const [open, setOpen] = useState(false);
        const [deleteFunction, setDeleteFuction] = useState(null);

        const handleClickOpen = (func) => {
            setDeleteFuction(() => func);
            setOpen(true);
        };

        const handleClose = async (confirmed) => {
            setOpen(false);
            if (confirmed) {
                await deleteFunction();
                // Add logic to delete the item here
            }
            setDeleteFuction(null);
        };

        return (
            <>
                <WrappedComponent
                    {...props}
                    openDialog={handleClickOpen}
                />
                <Dialog onClose={() => handleClose(false)} open={open}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <div style={{ padding: 20 }}>
                        {deleteFunction ? (
                            <Typography variant="body1">
                                Are you sure you want to delete the item?
                            </Typography>
                        ) : (
                            <Typography variant="body1">
                                Are you sure you want to delete this item?
                            </Typography>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleClose(false)}
                                style={{ marginRight: 10 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleClose(true)}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </>
        );
    };
};

export default withConfirmationDialog;
