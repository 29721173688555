import React, { memo } from "react";
import { Typography, useTheme } from "@mui/material";
import { AQUA_SQUEEZE, CLOUD_BURST } from "../../../constants.js/index.js";
import { Text } from "react-internationalization";
import { useBreakpoints } from "react-match-breakpoints";

const Image = ({ img }) => {
  return (
    <div
      style={{
        borderRadius: "10px",
        backgroundColor: AQUA_SQUEEZE,
        padding: 18,
        width: "fit-content",
      }}
    >
      <img loading="lazy" src={img} width={40} height={40} alt="VenturaCuida" />
    </div>
  );
};

const Title = ({ name }) => {
  const { tabletDown } = useBreakpoints();
  return (
    <Typography
      style={{
        fontSize: "20px",
        fontWeight: 600,
        color: 'white',
        textAlign: tabletDown ? "center" : "flex-end",
      }}
    >
      <Text id={name} />
    </Typography>
  );
};

export const Service = ({ name, img, description, id, ...props }) => {
  const { tabletDown } = useBreakpoints();
  const theme = useTheme();

  return (
    <div
      id={id}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignItems: tabletDown ? "center" : "flex-start",
      }}
      {...props}
    >
      <Image {...{ img }} />
      <Title {...{ name }} />
      <Typography
        color={CLOUD_BURST}
        style={{
          maxWidth: 347,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          color: 'white',
          opacity: 0.8
        }}
      >
        <Text id={description}></Text>
      </Typography>
    </div>
  );
};
