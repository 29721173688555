import { createTheme } from "@mui/material";
import { BONDI_BLUE } from "../../constants.js/index.js";
import { ThemeProvider } from "@mui/material/styles";

export const MyThemeProvider = ({ children }) => {
  const outerTheme = createTheme({
    palette: {
      primary: {
        main: BONDI_BLUE,
      },
    },
  });

  return <ThemeProvider theme={outerTheme}>{children}</ThemeProvider>;
};
