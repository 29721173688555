import { IconButton, Typography } from "@mui/material";
import { DataPlot } from "../DataPlot";
import opacity from "hex-color-opacity";
import { BONDI_BLUE } from "../../../constants.js";
import { useDashboardData } from "../../../hooks/useDashboardData/index.js";
import { useDataPlotData } from "../../../hooks/useDataPlotData/index.js";
import { textEditor } from "react-data-grid";
import { useMemo } from "react";
import usePersonalizedCols, { useOrderedCols } from "../../../hooks/usePersonalizedCols/index.js";
import { DeleteOutline } from "@mui/icons-material";
import { venturaCuidaApi } from "../../../api/venturaCuida/index.js";
import { useMutation, useQueryClient } from "react-query";
import withConfirmationDialog from "../../HOC/withConfirmDialog/index.js";
import { TabletDownDashboardChanger } from "../../TabletDownDashboardChanger/index.js";

const SHOW_COLS = [
    'user.name',
    'typeOfMassage',
    'information',
    'focusOnBodyParts',
    'medicalConditions',
    'createdAt',
    'actions'
];

const RESOURCE = 'pre-appointments';

const PreAppointments = ({ openDialog }) => {
    // Fetch data for pre-appointments
    const { data, isLoading, queryKey: mainResourceQueryKey } = useDashboardData({ resource: RESOURCE });

    const [initialColumns, initialRows] = useDataPlotData({
        columnsProps: {
            renderEditCell: textEditor,
        },
        data,
        extraCols: [{ key: 'actions', name: 'actions' }],
        isLoading,
    });

    const queryClient = useQueryClient();

    const deleteBookingMutation = useMutation({
        mutationFn: (data) => {
            return venturaCuidaApi().delete(`/api/admin/${RESOURCE}/${data.preAppointmentId}`);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [mainResourceQueryKey] });
        },
    });

    const columnsBluePrint = useMemo(() => {
        return {
            admin: {
                cols: SHOW_COLS,
                'user.name': {
                    renderCell: ({ row }) => {
                        return row?.user?.name;
                    },
                },
                'actions': {
                    renderCell: ({ row }) => {
                        return (
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <IconButton
                                    onClick={() => openDialog(() => {
                                        deleteBookingMutation.mutate({ preAppointmentId: row._id });
                                    })}
                                    size="small"
                                >
                                    <DeleteOutline />
                                </IconButton>
                            </div >
                        );
                    },
                }
            }
        }
    }, [SHOW_COLS]);

    const personalizedCols = usePersonalizedCols({
        initialColumns,
        columnsProps: {
            renderEditCell: null,
        },
        COLUMNS_BLUEPRINT: columnsBluePrint,
    });

    const orderedCols = useOrderedCols(personalizedCols, SHOW_COLS);

    return (
        <div style={{ height: '100%', width: '100%', overflowX: 'scroll' }}>
            {!isLoading && orderedCols && initialRows && (
                <DataPlot
                    dataGridProps={{
                        onColumnResize: (...props) => {
                            console.log(props[1]);
                            return Math.max([props[1], 100])
                        }
                    }}
                    columns={orderedCols}
                    rows={initialRows}
                    Header={() => (
                        <div
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: opacity(BONDI_BLUE, 0.7),
                                width: '100%',
                                height: 60,
                            }}
                        >
                            <TabletDownDashboardChanger currentPage={'Pré-agendamento'} />
                        </div>
                    )}
                    {...{ data, isLoading }}
                />
            )}
        </div>
    );
};

const PreAppointmentsWithDialog = withConfirmationDialog(PreAppointments);

export default PreAppointmentsWithDialog;
