import { useQuery } from "react-query"
import { useLocation } from "react-router-dom";
import { venturaCareApi } from "../../../api/venturaCuida";
import ReservationItem from "../Admin/Mobile/ReservationItem";
import { useBreakpoints } from "react-match-breakpoints";
import { Alert, CircularProgress } from "@mui/material";
import { ClickableLogo } from "../../../components/ClickableLogo";
import { WhatsAppWidget } from "../../../components/WhatsappWidget";

export const Schedule = () => {
    const { tabletDown } = useBreakpoints();
    const location = useLocation();

    const email = new URLSearchParams(location?.search)?.get?.('therapist');

    const { data, isLoading } = useQuery('schedule' + email, async () => {
        const data = await venturaCareApi().get(`/schedule?therapist=${email}`);
        return data?.data;
    });

    return <div style={{ flexDirection: 'column', gap: 10, marginTop: tabletDown ? 50 : 200, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <WhatsAppWidget />
        {tabletDown && <ClickableLogo imgStyles={{ width: 150 }} />}
        {!isLoading
            ? <div style={{ display: 'flex', flexDirection: 'column', gap: 10, maxWidth: 500 }}>
                {data?.map?.((reservation, index) => <ReservationItem
                    {...{ reservation, index }}
                />)}
            </div>
            : <div style={{ width: '100%', height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        }
    </div>

}