import uniqBy from "lodash/uniqBy";
import difference from "lodash/difference";
import { useMemo } from "react";

function flattenObject(obj, res = {}) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            // If the value is an object and not an array, flatten it
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                // Loop through the child object properties
                for (let childKey in obj[key]) {
                    if (obj[key].hasOwnProperty(childKey)) {
                        // Combine the parent key with the child key
                        const newKey = key + '.' + childKey;
                        res[newKey] = obj[key][childKey];
                    }
                }
            } else {
                // If no child object, just copy the property
                res[key] = obj[key];
            }
        }
    }
    return res;
}

// Helper function to capitalize the first letter of nested keys
function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

// Example usage:



export const useDataPlotData = ({ isLoading, data, extraCols = [], columnsProps = {}, columnsBlackListed = [] }) => {
    return useMemo(() => {
        if (!isLoading && data?.[0]) {
            let columns = uniqBy(data.reduce((accum, row) => {
                const columns = difference(Object.keys(flattenObject(row)), columnsBlackListed)?.reduce?.((accum, key) => {
                    return [...accum, { key, name: key }];
                }, []);
                return [...accum, ...columns];
            }, []), 'key');

            columns = [...columns, ...extraCols]?.map((c) => ({ ...c, ...columnsProps }))

            return [columns, data];
        }
        return [null, null];
    }, [isLoading, columnsBlackListed, columnsProps]);

}