import { Typography } from "@mui/material";
import { CULTERED } from "../../../../../constants.js/index.js";
import { EmailOutlined, Person, PhoneIphone } from "@mui/icons-material";
import SpaIcon from '@mui/icons-material/Spa';
import InfoIcon from '@mui/icons-material/Info';
import WomanIcon from '@mui/icons-material/Woman';
import ManIcon from "@mui/icons-material/Man";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import { withSnackbar } from "notistack";
import { memo } from "react";

function PreAppointmentItem({ preAppointment }) {

    const handleClick = () => {
        navigator.clipboard.writeText(`
Pre-appointment Details:

Name: ${preAppointment?.Name}
Birthday: ${preAppointment?.Birthday}
Gender: ${preAppointment?.Gender}
Type of Massage: ${preAppointment?.typeOfMassage || 'None'}
Focus on Body Parts: ${preAppointment?.focusOnBodyParts || 'None'}
Medical Conditions: ${preAppointment?.medicalConditions || 'None'}
Other Medical Condition: ${preAppointment?.otherMedicalCondition || 'None'}

        `);
    };

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: CULTERED,
                padding: 20,
                borderRadius: 3,
                position: 'relative',
            }}
        >
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <IconButton onClick={handleClick}>
                    <ContentCopyIcon />
                </IconButton>
            </div>
            <div style={{ flexDirection: 'column', display: 'flex', gap: 20 }}>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <Person />
                    <Typography
                        style={{}}
                    >
                        {preAppointment.Name}
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    {preAppointment.Gender === 'Men' && <ManIcon />}
                    {preAppointment.Gender === 'Woman' && <WomanIcon />}
                    <Typography>{preAppointment.Gender}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <SpaIcon />
                    <Typography>{preAppointment.typeOfMassage}</Typography>
                </div>
                {preAppointment.Information && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <InfoIcon />
                    <Typography>{preAppointment.Information}</Typography>
                </div>}
                {preAppointment['Telemóvel'] && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <PhoneIphone />
                    <Typography>{preAppointment.Phone}</Typography>
                </div>}
                {preAppointment.Email && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <EmailOutlined />
                    <Typography>{preAppointment.Email}</Typography>
                </div>}
                {preAppointment.focusOnBodyParts && <div style={{ flexDirection: 'column', display: 'flex', gap: 8 }}>
                    <Typography>Massage Preferences</Typography>
                    <Typography style={{ fontSize: 12 }}>{preAppointment.focusOnBodyParts}</Typography>
                </div>}
                {preAppointment.medicalConditions && <div style={{ flexDirection: 'column', display: 'flex', gap: 8 }}>
                    <Typography>Medical condition</Typography>
                    <Typography style={{ fontSize: 12 }}>{preAppointment.medicalConditions}</Typography>
                </div>}
                {preAppointment.otherMedicalCondition && <div style={{ flexDirection: 'column', display: 'flex', gap: 8 }}>
                    <Typography>Other medical conditions</Typography>
                    <Typography style={{ fontSize: 12 }}>{preAppointment.otherMedicalCondition}</Typography>
                </div>}
            </div>
        </div >
    )
}

export default memo(withSnackbar(PreAppointmentItem));
