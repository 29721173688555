import { useMutation, useQueryClient } from "react-query";
import { venturaCuidaApi } from "../../../api/venturaCuida/index.js";
import { Typography } from "@mui/material";
import { useAuth } from "../../../contexts/AuthProvider/index.js";
import { useMemo, useState } from "react";
import 'react-data-grid/lib/styles.css';
import { DataPlot } from "../DataPlot/index.js";
import opacity from "hex-color-opacity";
import { BONDI_BLUE } from "../../../constants.js/index.js";
import { VButton } from "../../NewHomeComponents/VButton/index.js";
import { useDashboardData } from "../../../hooks/useDashboardData/index.js";
import { textEditor, Row, useRowSelection } from "react-data-grid";
import { useDataPlotData } from "../../../hooks/useDataPlotData/index.js";
import { createRender, EditableDataPlotContext, EditableDataPlotProvider, renderEditCellArrayType } from "../../../contexts/EditDataPlotProvider/index.js";
import usePersonalizedCols, { useOrderedCols } from "../../../hooks/usePersonalizedCols/index.js";
import useLocalStorage from "use-local-storage";
import { get, set } from "lodash";
import { TabletDownDashboardChanger } from "../../TabletDownDashboardChanger/index.js";
import { useBreakpoints } from "react-match-breakpoints";
import { Business, ManageAccounts, People } from "@mui/icons-material";

const RESOURCE = 'user';

const COLUMNS_BLUEPRINT = {
    therapist: {
        cols: ['name', 'email', 'phone', 'therapist.workMunicipalities', 'therapist.workAreas', 'therapist.practicedLanguages', 'nib'],
        name: {
        },
        'therapist.workMunicipalities': {
            renderCell: ({ row }) => {
                return row?.therapist?.workMunicipalities;
            },
            renderEditCell: createRender((value) => [value])
        },
        'therapist.workAreas': {
            renderCell: ({ row }) => {
                return row?.therapist?.workAreas;
            },
            renderEditCell: createRender((value) => [value])
        },
        'therapist.practicedLanguages': {
            renderCell: ({ row }) => {
                return row?.therapist?.practicedLanguages;
            },
            renderEditCell: createRender((value) => [value])
        }
    },
    partnership: {
        cols: ['name', 'email', 'phone', 'nib', 'address', 'slug'],
        name: {
        },
    },
    client: {
        cols: ['name', 'email', 'phone', 'client.partnership', 'client.age', 'client.profession', 'client.gender', 'client.registeredBy'],
        name: {
        },
        'client.partnership': {
            renderCell: ({ row }) => {
                return row?.client?.partnership?.name;
            },
            renderEditCell: null
        },
        'client.gender': {
            renderCell: ({ row }) => {
                return row?.client?.gender;
            },
            renderEditCell: null
        },
        'client.age': {
            renderCell: ({ row }) => {
                return row?.client?.age;
            },
            renderEditCell: null
        },
        'client.profession': {
            renderCell: ({ row }) => {
                return row?.client?.profession;
            },
            renderEditCell: null
        },
        client_gender: {
            renderCell: ({ row }) => {
                return row?.client?.gender;
            },
            renderEditCell: null
        },
        client_registeredBy: {
            renderCell: ({ row }) => {
                return row?.client?.registeredBy?.name;
            },
            renderEditCell: null
        },
    }
}


export const Users = () => {
    const [_, setAddSessionOpen] = useState(false);
    const [roleFilter, setRoleFilter] = useLocalStorage('roleFilter', 'client');
    const { data, isLoading } = useDashboardData({ resource: RESOURCE });
    const { user } = useAuth();

    let [initialColumns, initialRows] = useDataPlotData({
        columnsProps: {
            renderEditCell: textEditor,
        },
        data,
        isLoading,
        columnsBlackListed: ['_id', '__v', 'createdAt']
    });

    const colsByRole = usePersonalizedCols({ roleFilter, initialColumns, COLUMNS_BLUEPRINT });
    const colsByRoleOrdered = useOrderedCols(colsByRole, COLUMNS_BLUEPRINT[roleFilter]?.cols)

    const updateDataMutation = useMutation({
        mutationFn: (data) => {
            return venturaCuidaApi().put('api/admin/' + RESOURCE, data)
        },
    });

    const count = useMemo(() => {
        return initialRows?.reduce((accum, col) => {
            return {
                therapist: accum.therapist + (col?.role === 'therapist' ? 1 : 0),
                client: accum.client + (col?.role === 'client' ? 1 : 0),
                partnership: accum.partnership + (col?.role === 'partnership' ? 1 : 0),
            }
        }, { therapist: 0, client: 0, partnership: 0 });
    }, [initialRows]);

    const queryClient = useQueryClient();

    const { mobile } = useBreakpoints();

    return <div style={{ height: '100%', width: '100%', overflowX: 'scroll' }}>
        {!isLoading && data &&
            <EditableDataPlotProvider rows={initialRows} roleFilter={roleFilter}>
                <EditableDataPlotContext.Consumer>
                    {({ isEdited, changedRows, reset, setEditedRows, editedRows, rowKeyGetter }) => {
                        const filtredByrole = roleFilter ? editedRows.filter(({ role }) => role === roleFilter) : editedRows;
                        return (
                            <DataPlot
                                columns={colsByRoleOrdered}
                                rows={filtredByrole}
                                dataGridProps={{
                                    onRowsChange: setEditedRows,
                                    rowKeyGetter,
                                }}
                                Header={() => <div style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: opacity(BONDI_BLUE, 0.7),
                                    width: '100%',
                                    height: 60
                                }}>
                                    <div style={{ display: 'inline-flex', gap: 10, alignItems: 'center' }}>
                                        <TabletDownDashboardChanger currentPage={'Utilizadores'} />
                                        <VButton
                                            style={{ color: 'white' }}
                                            variant={roleFilter === 'therapist' ? 'contained' : "outlined"}
                                            onClick={() => setRoleFilter('therapist')}
                                        >
                                            {mobile ? <ManageAccounts /> : `Terapeutas ${count?.therapist}`}
                                        </VButton>
                                        <VButton
                                            style={{ color: 'white' }}
                                            variant={roleFilter === 'partnership' ? 'contained' : "outlined"}
                                            onClick={() => setRoleFilter('partnership')}
                                        >
                                            {mobile ? <Business /> : `Terapeutas ${count?.partnership}`}
                                        </VButton>
                                        <VButton
                                            style={{ color: 'white' }}
                                            variant={roleFilter === 'client' ? 'contained' : "outlined"}
                                            onClick={() => setRoleFilter('client')}
                                        >
                                            {mobile ? <People /> : `Clientes ${count?.client}`}
                                        </VButton>
                                    </div>
                                    {!mobile && <div>
                                        <VButton
                                            sx={{ color: 'white' }}
                                            variant="text"
                                            loading={updateDataMutation.isLoading}
                                            disabled={!isEdited} onClick={() => {
                                                updateDataMutation.mutate(changedRows, {
                                                    onSuccess: () => {
                                                        console.log('onSuccess')
                                                        queryClient.invalidateQueries({ queryKey: [`${RESOURCE}-${user._id}`] })
                                                        reset();
                                                    },
                                                })
                                            }}>
                                            Guardar
                                        </VButton>
                                    </div>}
                                </div>
                                }
                                {...{ data, isLoading }}
                            />)
                    }}
                </EditableDataPlotContext.Consumer>
            </EditableDataPlotProvider >
        }
    </div >
}