import { useQuery } from "react-query"
import { venturaCuidaApi } from "../../../api/venturaCuida";
import ReservationItem from "../Admin/Mobile/ReservationItem";
import { useBreakpoints } from "react-match-breakpoints";
import { Alert, CircularProgress } from "@mui/material";
import { ClickableLogo } from "../../../components/ClickableLogo";
import { WhatsAppWidget } from "../../../components/WhatsappWidget";
import { useAuth } from "../../../contexts/AuthProvider";

export const PartnershipReservations = () => {
    const { tabletDown } = useBreakpoints();

    const { isLoggedIn } = useAuth();

    const { data, isLoading } = useQuery('api/excel/partnership-reservations', async () => {
        const data = await venturaCuidaApi().get(`api/excel/partnership-reservations`);
        return data?.data;
    }, { enabled: isLoggedIn });

    return <div style={{ flexDirection: 'column', gap: 10, marginTop: tabletDown ? 50 : 200, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <WhatsAppWidget />
        {tabletDown && <ClickableLogo imgStyles={{ width: 150 }} />}
        {!isLoading
            ? <div style={{ display: 'flex', flexDirection: 'column', gap: 10, maxWidth: 500 }}>
                <Alert severity="warning">
                    Informo que este sistema é novo. <br />
                    Se encontrar algum erro, ou tem sugestões de melhoria, contacte-nos sobre o assunto pelo Whatsapp.
                </Alert>
                {data?.map?.((reservation, index) => <div key={`reservation-${index}`}>
                    <ReservationItem
                        {...{ reservation, index }}
                    />
                </div>)}
            </div>
            : <div style={{ width: '100%', height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        }
    </div>

}