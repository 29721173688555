import { IconButton, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { FadeInWhenVisible } from "../FadeWhenVisible/index.js";
import { times } from "lodash";
import { Text } from "react-internationalization";
import { memo } from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const Review = memo(({ link, name, stars, text }) => {

  const { spacing } = useTheme();

  return (
    <a
      href={link}
      style={{ textDecoration: "none", color: "black" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Typography
          style={{
            color: 'white',
          }}
        >
          <Text id={text} />
        </Typography>
        <div
          style={{
            paddingTop: spacing(2),
            display: "flex",
            alignItems: "center",
            gap: 30,
            color: 'white',
          }}
        >
          <Typography
            variant="h2"
            style={{
              opacity: 0.6,
              fontSize: 24,
              fontWeight: 700
            }}
          >
            {name}
          </Typography>
          <motion.div layout style={{ display: "grid", gap: 2, width: 70 }}>
            {times(stars, (index) => (
              <FadeInWhenVisible
                key={`start-${index}`}
                duration={index / 4}
                style={{ gridRow: 1 }}
              >
                <img
                  loading="lazy"
                  width="15px"
                  src="/images/review/star.png"
                  alt="star"
                ></img>
              </FadeInWhenVisible>
            ))}
          </motion.div>
          <IconButton style={{ color: 'white' }}>
            <OpenInNewIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      </div>
    </a>
  );
});
