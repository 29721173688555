import React, { useMemo, useState } from "react";

export const BlurContext = React.createContext();

export const Blur = ({children})=> {

    const [isBlur, setBlur] = useState();

    const blurStyles = useMemo(()=> {
        return isBlur ? {
            /* Add the blur effect */
            filter: 'blur(4px)',
            WebkitFilter: 'blur(4px)',
        } : null;
    },[isBlur]);

    return (
        <BlurContext.Provider value={{setBlur}}>
            <div
                style={blurStyles}
            >
                {children}
            </div>
        </BlurContext.Provider>
    )
}