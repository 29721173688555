import { Typography } from "@mui/material";
import { CULTERED } from "../../../../../constants.js/index.js";
import { CopyAllOutlined, EmailOutlined, Link } from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';
import { withSnackbar } from "notistack";
import { memo } from "react";
import Business from "@mui/icons-material/Business.js";
import HomeIcon from '@mui/icons-material/Home';
import { useMutation } from "react-query";
import { venturaCareApi } from "../../../../../api/venturaCuida/index.js";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

export const useParthnerShipLink = () => {
    return useMutation({
        mutationFn: async (email) => {
            const link = await venturaCareApi().post(`/api/rot`, { email });
            return link?.data;
        },
    });
};

function PartnerShipItem({ partnership, ...props }) {

    const { enqueueSnackbar } = props;

    const getParthnerShip = useParthnerShipLink();

    const handleClick = (email) => {
        getParthnerShip.mutate(
            email,
            {
                onSuccess: (data) => {
                    navigator.clipboard.writeText(data);
                    enqueueSnackbar("Link copiado", { variant: "success" });
                },
                onError: (error) => {
                    // console.log({ error });
                    throw error;
                }
            }
        );
    };

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: CULTERED,
                padding: 20,
                borderRadius: 3,
                position: 'relative',
            }}
        >
            <div style={{ flexDirection: 'column', display: 'flex', gap: 8 }}>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <Business />
                    <Typography
                        style={{}}
                    >
                        {partnership?.['Nome da empresa']}
                    </Typography>
                </div>
                {partnership?.Information && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <InfoIcon />
                    <Typography>{partnership?.Information}</Typography>
                </div>}
                {partnership?.morada && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <HomeIcon />
                    <Typography>{partnership?.morada}</Typography>
                </div>}
                {partnership?.['Endereço de email'] && <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <EmailOutlined />
                    <Typography>{partnership['Endereço de email']}</Typography>
                    <IconButton onClick={() => {
                        enqueueSnackbar("Email copiado", { variant: "success" });
                        navigator.clipboard.writeText(partnership['Endereço de email'])
                    }}>
                        <CopyAllOutlined />
                    </IconButton>
                </div>}
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <MonitorHeartIcon />
                    <Typography>{`Link Pré-massagem`}</Typography>
                    <IconButton onClick={() => {
                        enqueueSnackbar(`Link pré-massagem copiado`, { variant: "success" });
                        navigator.clipboard.writeText(`https://www.venturacuida.com/client/pre-appointment/${partnership['id']}`)
                    }}>
                        <CopyAllOutlined />
                    </IconButton>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                    <Link />
                    <Typography>Link parceria: </Typography>
                    <IconButton onClick={() => handleClick(partnership?.['Endereço de email'])}>
                        <CopyAllOutlined />
                    </IconButton>
                </div>
            </div>
        </div >
    )
}

export default memo(withSnackbar(PartnerShipItem));