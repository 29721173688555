import { Fab } from "@mui/material";
import AddClientForm from "../Modal/AddClientForm/index.js";
import VButton from "../V/VButton/index.js";
import { useState } from "react";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RemoveIcon from "@mui/icons-material/Remove";
import ScheduleSession from "../Modal/ScheduleSession/index.js";

export const AdminWidget = () => {
    const [addClientForm, setAddClientForm] = useState(false);
    const [addSessionOpen, setAddSessionOpen] = useState(false);
    const [showAllForms, setShowAllForms] = useState(false);

    return (<>
        <ScheduleSession
            open={addSessionOpen}
            handleClose={() => setAddSessionOpen(false)}
        />
        <AddClientForm
            open={addClientForm}
            handleClose={(event, reason) => {
                setAddClientForm(false);
            }}
        />
        <div
            style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                margin: "20px",
                zIndex: 999,
                display: "flex",
                gap: 20,
            }}
        >
            <Fab
                onClick={() => {
                    setShowAllForms(!showAllForms);
                }}
                size={"large"}
                color={showAllForms ? "gray" : "primary"}
            >
                <div style={{ display: showAllForms ? 'flex' : 'none' }}> <RemoveIcon /></div>
                <div style={{ display: showAllForms ? 'none' : 'flex' }}> <SummarizeIcon /></div>
            </Fab>
            <div
                style={{
                    position: "absolute",
                    bottom: 70,
                    right: 0,
                    display: showAllForms ? "flex" : 'none',
                    flexDirection: "column",
                    width: 150,
                    gap: 20,
                }}
            >
                <VButton
                    showDot="up"
                    style={{ fontSize: 12 }}
                    onClick={() => setAddClientForm(true)}
                >
                    Adicionar cliente
                </VButton>
                <VButton
                    showDot="up"
                    style={{ fontSize: 12 }}
                    onClick={() => setAddSessionOpen(true)}
                >
                    Criar reserva
                </VButton>
            </div>
        </div>
    </>
    )
}