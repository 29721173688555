import React, { memo } from "react";
import { map } from "lodash";
import { GUNMETAL, SERVICES } from "../../constants.js";
import { motion } from "framer-motion";
import { Service } from "./Service/index.js";
import { useBreakpoints } from "react-match-breakpoints";

const ServicesComponent = memo(() => {
  const { mobile } = useBreakpoints();

  return (
    <motion.div
      layout
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexWrap: "wrap",
        width: mobile ? "100%" : "90%",
        rowGap: 80,
        columnGap: 60,
      }}
    >
      {map(SERVICES, (s, index) => (
        <Service {...{ ...s }} key={`service-${index}`} />
      ))}
    </motion.div>
  );
});

export default ServicesComponent;
