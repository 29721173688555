import { CircularProgress, Fab, TextField, Typography } from "@mui/material";
import { map, pick } from "lodash";
import React, { useMemo, useState } from "react";
import VButton from "../../../components/V/VButton/index.js";
import { BABY_PINK, VENTURACARE_SPREADSHEET } from "../../../constants.js";
import ScheduleSession from "../../../components/Modal/ScheduleSession/index.js";
import Desktop from "./Desktop/index.js";
import { useBreakpoints } from "react-match-breakpoints";
import Mobile from "./Mobile/index.js";
import { useSheet } from "../../../hooks/useVenturaCuidaSheet/index.js";
import { loginAttempt } from "../../../api/venturaCuida/index.js";
import { useAuth } from "../../../contexts/AuthProvider/index.js";
import { useSnackbar } from "notistack";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RemoveIcon from "@mui/icons-material/Remove";
import AddClientForm from "../../../components/Modal/AddClientForm/index.js";

export default function Admin() {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? <AdminLogin /> : <LogOutPage />;
}

const LogOutPage = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { authenticate } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const { mobile } = useBreakpoints();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 200,
        marginBottom: 200,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 10,
          borderRadius: 4,
          padding: 40,
          border: `2px solid ${BABY_PINK}`,
        }}
      >
        <Typography variant="body2">Administrador</Typography>
        <TextField
          style={{
            width: mobile ? '100%' : 400
          }}
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          style={{
            width: mobile ? '100%' : 400
          }}
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <VButton
          onClick={async () => {
            try {
              const { data } = await loginAttempt({
                email,
                password
              });

              const { token: accessToken, user } = data;
              user?.role.includes("admin") && authenticate({
                accessToken,
                user,
              });

            } catch (error) {
              enqueueSnackbar("Wrong password", { variant: "error" });
            }
          }}
          variant={"contained"}
          color={"primary"}
        >
          Submit
        </VButton>
      </div>
    </div>
  );
};

export function AdminLogin() {
  const { tabletDown } = useBreakpoints();

  const [addSessionOpen, setAddSessionOpen] = useState(false);
  const [addClientForm, setAddClientForm] = useState(false);
  const [showAllForms, setShowAllForms] = useState(false);

  const { data: reservations, isLoading } = useSheet(
    VENTURACARE_SPREADSHEET.tabs.Reservas
  );

  const { data: clients, isLoading: isLoadingClients } = useSheet(
    VENTURACARE_SPREADSHEET.tabs.Clients
  );

  const { data: preAppointment, isLoading: isLoadingPreAppointment } = useSheet(
    VENTURACARE_SPREADSHEET.tabs.PreAppointment
  );

  const { data: partnerships, isLoading: isLoadingPartnerships } = useSheet(
    VENTURACARE_SPREADSHEET.tabs.Parcerias
  );

  const { data: therapists, isLoading: isLoadingTherapists } = useSheet(
    VENTURACARE_SPREADSHEET.tabs.Fisioterapeutas
  );

  const sessionsPlot = useMemo(() => {
    if (!reservations?.headerValues) return;
    return {
      cols: map(reservations?.headerValues, (col) => ({ id: col, label: col })),
      rows: reservations?.data?.sort(function (a, b) {
        return new Date(b["Data da sessão"]) - new Date(a["Data da sessão"]);
      }).filter((r) => r['Data da sessão']),
    }
  }, [reservations]);

  const preAppointmentPlot = useMemo(() => {
    if (!preAppointment?.headerValues) return;
    return {
      cols: map(preAppointment?.headerValues, (col) => ({ id: col, label: col })),
      rows: preAppointment?.data?.sort(function (a, b) {
        return new Date(b["Carimbo de data/hora"]) - new Date(a["Carimbo de data/hora"]);
      }),
    }
  }, [preAppointment]);

  const therapistsPlot = useMemo(() => {
    if (!therapists?.headerValues) return;
    return {
      cols: map(therapists?.headerValues, (col) => ({ id: col, label: col })),
      rows: therapists?.data?.sort(function (a, b) {
        return new Date(b["Carimbo de data/hora"]) - new Date(a["Carimbo de data/hora"]);
      }),
    }
  }, [therapists]);

  const clientsPlot = useMemo(() => {
    if (!clients?.headerValues) return;
    return {
      cols: map(clients?.headerValues, (col) => ({ id: col, label: col })),
      rows: clients?.data?.sort(function (a, b) {
        return new Date(b["Carimbo de data/hora"]) - new Date(a["Carimbo de data/hora"]);
      }),
    }
  }, [clients]);

  const partnershipsPlot = useMemo(() => {
    if (!partnerships?.headerValues) return;
    return {
      cols: map(partnerships?.headerValues, (col) => ({ id: col, label: col })),
      rows: partnerships?.data?.sort(function (a, b) {
        return new Date(b["Carimbo de data/hora"]) - new Date(a["Carimbo de data/hora"]);
      }).filter((p) => p['Carimbo de data/hora']),
    }
  }, [partnerships]);

  return (
    <>
      <ScheduleSession
        open={addSessionOpen}
        handleClose={() => setAddSessionOpen(false)}
      />
      <AddClientForm
        open={addClientForm}
        handleClose={(event, reason) => {
          setAddClientForm(false);
        }}
      />
      {isLoading || isLoadingClients || isLoadingPreAppointment || isLoadingPartnerships ? (
        <div
          style={{
            width: "100%",
            height: 400,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : tabletDown ? (
        <Mobile {...{
          sessionsPlot,
          clientsPlot,
          preAppointmentPlot,
          partnershipsPlot,
          therapistsPlot
        }} />
      ) : (
        <Desktop
          {...{
            sessionsPlot,
            clientsPlot,
            preAppointmentPlot,
            partnershipsPlot,
            therapistsPlot
          }}
        />
      )}
    </>
  );
}
