import { Button } from "@mui/material"


export const VButton = ({ children, style, ...props }) => {

    return <Button
        style={{ width: 'fit-content', height: 'fit-content', ...style }}
        variant="contained"
        {...props}
    >
        {children}
    </Button>
}