import { createContext, useContext, useEffect, useState } from "react";
import useLocalStorage from "use-local-storage";
import { getUserData } from "../../api/venturaCuida";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [token, setToken] = useLocalStorage("token", null);
  const [user, setUser] = useState(null);

  const logOut = () => {
    setToken(null);
  };

  const authenticate = ({ accessToken, user }) => {
    setToken(accessToken);
    setUser(user);
  };

  useEffect(() => {
    getUserData()
      .then(({ data }) => {
        const { token, user } = data;
        authenticate({ accessToken: token, user });
      })
      .catch((error) => {
      })
  }, []);


  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        logOut,
        authenticate,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
