import { Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useBreakpoints } from "react-match-breakpoints";
import { CLOUD_BURST } from "../../constants.js";
import { translate } from "react-internationalization";

const PolicyReader = () => {
    const { id } = useParams();
    const { mobile, tabletDown } = useBreakpoints();

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div style={{ width: tabletDown ? "100%" : "70%" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        dangerouslySetInnerHTML={{ __html: translate(`policy.${id}.content`) }}
                        style={{
                            maxWidth: mobile ? "90%" : "70%",
                            lineHeight: 1.5,
                            textAlign: "justify",
                            textJustify: "inter-word",
                            color: 'white',
                        }}
                    ></Typography>
                </div>
            </div>
        </div>
    );
};

export default PolicyReader;
