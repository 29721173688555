import {
  Alert,
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { groupBy } from "lodash";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { createContext, createRef, memo, useContext, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import VButton from "../../V/VButton";
import { withSnackbar } from "notistack";
import { MyDialog } from "../MyDialog";
import { useAddSheetRowData, useSheet } from "../../../hooks/useVenturaCuidaSheet";
import { BONDI_BLUE, VENTURACARE_SPREADSHEET } from "../../../constants.js/index.js";
import moment from "moment/moment";
import { DateAndHour } from "../DateAndHour/index.js";
import _ from "lodash";
import { useDashboardData } from "../../../hooks/useDashboardData";
import { Discount } from "@mui/icons-material";
import { useAuth } from "../../../contexts/AuthProvider";
import { createUTCDateTime } from "./helpers";
import { useMutation, useQueryClient } from "react-query";
import { venturaCuidaApi } from "../../../api/venturaCuida";

export const ScheduleSessionV2Context = createContext();

const RESOURCE = 'booking';

function ScheduleSessionV2({ open, handleClose, showReset, enqueueSnackbar }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const pricing = useDashboardData({ resource: 'pricing' });
  const { therapists, partnerships, clients, mainResourceQueryKey } = useContext(ScheduleSessionV2Context);

  const theme = useTheme();

  const { control, register, handleSubmit, reset, setValue, setFocus, watch, formState: { errors } } = useForm({
    shouldUnregister: false,
  });

  const therapist = watch("therapist");
  const service = watch("service");
  const partnership = watch("partnership");
  const client = watch("client");
  const totalServicePricePaidToTherapist = watch('totalServicePricePaidToTherapist');
  const therapistPay = watch("therapistPay");

  const isLoadingAll = useMemo(() => false, []);

  const createBookingMutation = useMutation({
    mutationFn: (data) => {
      return venturaCuidaApi().post('api/' + RESOURCE, data)
    },
  });

  const onError = (errors, e) => {
    enqueueSnackbar("Aconteceu um erro", { variant: "error" });
    setIsLoading(false);
  };

  const queryClient = useQueryClient()

  const onSubmit = async (data) => {

    const date = createUTCDateTime(data.date, data.hour);

    const newSession = {
      client: data.client._id,
      therapist: data.therapist._id,
      partnership: data.partnership._id,
      therapistPay: data.therapistPay || 0,
      totalServicePricePaidToTherapist: !!data.totalServicePricePaidToTherapist,
      totalServicePrice: data.pricing.totalPrice,
      notes: data.notes,
      duration: data.pricing.service.duration,
      service: data.pricing.service._id,
      discount: data.discount || 0,
      status: 'session_scheduled',
      createdBy: user._id,
      date
    }

    createBookingMutation.mutate(
      newSession, {
      onSuccess: () => {
        enqueueSnackbar("Registado com sucesso", { variant: "success" });
        queryClient.invalidateQueries({ queryKey: [mainResourceQueryKey] });
        handleClose();
      },
      onError,
    })
  };

  const pricingByPartnership = useMemo(() => {
    if (pricing.isLoading) return;
    return groupBy(pricing.data, ({ partnership }) => partnership.name)
  }, [pricing]);

  const inputRef = createRef();

  return (
    <MyDialog
      disableRestoreFocus
      stylesProps={{ width: "100%" }}
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div
        style={{
          padding: theme.spacing(3),
          paddingLeft: 20,
          paddingRight: 20,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Typography variant="body1" fontWeight={700} color={BONDI_BLUE}>
            Marcação de sessão
          </Typography>
          {showReset && <VButton
            showDot={null}
            onClick={() => {
              reset({});
            }}>
            <RestartAltIcon />
          </VButton>}
        </div>
        {!isLoadingAll && <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <Typography variant="body2">Terapeuta</Typography>
          <div style={{ display: "flex", gap: theme.spacing(2) }}>
            <Controller
              {...register('therapist')}
              control={control}
              name="therapist"
              render={({ field }) => (
                <Autocomplete
                  disabled={isLoadingAll}
                  value={therapist}
                  options={therapists}
                  getOptionLabel={(t) => t.name}
                  renderInput={(params) => (
                    <TextField {...params}
                      label="Terapeuta"
                    />
                  )}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </div>
          <Typography variant="body2">Parceria</Typography>
          <Controller
            {...register('partnership', { required: 'Selecione uma parceria' })}
            control={control}
            render={({ field }) => (
              <Autocomplete
                disabled={isLoadingAll}
                options={partnerships}
                value={partnership}
                getOptionLabel={(p) => p.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parceria"
                    error={!!errors.partnership}
                    helperText={errors.partnership ? "Parceria é obrigatória" : null}
                  />
                )}
                onChange={(e, value) => field.onChange(value)}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Typography variant="body2">Serviço</Typography>
          <div style={{ display: "flex", gap: theme.spacing(2) }}>
            <Controller
              {...register('pricing', { required: 'Selecione um serviço' })}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disabled={!partnership || isLoadingAll}
                  value={service}
                  options={pricingByPartnership[partnership?.name]}
                  getOptionLabel={(s) => s?.service?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Serviço"
                      error={!!errors.service}
                      helperText={errors.service ? "Serviço é obrigatório" : null} />
                  )}
                  onChange={(e, value) => {
                    console.log({ value }, inputRef.current);
                    setValue('therapistPay', value?.therapistPay, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
                    inputRef.current.value = therapistPay;
                    field.onChange(value);
                  }}
                  sx={{ width: "100%" }}
                />
              )}
            />
            <TextField
              disabled={isLoadingAll}
              type="number"
              style={{ width: "70%" }}
              {...register("discount", { valueAsNumber: true })}
              label="Desconto"
              variant="outlined"
            />
          </div>
          <TextField
            inputRef={inputRef}
            disabled={isLoadingAll}
            type="number"
            style={{ width: "100%" }}
            value={therapistPay}
            {...register("therapistPay", { required: true, valueAsNumber: true })}
            label="Valor a pagar ao terapeuta"
            variant="outlined"
            error={!!errors.therapistPay}
            helperText={errors.therapistPay ? "Valor a pagar é obrigatório" : null}
          />
          <FormControlLabel
            key={'totalServicePricePaidToTherapist'}
            control={
              <Checkbox
                onChange={(e) => { setValue(`totalServicePricePaidToTherapist`, !totalServicePricePaidToTherapist) }}
                checked={totalServicePricePaidToTherapist}
              />
            }
            label={'Terapeuta recebeu dinheiro do cliente'}
          />
          {totalServicePricePaidToTherapist && <Alert severity="info">O dinheiro recebido será descontado no final do mês dos serviços que forneceu á VenturaCuida</Alert>}
          <Typography variant="body2">Cliente</Typography>
          <Controller
            control={control}
            name="client"
            render={({ field }) => (
              <Autocomplete
                disabled={isLoadingAll}
                value={client}
                options={clients}
                getOptionLabel={(c) => c.name}
                renderInput={(params) => (
                  <TextField {...params} label="Cliente" error={!!errors["Nome do cliente"]} helperText={errors["Nome do cliente"] ? "Cliente é obrigatório" : null} />
                )}
                onChange={(e, value) => field.onChange(value)}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Typography variant="body2">Data/Hora</Typography>
          <FormProvider {...{ control }}>
            <DateAndHour dateName="date" hourName="hour" />
          </FormProvider>
          <TextField
            disabled={isLoadingAll}
            {...register("notes")}
            multiline
            minRows={3}
            label="Notas"
            variant="outlined"
          />
          <div style={{ display: "flex", gap: 10, alignSelf: "flex-end" }}>
            <VButton
              style={{ alignSelf: "flex-end" }}
              variant="contained"
              color="secondary"
              showDot={"left"}
              onClick={handleClose}
            >
              Cancelar
            </VButton>
            <VButton
              disabled={isLoadingAll}
              style={{ alignSelf: "flex-end" }}
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(onSubmit, onError)()}
            >
              Marcar
            </VButton>
          </div>
        </div>}
        {isLoadingAll && <div style={{ padding: 20, display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></div>}
      </div>
    </MyDialog>
  );
}

export default memo(withSnackbar(ScheduleSessionV2));
