import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  TextField,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { useEffect } from "react";

export const DateFormInputV2 = ({ name, registerOptions = {} }) => {
  const { setValue, register, watch, errors } = useFormContext();

  useEffect(() => {
    register(name, registerOptions);
    setValue(name, moment()?.format("YYYY/MM/DD"));
  }, []);

  const value = watch(name);
  const error = errors[name];

  return (
    <LocalizationProvider
      style={{ width: "100%" }}
      dateAdapter={AdapterDayjs}
    >
      <DatePicker
        inputFormat="YYYY/MM/DD"
        dateFormat="YYYY/MM/DD"
        format="YYYY/MM/DD"
        label={name}
        value={value}
        onChange={(e) => {
          setValue(name, e?.format("YYYY/MM/DD") || moment()?.format("YYYY/MM/DD"));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ width: "100%" }}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    </LocalizationProvider>
  );
};
