import React, { createRef } from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import NoPage from "./pages/Layout/NoPage";
import { internationalize } from "react-internationalization";
import Services from "./pages/Layout/Services";
import AboutUs from "./pages/Layout/AboutUs";
import Location from "./pages/Layout/Location";
import Blog from "./pages/Layout/Blog";
import BlogReader from "./pages/Layout/Blog/BlogReader";
import Admin, { AdminLogin } from "./pages/Layout/Admin";
import Recomendations from "./pages/Layout/Recomendations";
import { Providers } from "./contexts";
import { Therapist } from "./pages/Layout/Therapist";
import PolicyReader from "./components/PolicyReader";
import { Schedule } from "./pages/Layout/Schedule";
import PreAppointment from "./components/PreAppointment";
import ResetPassword from "./components/ResetPassword";
import { PartnershipReservationsWithAuth } from "./pages/Layout/PartnershipReservationsWithAuth";
import { NewHome } from "./pages/Layout/NewHome";
import "./index.css";
import { AnimatedLayoutRoutes, DashboardLayout } from "./pages/Layout";
import { Login } from "./pages/Layout/Login";
import { Corporate } from "./pages/Layout/Corporate";
import { DashboardTherapist } from "./components/Dashboard/DashboardTherapist";
import { Partnerhips } from "./components/Dashboard/Partnerships";
import { DashboardServices } from "./components/Dashboard/DashboardServices";
import { Pricing } from "./components/Dashboard/Pricing";
import { Users } from "./components/Dashboard/User";
import Sessions from "./components/Dashboard/Sessions";
import { TabletDownDashboardChanger } from "./components/TabletDownDashboardChanger";
import opacity from "hex-color-opacity";
import { BONDI_BLUE } from "./constants.js";
import PreAppointmentsWithDialog from "./components/Dashboard/PreAppointment/index.js";

// Define the route structure and createRef for each route
const routes = [
  { path: "/", name: "NewHome", element: <NewHome />, nodeRef: createRef() },
  { path: "/admin", name: "Admin", element: <Admin />, nodeRef: createRef() },
  { path: "/services", name: "Services", element: <Services />, nodeRef: createRef() },
  { path: "/hotel-airbnb", name: "Hotel & Airbnb", element: <Services />, nodeRef: createRef() },
  { path: "/corporate", name: "Corporate", element: <Corporate />, nodeRef: createRef() },
  { path: "/about-us", name: "AboutUs", element: <AboutUs />, nodeRef: createRef() },
  { path: "/location", name: "Location", element: <Location />, nodeRef: createRef() },
  { path: "/blog", name: "Blog", element: <Blog />, nodeRef: createRef() },
  { path: "/blog/:id", name: "BlogReader", element: <BlogReader />, nodeRef: createRef() },
  { path: "/recomendations", name: "Recomendations", element: <Recomendations />, nodeRef: createRef() },
  { path: "/therapist", name: "Therapist", element: <Therapist />, nodeRef: createRef() },
  { path: "/therapist/schedule", name: "Schedule", element: <Schedule />, nodeRef: createRef() },
  { path: "/client/pre-appointment/:partnership?", name: "PreAppointment", element: <PreAppointment />, nodeRef: createRef() },
  { path: "/partnership", name: "PartnershipReservationsWithAuth", element: <PartnershipReservationsWithAuth />, nodeRef: createRef() },
  { path: "/policy/:id", name: "PolicyReader", element: <PolicyReader />, nodeRef: createRef() },
  { path: "/set-new-password/:id", name: "ResetPassword", element: <ResetPassword />, nodeRef: createRef() },
  { path: "/login", name: "Login", element: <Login />, nodeRef: createRef() },
  { path: "*", name: "NoPage", element: <NoPage />, nodeRef: createRef() },
];

const dashboardRoutes = [
  { path: "/dashboard", name: "Sessions", element: <Sessions />, nodeRef: createRef() },
  { path: "/dashboard/sessions", name: "Sessions", element: <Sessions />, nodeRef: createRef() },
  { path: "/dashboard/therapists", name: "Therapists", element: <DashboardTherapist />, nodeRef: createRef() },
  { path: "/dashboard/partnerships", name: "Partnerships", element: <Partnerhips />, nodeRef: createRef() },
  { path: "/dashboard/services", name: "Services", element: <DashboardServices />, nodeRef: createRef() },
  { path: "/dashboard/pricing", name: "Pricing", element: <Pricing />, nodeRef: createRef() },
  { path: "/dashboard/users", name: "Users", element: <Users />, nodeRef: createRef() },
  { path: "/dashboard/pre-appointments", name: "Pre-appointments", element: <PreAppointmentsWithDialog />, nodeRef: createRef() },
  { path: "/dashboard/old-admin", name: "OldAdmin", element: <div style={{ width: '100%', overflowX: 'hidden' }}><div style={{ width: '100%', backgroundColor: opacity(BONDI_BLUE, 0.6) }}><TabletDownDashboardChanger currentPage={'Old Admin'} /></div><AdminLogin /></div>, nodeRef: createRef() },
]

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AnimatedLayoutRoutes routes={routes} />}>
          {/* Animate only child routes of Layout */}
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
        <Route path="/" element={<DashboardLayout />} >
          {dashboardRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function renderDom() {
  const InternationalApp = internationalize(App);

  const container = document.getElementById("root");
  const rootContainer = createRoot(container);
  rootContainer.render(
    <Providers>
      <InternationalApp />
    </Providers>
  );
}

renderDom();
