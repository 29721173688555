import React, { Fragment } from "react";
import { map } from "lodash";
import ReservationItem from "./ReservationItem/index.js";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import { ClientItem } from "./ClientItem/index.js";
import PreAppointmentItem from "./PreAppointmentItem/index.js";
import PartnerShipItem from "./PartnerShipItem/index.js";
import { useBreakpoints } from "react-match-breakpoints";
import TherapistItem from "./TherapistItem/index.js";

const Mobile = ({
    sessionsPlot,
    clientsPlot,
    preAppointmentPlot,
    partnershipsPlot,
    therapistsPlot
}) => {

    const { mobile } = useBreakpoints();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                margin: 20,
                marginTop: 100,
            }}
        >
            <Tabs>
                <TabList>
                    <Tab>Sessões</Tab>
                    <Tab>Clientes</Tab>
                    <Tab>Pré-Massagem</Tab>
                    <Tab>Parcerias</Tab>
                    <Tab>Terapeutas</Tab>
                </TabList>
                <TabPanel>
                    {map(sessionsPlot?.rows || [], (reservation, index) => {
                        return (
                            <div key={`reservation-${index}`} style={{ margin: '8px 0px', display: 'flex', flexDirection: 'column', gap: 8 }}>
                                <ReservationItem
                                    {...{ reservation, index }}
                                />
                            </div>
                        )
                    })}
                </TabPanel>
                <TabPanel>
                    {map(clientsPlot?.rows || [], (client, index) => {
                        return (
                            <div key={`client-${index}`} style={{ margin: '8px 0px', display: 'flex', flexDirection: 'column', gap: 8 }}>
                                <ClientItem
                                    {...{ client }}
                                />
                            </div>
                        )
                    })}
                </TabPanel>
                <TabPanel>
                    <div style={{ width: '100%', display: 'flex', gap: 10, flexWrap: 'wrap' }}>
                        {map(preAppointmentPlot?.rows || [], (preAppointment, index) => {
                            return (
                                <Fragment key={`preAppointment-${index}`}>
                                    <PreAppointmentItem
                                        {...{ preAppointment }}
                                    />
                                </Fragment>
                            )
                        })}
                    </div>
                </TabPanel>
                <TabPanel>
                    <div style={{ width: '100%', display: 'flex', gap: 10, flexWrap: 'wrap' }}>
                        {map(partnershipsPlot?.rows || [], (partnership, index) => {
                            return (
                                <Fragment key={`partnerShipItem-${index}`}>
                                    <PartnerShipItem partnership={partnership} />
                                </Fragment>
                            )
                        })}
                    </div>
                </TabPanel>
                <TabPanel>
                    <div style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                        {map(therapistsPlot?.rows || [], (therapist, index) => {
                            return (
                                <TherapistItem therapist={therapist} />
                            )
                        })}
                    </div>
                </TabPanel>
            </Tabs>
        </div >
    )
}

export default Mobile;