import { Typography, useTheme } from "@mui/material"
import { PLAYFAIR } from "../../../constants.js"
import ImageWithText from "../../../components/ImageWithText/index.js";
import { VButton } from "../../../components/NewHomeComponents/VButton/index.js";
import { Section } from "../../../components/NewHomeComponents/Section/index.js";
import opacity from 'hex-color-opacity';
import { Reviews } from "../../../components/NewHomeComponents/Reviews/index.js";
import { useNavigate } from "react-router-dom";
import { useBreakpoints } from "react-match-breakpoints";
import { useResponsive } from "../../../hooks/useResponsive/index.js";
import { Text, translate } from "react-internationalization";
import { lazy, Suspense } from "react";

const Advantages = lazy(() => import('./../../../components/NewHomeComponents/Advantages'));
const WhyVenturaCuidaItem = lazy(() => import('./../../../components/NewHomeComponents/WhyVenturaCuidaItem'));
const ContactUsForm = lazy(() => import('./../../../components/NewHomeComponents/ContactUsForm'));
const Location = lazy(() => import('./../../../components/NewHomeComponents/Location'));
const Blog = lazy(() => import('../../../components/NewHomeComponents/Blog'));
const Services = lazy(() => import('../../../components/NewHomeComponents/Services'));
const Partnerships = lazy(() => import('../../../components/Partnerships'));
const HotelsAccomodations = lazy(() => import('./../../../components/NewHomeComponents/HotelsAccomodations'));

export const NewHome = () => {
    const { spacing } = useTheme();
    const navigate = useNavigate();
    const { mobile } = useBreakpoints();
    const res = useResponsive();

    return <div style={{ maxWidth: 1000 }}>
        <div style={{}}>
            <div style={{}}>
                <ImageWithText
                    img={`${window.location.origin}/images/new_home/massage_main.webp`}
                    imageStyle={{
                        width: '100%',
                        aspectRatio: 16 / 9,
                        height: 'auto'
                    }}
                >
                    <div style={{
                        padding: spacing(4),
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: res(440, 600, 600),
                        gap: spacing(2)
                    }}>
                        <Typography variant="h1" style={{
                            fontFamily: PLAYFAIR,
                            lineHeight: 1.2,
                            fontSize: res('2rem', '3rem', '3rem'),
                            color: 'white',
                            fontOpticalSizing: 'auto',
                            fontWeight: 700,
                            fontStyle: 'normal',
                        }}>
                            <Text id="exclusiveService" />
                        </Typography>
                        <Typography variant="h2" style={{
                            color: 'white',
                            opacity: 0.6,
                            fontSize: res('1.2rem', '2rem', '1.5rem'),
                        }}>
                            <Text id="guestLuxury" />
                        </Typography>
                        <VButton
                            onClick={() => {
                                navigate('/services');
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}
                            style={{ width: 'fit-content' }}>
                            <Typography style={{ fontSize: res('1rem', '2rem', '1rem') }}>
                                <Text id="services.ourServices" />
                            </Typography>
                        </VButton>
                    </div>
                </ImageWithText>
            </div>
            <div style={{ height: '10vh' }}></div>
            <Section textProps={{
                fontSize: res('2.5rem', '3rem', '4rem')
            }} text={translate('whyVentura.title')}>
                < div style={{ display: 'flex', gap: res(0, 10), flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }} >
                    <WhyVenturaCuidaItem />
                </div>
            </Section>
        </div >
        <div style={{ height: '10vh' }}></div>
        <Section text={translate('ourPartners')}>
            <div style={{ width: '100%', display: 'flex', gap: res(10, 40), marginBottom: spacing(2) }}>
                <Partnerships
                    removeShadows={false}
                    swiperProps={{
                        spaceBetween: 0,
                        slidesPerView: res(1, 4)
                    }}
                />
            </div>
        </Section>
        <div style={{
            backgroundColor: opacity('#FFFFFF', 0.1),
            padding: spacing(res(1, 5)),
        }}>
            <HotelsAccomodations />
        </div>
        <div style={{ height: '10vh' }}></div>
        <Section text={translate('advantages')}>
            <div style={{
                gap: mobile ? 10 : 0,
                width: res('100%', 800, 800),
                justifyContent: 'center',
                display: 'flex',
                flexWrap: 'wrap'
            }}>
                <Suspense fallback={<di></di>}>
                    <Advantages />
                </Suspense>
            </div>
        </Section >
        <div style={{ height: '10vh' }}></div>
        <Reviews />
        <div style={{ height: '10vh' }}></div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '80%' }}>
                <Services />
            </div>
        </div>
        <div style={{ height: '10vh' }}></div>
        <ContactUsForm />
        <div style={{ height: '10vh' }}></div>
        <Location />
        <div style={{ height: '10vh' }}></div>
        <Blog />
        <div style={{ height: '10vh' }}></div>
    </div >
}