import {
  Box,
  Checkbox,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import React, { memo } from "react";
import { withSnackbar } from "notistack";
import { Text, translate } from "react-internationalization";
import {
  CLOUD_BURST,
  VENTURACARE_SPREADSHEET,
} from "../../constants.js/index.js";
import { useSendGrid } from "../../hooks/useSendGrid/index.js";
import { useAddSheetRowData } from "../../hooks/useVenturaCuidaSheet/index.js";
import { VtextField } from "../V/VTextField/index.js";
import VButton from "../V/VButton/index.js";
import { useBreakpoints } from "react-match-breakpoints";

const ContactForm = ({
  ref,
  handleClose = () => { },
  spreadSheetId,
  sheetId = VENTURACARE_SPREADSHEET.tabs.contactUs,
  fieldsRequiredProps = {},
  HeaderComponent = null,
  consentText = null,
  CTAs = () => (
    <VButton variant="contained" rel="noreferrer" color="primary" type="submit">
      <Text id="contactUs.send" />
    </VButton>
  ),
  ...props
}) => {
  const theme = useTheme();
  const { tabletDown } = useBreakpoints();
  const { enqueueSnackbar } = props;

  const fieldsRequired = {
    message: true,
    rgdp: true,
    ...fieldsRequiredProps,
  };

  const addContactUsData = useAddSheetRowData(
    VENTURACARE_SPREADSHEET.tabs.contactUs
  );

  const sendEmail = useSendGrid();

  const onSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const message = e.target.message.value;

    try {
      await addContactUsData.mutate({
        name,
        email,
        message,
        phone,
        createdAt: new Date().toLocaleString(),
      });

      sendEmail({ name, message, phone });

      enqueueSnackbar(translate("contactUs.messageSuccess"), {
        variant: "success",
      });
      handleClose();
    } catch (error) {
      enqueueSnackbar("Error happened", { variant: "error" });
    }
  };

  return (
    <div>
      <DialogTitle>
        <div style={{ paddingTop: 20 }}>
          <HeaderComponent />
        </div>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              gap: 20,
              paddingTop: theme.spacing(1),
            }}
          >
            <VtextField
              required
              name="name"
              label={translate("contactUs.name")}
              variant="outlined"
            />
            <VtextField
              type="email"
              required
              name="email"
              label={translate("contactUs.email")}
              variant="outlined"
            />
            <VtextField
              type="tel"
              required
              name="phone"
              label={translate("contactUs.phone")}
              variant="outlined"
            />
            <VtextField
              required={fieldsRequired?.message}
              name="message"
              label={translate("contactUs.message")}
              variant="outlined"
              multiline
              maxRows={5}
              minRows={5}
            />
            <Box display={"flex"} alignItems={"center"} flexDirection={'column'}>
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  name="rgdp"
                  required
                  style={{ marginRight: theme.spacing(2) }}
                />
                <Typography variant="caption">
                  <Text id="consent.authorization" />
                </Typography>
              </Box>

              <div style={{ height: 80, width: '100%', gap: 20, display: 'flex' }}>
                <VButton
                  onClick={() => {
                    window.open('/policy/privacy');
                  }}
                  showDot={null}
                  style={{ fontSize: 10, height: 20 }}
                  variant="text"
                >
                  <Text id="policy.privacy.id" />
                </VButton>
                <VButton
                  onClick={() => {
                    window.open('/policy/terms');
                  }}
                  showDot={null}
                  style={{ fontSize: 10, height: 20 }}
                  variant="text"
                >
                  <Text id="policy.terms.id" />
                </VButton>
                <VButton
                  onClick={() => {
                    window.open('/policy/protection');
                  }}
                  showDot={null}
                  style={{ fontSize: 10, height: 20 }}
                  variant="text"
                >
                  <Text id="policy.protection.id" />
                </VButton>
              </div>
            </Box>
            <a
              href={`mailto:venturacareportugal@gmail.com?subject=${translate(
                "contactUs.removeConsent"
              )}`}
              style={{
                width: "fit-content",
                fontSize: 12,
                paddingLeft: theme.spacing(2),
                color: CLOUD_BURST,
              }}
            >
              {consentText || translate("consent.remove")}
            </a>
            {tabletDown && <div style={{ height: 80 }}></div>}
            {CTAs({ isLoading: false })}
          </div>
        </form>
      </DialogContent>
    </div>
  );
};

export default memo(withSnackbar(ContactForm));
