import { Typography, useTheme } from "@mui/material";
import { Section } from "../Section";
import { BONDI_BLUE } from "../../../constants.js";
import opacity from "hex-color-opacity";
import { useResponsive } from "../../../hooks/useResponsive/index.js";
import { useBreakpoints } from "react-match-breakpoints";
import ImageWithText from "../../ImageWithText/index.js";
import { Text, translate } from "react-internationalization";

const getItemsDesk = () => [
    {
        icon: 'images/advantages/1.webp'
    },
    { title: translate('accomodation.moreClients.title'), description: translate('accomodation.moreClients.description') },
    {
        icon: 'images/advantages/2.webp'
    },
    { title: translate('accomodation.increase.title'), description: translate('accomodation.increase.description') },
    { title: translate('accomodation.earnings.title'), description: translate('accomodation.earnings.description') },
    {
        icon: 'images/advantages/3.webp'
    },
    { title: translate('accomodation.massages.title'), description: translate('accomodation.massages.description') },
    {
        icon: 'images/advantages/4.webp'
    },
];

const getItemsMobile = () => [
    { icon: 'images/advantages/1.webp', title: translate('accomodation.moreClients.title'), description: translate('accomodation.moreClients.description') },
    { icon: 'images/advantages/2.webp', title: translate('accomodation.increase.title'), description: translate('accomodation.increase.description') },
    { icon: 'images/advantages/4.webp', title: translate('accomodation.earnings.title'), description: translate('accomodation.earnings.description') },
    { icon: 'images/advantages/3.webp', title: translate('accomodation.massages.title'), description: translate('accomodation.massages.description') },
];

export const Advantages = ({
    mobileItems = getItemsMobile,
    descktopItems = getItemsDesk
}) => {
    const res = useResponsive();
    const { mobile } = useBreakpoints();
    const { spacing } = useTheme();

    const items = res(mobileItems(), descktopItems());

    const size = res('90%', 200, 300);
    const height = res('fit-content', 200, 'auto');

    const section = (title, description) => <Section
        textProps={{
            fontSize: res('2rem', '1.2rem', '2rem'),
            textAlign: 'left'
        }}
        containerProps={{
            boxSizing: 'border-box',
            padding: spacing(2),
            width: size,
            height: height,
            backgroundColor: opacity(BONDI_BLUE, res(0.9, 0.4)),
            alignItems: 'flex-start',
            gap: spacing(1)
        }}
        text={title}
    >
        <Typography
            variant="body1"
            style={{
                fontSize: res('1.4rem', '1rem', '1.5rem'),
                color: 'white',
                opacity: 0.6
            }}
        >
            <Text id={description} />
        </Typography>
    </Section>

    return items.map(({ icon, title, description }, index) => {
        return <div
            key={`advantages-${index}`}
            style={{ width: size, height: height, display: 'flex', justifyContent: 'center' }}
        >
            {
                icon
                    ? mobile ? <ImageWithText imageStyle={{ width: size, aspectRatio: 1 }} img={icon}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                            {section(title, description)}
                        </div>
                    </ImageWithText> : <img
                        loading="lazy"
                        style={{
                            width: size,
                            objectFit: 'fill',
                            aspectRatio: 1
                        }} src={icon}></img>
                    : section(title, description)
            }
        </div >
    })
}

export default Advantages;