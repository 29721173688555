import { useMemo } from 'react';

const usePersonalizedCols = ({ roleFilter = 'admin', initialColumns, COLUMNS_BLUEPRINT }) => {
    const colsByRole = useMemo(() => {
        if (!initialColumns || !roleFilter || !COLUMNS_BLUEPRINT[roleFilter]?.cols[0]) return initialColumns;
        return initialColumns.reduce((accum, col) => {
            if (COLUMNS_BLUEPRINT[roleFilter]?.cols?.includes(col?.key)) {
                accum.push({ ...col, ...COLUMNS_BLUEPRINT[roleFilter]?.[col.key] });
            }
            return accum;
        }, []);
    }, [roleFilter, initialColumns, COLUMNS_BLUEPRINT]);

    return colsByRole;
};


export const useOrderedCols = (initialColumns, colsOrder) => {
    const orderedColumns = useMemo(() => {
        if (!initialColumns) return [];
        return colsOrder.map((key) =>
            initialColumns.find((col) => col.key === key)
        );
    }, [initialColumns, colsOrder]);

    return orderedColumns;
};


export const usePersonalizedColsError = ({ roleFilter = 'admin', initialColumns, COLUMNS_BLUEPRINT }) => {
    const colsByRole = useMemo(() => {
        if (!initialColumns) return [];
        const result = initialColumns.reduce((accum, col) => {
            if (COLUMNS_BLUEPRINT[roleFilter]?.cols?.includes(col?.key)) {
                const targetIdx = COLUMNS_BLUEPRINT[roleFilter]?.cols.findIndex((v) => v === col?.key)
                accum[targetIdx] = ({ ...col, ...COLUMNS_BLUEPRINT[roleFilter]?.[col.key] });
            }
            return accum;
        }, new Array(COLUMNS_BLUEPRINT[roleFilter]?.cols.length));
        return result;
    }, [roleFilter, initialColumns, COLUMNS_BLUEPRINT]);
    return colsByRole;
};


export default usePersonalizedCols;
