import enLang from './EN';
import ptLang from './PT';
 
export function en() {
  return enLang;
}

export function pt() {
  return ptLang;
}
