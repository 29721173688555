export const hours = [
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30'
]

// Function to create a UTC date and time object
export const createUTCDateTime = (date, hours) => {
    const [year, month, day] = date.split('/').map(Number);
    const [hour, minute] = hours.split(':').map(Number);

    // Create a UTC date with Date.UTC (months are 0-indexed)
    return new Date(year, month - 1, day, hour, minute);
};