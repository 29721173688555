import { Typography, useTheme } from "@mui/material";
import Auth from "../../../components/Modal/Auth";
import { FormProvider, useForm } from "react-hook-form";
import { BONDI_BLUE } from "../../../constants.js";
import { useNavigate } from "react-router-dom";

export const Login = () => {
    const { spacing } = useTheme();
    const { control, watch, register, handleSubmit, reset } = useForm();
    const navigate = useNavigate();

    return (
        <div style={{
            width: '100%',
            padding: spacing(4),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <FormProvider
                {...{ control, register, handleSubmit, reset, watch }}
            >
                <div style={{
                    padding: spacing(3),
                    backgroundColor: 'white',
                    justifyContent: 'center',
                    width: '100%',
                    display: 'flex',
                    borderRadius: 4,
                }}>
                    <div style={{
                        display: 'flex',
                    }}>
                        <div style={{ width: '100%', gap: 10, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                            <Typography fontWeight={'bold'} variant="body1" color={BONDI_BLUE}>Painel de gestão</Typography>
                            <Typography variant="caption" style={{ opacity: 0.7 }}>
                                Autenticação para os nossos terapeutas e parcerias.
                            </Typography>
                            <Auth
                                onAuthSuccess={() => navigate('/dashboard')}
                            />
                        </div>
                    </div>
                </div>
            </FormProvider >
        </div >
    );
}