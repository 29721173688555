export const SERVICES = [
  {
    id: "therapeuticMassage",
    name: "services.therapeuticMassage.name",
    img: "./images/services/icons/therapeutic-massage.png",
    description: "services.therapeuticMassage.description",
  },
  {
    id: "lymphaticDrainage",
    name: "services.lymphaticDrainage.name",
    img: "./images/services/icons/lymphatic-drainage.png",
    description: "services.lymphaticDrainage.description",
  },
  {
    id: "relaxing-massage",
    name: "services.relaxingMassage.name",
    img: "./images/services/icons/relaxing.png",
    description: "services.relaxingMassage.description",
  },
  {
    id: "acupuncture",
    name: "services.acupuncture.name",
    img: "./images/services/icons/acunpucture.png",
    description: "services.acupuncture.description",
  },
  {
    id: "orthopedic",
    name: "services.orthopedic.name",
    img: "./images/services/icons/orthopedic.png",
    description: "services.orthopedic.description",
  },
  {
    id: "geriatric",
    name: "services.geriatric.name",
    img: "./images/services/icons/geriatric.svg",
    description: "services.geriatric.description",
  },
];

export const REVIEWS = [
  {
    name: "Nataly Bellomia",
    stars: 5,
    link: "https://g.co/kgs/uXU3cu",
    text: "reviews.natalyBellomia.text",
    createdAt: "2022-07-25",
    source: "Google",
  },
  {
    name: "Carolina Gomes",
    stars: 5,
    link: "https://g.co/kgs/e4nV2T",
    text: "reviews.carolinaGomes.text",
    createdAt: "2022-09-25",
    source: "Google",
  },
  {
    name: "Elisa Veronica",
    stars: 5,
    link: "https://g.co/kgs/P2L778",
    text: "reviews.elisaVeronica.text",
    createdAt: "2022-05-25",
    source: "Google",
  },
];

export const CATEGORIES = [
  {
    name: "categories.wellness",
    img: "categories/wellness.webp",
    problems: [
      { name: "categories.muscleTension.name", id: "muscle-tension" },
      { name: "categories.swelling.name", id: "swelling" },
      { name: "categories.contractures.name", id: "contractures" },
      {
        name: "categories.posturalAlterations.name",
        id: "postural-alterations",
      },
    ],
  },
  {
    name: "categories.elderly",
    img: "/services/geriatric.jpeg",
    problems: [
      { name: "categories.mobility.name", id: "mobility" },
      {
        name: "categories.breathingDifficulties.name",
        id: "breathing-difficulties",
      },
    ],
  },
  {
    name: "categories.womenMom",
    img: "categories/women.webp",
    problems: [
      {
        name: "categories.urinaryIncontinence.name",
        id: "urinary-incontinence",
      },
      {
        name: "categories.painDuringIntercourse.name",
        id: "pain-during-intercourse",
      },
      {
        name: "categories.pelvicOrganProlapse.name",
        id: "pelvic-organ-prolapse",
      },
      { name: "categories.pelvicPain.name", id: "pelvic-pain" },
      {
        name: "categories.difficultyReachingOrgasm.name",
        id: "difficulty-reaching-orgasm",
      },
    ],
  },
  /*{
        name: 'Corporate',
        img: 'categories/corporate.jpg',
        problems: [
            {name: 'Drenagem'},
            {name: 'Massagem'},
        ],
    }
    */
];

export const PRICING = [
  {
    id: "one-session-60",
    numSessions: 1,
    name: "pricing.oneSession60.name",
    price: "pricing.oneSession60.price",
    duration: "pricing.oneSession60.duration",
    ideal: [
      "pricing.oneSession60.ideal.2",
      "pricing.oneSession60.ideal.3",
    ],
  },
  {
    id: "pack-5",
    numSessions: 5,
    name: "pricing.pack5.name",
    price: "pricing.pack10.price",
    duration: "pricing.pack10.duration",
    ideal: ["pricing.pack10.ideal.1", "pricing.pack10.ideal.2"],
  },
  {
    id: "pack-10",
    numSessions: 10,
    name: "pricing.pack10.name",
    price: "pricing.pack10.price",
    duration: "pricing.pack10.duration",
    ideal: ["pricing.pack10.ideal.1", "pricing.pack10.ideal.2"],
  },
  {
    id: "pack-20",
    numSessions: 20,
    name: "pricing.pack20.name",
    price: "pricing.pack20.price",
    duration: "pricing.pack20.duration",
    ideal: ["pricing.pack20.ideal.1", "pricing.pack20.ideal.2"],
  },
];

const partnershipsPath = "./images/partnerships";
export const PARTNERSHIPS = [
  {
    img: `${partnershipsPath}/quinta-da-serra.png`,
    link: "https://www.hotelquintadaserra.com/",
    sector: 'sector.hotel'
  },
  {
    img: `${partnershipsPath}/selina-white.png`,
    link: "https://www.selina.com/portugal/secret-garden-lisbon/",
    sector: 'sector.hotel'
  },
  {
    img: `${partnershipsPath}/moscadinha.webp`,
    link: "https://quintadamoscadinha.com/",
    sector: 'sector.hotel'
  },
  {
    img: `${partnershipsPath}/fitcenter.webp`,
    link: "https://fitcenter.pt/",
    sector: 'sector.gym'
  },
  /*{
    img: `${partnershipsPath}/smart-studios.png`,
    link: "https://smartstudios.pt/",
  },*/
  {
    img: `${partnershipsPath}/clivip.png`,
    link: "https://clivip.pt/",
    sector: 'sector.medical-clinic'
  },
  {
    img: `${partnershipsPath}/medicare.png`,
    link: "https://www.medicare.pt/",
    sector: 'sector.insurance'
  },
];

export const DARK_CYAN = "#2A8F8A";
export const LIGHT_CYAN = "#bfdddb";
export const FUZZY_WUZZY = "#D2666B";
export const BABY_PINK = "#EFC9CA";
export const WHITE_SMOKE = "#F3F3F3";
export const CULTERED = "#F3F3F3";

export const BONDI_BLUE = "#009FB5";
export const BRIGHT_GRAY = "#e3f4f7";
export const CLOUD_BURST = "#202E54";
export const AQUA_SQUEEZE = "#E8F5F2";
export const DARK_BLUE_GRAY = "#636E88";
export const BLACK = "#1E1E1E";
export const SPACE_CADET = "#1D2B4F";
export const GUNMETAL = "#213341";

export const MAIN_FONT = "Poppins";
export const PLAYFAIR = 'Playfair';

export const VENTURACARE_SPREADSHEET = {
  tabs: {
    contactUs: "0",
    evaluation: "1401379983",
    Reservas: "1267249295",
    Products: "970903279",
    Fisioterapeutas: "1084927239",
    Reviews: "917305051",
    Parcerias: "381342159",
    Servicos: "1630915958",
    Precario: "318661210",
    Clients: "1824711188",
    Avaliacao: "828889034",
    PlanoIntervencao: "1654980836",
    PreMassagem: '98147855',
    PreAppointment: '339230140',
  },
};

export const blogs = [
  {
    name: "blog.whyPhisioterapy.title",
    img: "/images/blog/why-physio.webp",
    description: "blog.whyPhisioterapy.text",
    id: "whyPhisioterapy",
  },
  {
    name: "blog.massage.title",
    img: "/images/blog/massage.webp",
    description: "blog.massage.text",
    id: "massage",
  },
  {
    name: "blog.pregnancy.title",
    img: "/images/blog/pregnancy.webp",
    description: "blog.pregnancy.text",
    id: "pregnancy",
  },
  {
    name: "blog.pelvic.title",
    img: "/images/blog/pelvic.webp",
    description: "blog.pelvic.text",
    id: "pelvic",
  },
];

export const pages = [
  { pageName: "header.home", href: "/", id: "home" },
  { pageName: "header.hotelAirbnb", href: "/hotel-airbnb", id: "hotel-airbnb" },
  // { pageName: "header.corporate", href: "/corporate", id: "corporate" },
  { pageName: "header.therapist", href: "/therapist", id: "therapist" },
  { pageName: "header.aboutUs", href: "/about-us", id: "aboutUs" },
  { pageName: "login", href: "/login", id: "login" },
];
