import { CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { map } from "lodash";
import React from "react";
import { useBreakpoints } from "react-match-breakpoints";
import {
  BONDI_BLUE,
  BRIGHT_GRAY,
  CLOUD_BURST,
  VENTURACARE_SPREADSHEET,
} from "../../../constants.js/index.js";
import { useLanguage } from "../../../contexts/Language";
import Product from "./Product";
import { ClickableLogo } from "../../../components/ClickableLogo/index.js";
import { Text } from "react-internationalization";
import { useSheet } from "../../../hooks/useVenturaCuidaSheet/index.js";
import { WhatsAppWidget } from "../../../components/WhatsappWidget/index.js";

export default function Recomendations() {
  const theme = useTheme();
  const { mobile, tabletDown } = useBreakpoints();
  const { language } = useLanguage();
  const { data: products, isLoading } = useSheet(
    VENTURACARE_SPREADSHEET.tabs.Products
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <WhatsAppWidget />
      <div
        style={{
          backgroundColor: BRIGHT_GRAY,
          height: 600,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 20,
          paddingBottom: 50,
        }}
      >
        {tabletDown && <ClickableLogo imgStyles={{ width: 200 }} />}
        <Typography
          fontSize={tabletDown ? 40 : 74}
          fontFamily={"Poppins"}
          fontWeight={700}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: BONDI_BLUE,
            textAlign: "center",
          }}
        >
          <Text id="recomendationsHeader" />
        </Typography>
        <Typography
          style={{
            maxWidth: 900,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            textAlign: "center",
          }}
          fontSize={18}
          color={CLOUD_BURST}
          fontFamily={"Poppins"}
        >
          <Text id="recomendations.description"></Text>
        </Typography>
      </div>

      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: tabletDown ? 300 : 400,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: theme.spacing(mobile ? 1 : 3),
            justifyContent: "center",
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
          }}
        >
          {map(products?.data, (row, index) => (
            <Product
              key={`row-${index}`}
              {...{
                name: row[`name-${language}`],
                description: row[`description-${language}`],
                img: row.image,
                link: row.link,
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}
