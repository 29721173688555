import { useQuery } from "react-query";
import { venturaCuidaApi } from "../../../api/venturaCuida";
import { Typography } from "@mui/material";
import { useAuth } from "../../../contexts/AuthProvider";
import { useState } from "react";
import { DataPlot } from "../DataPlot";
import opacity from "hex-color-opacity";
import { BONDI_BLUE } from "../../../constants.js";
import { VButton } from "../../NewHomeComponents/VButton/index.js";
import { useDashboardData } from "../../../hooks/useDashboardData/index.js";
import { useDataPlotData } from "../../../hooks/useDataPlotData/index.js";

export const Partnerhips = () => {
    const [addSessionOpen, setAddSessionOpen] = useState(false);
    const { data, isLoading } = useDashboardData({ resource: 'partnerships' });

    const [initialcolumns, initialRows] = useDataPlotData({
        data,
        isLoading,
    });

    return <div style={{ height: '100%', width: '100%', overflowX: 'scroll' }}>
        {!isLoading && <DataPlot
            columns={initialcolumns}
            rows={initialRows}
            Header={() => <div style={{
                paddingLeft: 10,
                paddingRight: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: opacity(BONDI_BLUE, 0.5), width: '100%',
                height: 60
            }}>
                <Typography style={{ color: 'white' }}>Parcerias</Typography>
                <Typography style={{ color: 'white' }}>{initialRows?.length}</Typography>
                <VButton onClick={() => setAddSessionOpen(true)}>Criar parceria</VButton>
            </div>
            }
            {...{ data, isLoading }}
        />}
    </div>
}