import { Box, ClickAwayListener, useTheme } from "@mui/material";
import { lowerFirst, times } from "lodash";
import { BONDI_BLUE, GUNMETAL } from "../../../../constants.js";
import { useSpring, animated } from "react-spring";
import { useEffect, useState } from "react";
import { Menu } from "../../../Header/Mobile/Menu/index.js";
import { ClickableLogo } from "../../../ClickableLogo/index.js";


export const Mobile = () => {

    const { spacing } = useTheme();

    const ABox = animated(Box);

    const [open, setOpen] = useState(false);

    const [styles, api] = useSpring(() => ({
        width: 80,
        borderRadius: '50%',
        height: 80,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    }))

    useEffect(() => {
        if (open) {
            api.start({
                width: 300,
                borderRadius: '1%',
                height: 500,
            })
        } else {
            api.start({
                width: 80,
                borderRadius: '50%',
                height: 80,
            })
        }
    }, [open])


    return (
        <>
            <ClickAwayListener
                mouseEvent="onMouseUp"
                touchEvent="onTouchEnd"
                onClickAway={() => {
                    setOpen(false);
                }}
            >
                <div style={{
                    margin: spacing(2),
                    zIndex: 100,
                    position: 'fixed'
                }}>
                    <ABox style={{
                        background: BONDI_BLUE,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        ...styles,
                    }}>
                        <Box
                            onClick={() => {
                                setOpen(!open);
                            }}
                            style={{
                                position: 'absolute',
                                left: 25,
                                top: 30,
                                width: 30,
                                display: "flex",
                                flexWrap: "wrap",
                                gap: spacing(1),
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            sx={{
                                "&:hover": {
                                    cursor: "pointer",
                                },
                            }}
                        >
                            {times(4, (index) => {
                                return (
                                    <div
                                        key={"dot-" + index}
                                        style={{
                                            height: "5px",
                                            width: "5px",
                                            backgroundColor: 'white',
                                            borderRadius: "50%",
                                            display: "inline-block",
                                        }}
                                    />
                                );
                            })}
                        </Box>
                        {open && <div style={{ marginTop: spacing(10) }}>
                            <Menu toggleOpen={() => setOpen(false)} />
                        </div>}
                    </ABox>
                </div>
            </ClickAwayListener>
            <div style={{
                padding: spacing(3),
                paddingTop: spacing(10),
                backgroundColor: GUNMETAL,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <ClickableLogo logo="logo/logo_branco.png" />
            </div>
        </>
    )
}