import { IconButton, MenuItem, Select, Typography } from "@mui/material";
import { useAuth } from "../../../contexts/AuthProvider";
import 'react-data-grid/lib/styles.css';
import { DataPlot } from "../DataPlot";
import opacity from "hex-color-opacity";
import { BONDI_BLUE } from "../../../constants.js";
import { VButton } from "../../NewHomeComponents/VButton/index.js";
import { useDashboardData } from "../../../hooks/useDashboardData/index.js";
import { memo, useMemo, useState } from "react";
import { useDataPlotData } from "../../../hooks/useDataPlotData/index.js";
import usePersonalizedCols, { useOrderedCols } from "../../../hooks/usePersonalizedCols/index.js";
import { createRenderDate, createRenderDropdown, EditableDataPlotContext, EditableDataPlotProvider } from "../../../contexts/EditDataPlotProvider/index.js";
import { renderCheckbox, textEditor } from "react-data-grid";
import { useMutation, useQueryClient } from "react-query";
import { venturaCuidaApi } from "../../../api/venturaCuida/index.js";
import { groupBy } from "lodash";
import moment from "moment";
import ScheduleSessionV2, { ScheduleSessionV2Context } from "../../Modal/ScheduleSessionV2";
import { DeleteOutline, HdrPlusOutlined, PlusOne } from "@mui/icons-material";
import { withSnackbar } from "notistack";
import { useBreakpoints } from "react-match-breakpoints";
import { useDashboardPages } from "../../../hooks/useDashboardPages/index.js";
import { TabletDownDashboardChanger } from "../../TabletDownDashboardChanger/index.js";
import withConfirmationDialog from "../../HOC/withConfirmDialog/index.js";

const SHOW_COLS = [
    'partnership.name',
    'client.name',
    'therapist.name',
    'service.name',
    'status',
    'date',
    'therapistPay',
    'totalServicePrice',
    'totalServicePricePaidToTherapist',
    'duration',
    'notes',
    'actions'
];

const MAIN_RESOURCE = 'sessions';

const Sessions = ({ enqueueSnackbar, openDialog }) => {
    const { user } = useAuth();
    const [addSessionOpen, setAddSessionOpen] = useState(false);
    const { data, isLoading, queryKey: mainResourceQueryKey } = useDashboardData({ resource: MAIN_RESOURCE });
    const services = useDashboardData({ resource: 'services' });

    const users = useDashboardData({ resource: 'user' });
    const usersByRole = useMemo(() => {
        if (users.isLoading) return;
        return groupBy(users.data, ({ role }) => role);
    }, [users]);


    const [initialColumns, initialRows] = useDataPlotData({
        columnsProps: {
            renderEditCell: textEditor,
        },
        data,
        extraCols: [{ key: 'actions', name: 'actions' }],
        isLoading,
    });

    const columnsBluePrint = useMemo(() => {
        return {
            admin: {
                cols: SHOW_COLS,
                'partnership.name': {
                    renderCell: ({ row }) => {
                        return row?.partnership?.name;
                    },
                    renderEditCell: createRenderDropdown({
                        options: usersByRole?.partnership,
                        processOnChange: ({ row, value }) => {
                            return { ...row, partnership: value };
                        },
                        processValue: (row) => row.partnership
                    })
                },
                'therapist.name': {
                    renderCell: ({ row }) => {
                        return row?.therapist?.name;
                    },
                    renderEditCell: createRenderDropdown({
                        options: usersByRole?.therapist,
                        processOnChange: ({ row, value }) => {
                            return { ...row, therapist: value };
                        },
                        processValue: (row) => row.therapist
                    })
                },
                'client.name': {
                    renderCell: ({ row }) => {
                        return row?.client?.name;
                    },
                    renderEditCell: createRenderDropdown({
                        options: usersByRole?.client,
                        processValue: (row) => row.client,
                        processOnChange: ({ row, value }) => {
                            return { ...row, client: value };
                        },
                    })
                },
                'service.name': {
                    renderCell: ({ row }) => {
                        return row?.service?.name;
                    },
                    renderEditCell: createRenderDropdown({
                        options: services?.data,
                        processValue: (row) => row,
                        processOnChange: ({ row, value }) => {
                            return { ...row, service: value };
                        },
                    })
                },
                'status': {
                    renderCell: ({ row }) => {
                        return row?.status;
                    },
                    renderEditCell: createRenderDropdown({
                        options: [
                            "inquiry_received",
                            "awaiting_therapist_availability",
                            "availability_provided",
                            "awaiting_confirmation",
                            "confirmed",
                            "session_scheduled",
                            "session_in_progress",
                            "completed",
                            "cancelled"
                        ],
                        processValue: (row) => {
                            console.log({ row });
                            return row?.status;
                        },
                        processOnChange: ({ row, value }) => {
                            console.log({ row, value });
                            return { ...row, status: value };
                        },
                        getOptionLabel: (value) => {
                            console.log({ value });
                            return value;
                        },
                    })
                },
                'date': {
                    renderCell: ({ row }) => {
                        return moment(row?.date).format('DD/MM/YYYY HH:mm');
                    },
                    renderEditCell: createRenderDate(),
                },
                'totalServicePricePaidToTherapist': {
                    renderCell: ({ row, onRowChange }) => renderCheckbox({
                        checked: row.totalServicePricePaidToTherapist,
                        onChange: (e) => {
                            onRowChange({ ...row, totalServicePricePaidToTherapist: e })
                        }
                    }),
                    renderEditCell: ({ column, row, onRowChange, onClose }) => renderCheckbox({
                        onChange: (e) => {
                            console.log({ e, column, row, onRowChange, onClose })
                        }
                    })
                },
                'actions': {
                    renderCell: ({ row }) => {
                        return <div
                            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                                onClick={() => openDialog(() => {
                                    deleteBookingMutation.mutate({ sessionId: row._id },
                                        {
                                            onSuccess: () => {
                                                queryClient.invalidateQueries({ queryKey: [mainResourceQueryKey] })
                                            }
                                        }
                                    )
                                })}
                                size="small"
                            >
                                <DeleteOutline />
                            </IconButton>
                        </div >;
                    },
                }
            }
        }
    }, [usersByRole, services]);

    const personalizedCols = usePersonalizedCols({
        initialColumns,
        COLUMNS_BLUEPRINT: columnsBluePrint,
    })

    const orderedCols = useOrderedCols(personalizedCols, SHOW_COLS);

    const queryClient = useQueryClient();

    const updateDataMutation = useMutation({
        mutationFn: (data) => {
            console.log({ data });
            return venturaCuidaApi().put('api/admin/' + MAIN_RESOURCE, data)
        },
    });

    const deleteBookingMutation = useMutation({
        mutationFn: (data) => {
            return venturaCuidaApi().delete(`/api/admin/sessions/${data.sessionId}`);
        },
    });

    const { tabletDown, mobile } = useBreakpoints();

    const pages = useDashboardPages();

    return <div style={{ height: '100%', width: '100%', overflowX: 'scroll' }}>
        {user?.role && <ScheduleSessionV2Context.Provider value={{
            services: services?.data,
            partnerships: usersByRole?.partnership,
            clients: usersByRole?.client,
            therapists: usersByRole?.therapist,
            clients: usersByRole?.client,
            mainResourceQueryKey,
        }}>
            <ScheduleSessionV2
                open={addSessionOpen}
                handleClose={() => setAddSessionOpen(false)}
                {...(user?.role === 'therapist' && {
                    showReset: false,
                })}
            />
        </ScheduleSessionV2Context.Provider>
        }
        {!isLoading &&
            <EditableDataPlotProvider rows={initialRows}>
                <EditableDataPlotContext.Consumer>
                    {({ isEdited, changedRows, reset, setEditedRows, editedRows, rowKeyGetter }) => {
                        return (
                            <DataPlot
                                columns={orderedCols}
                                rows={editedRows || []}
                                dataGridProps={{
                                    onRowsChange: setEditedRows,
                                    rowKeyGetter,
                                }}
                                Header={() => <div style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: opacity(BONDI_BLUE, 0.7),
                                    width: '100%',
                                    height: 60,
                                }}>
                                    <TabletDownDashboardChanger currentPage={'Reservas'} />
                                    <div style={{ display: 'flex', gap: 10 }}>
                                        <VButton loading={updateDataMutation.isLoading} disabled={!isEdited} onClick={() => {
                                            updateDataMutation.mutate(changedRows, {
                                                onSuccess: () => {
                                                    queryClient.invalidateQueries({ queryKey: [`${MAIN_RESOURCE}-${user._id}`] })
                                                    reset();
                                                },
                                            })
                                        }}>
                                            Guardar
                                        </VButton>
                                        <VButton onClick={() => setAddSessionOpen(true)}>{mobile ? <PlusOne /> : "Criar sessão"}</VButton>
                                    </div>
                                </div>
                                }
                                {...{ data, isLoading }}
                            />)
                    }}
                </EditableDataPlotContext.Consumer>
            </EditableDataPlotProvider>
        }
    </div>
}

export default memo(withSnackbar(withConfirmationDialog(Sessions)));
