import { Typography } from "@mui/material";
import React from "react";
import { Text } from "react-internationalization";
import { useBreakpoints } from "react-match-breakpoints";

const PageHeader = ({name, LeftComponent = null}) => {
  const { mobile } = useBreakpoints();

  return (
    <div style={{
        width: '100%',
        background: '#162836',
        display: 'flex',
        alignItems: 'center',
        gap: mobile ? 10 : 100,
        padding: '20px 0px',
    }}>
        {LeftComponent ? LeftComponent : <div style={{width: 30}}></div>}
        <Typography style={{
          color: 'white',
          fontSize: '4vw',
        }}>
          <Text id={name}/>
        </Typography>
    </div>
  );
};
export default PageHeader;