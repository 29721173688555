import { CircularProgress } from "@mui/material";
import { memo, useMemo } from "react";
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';

export const DataPlot = memo(({ isLoading, columns, rows, dataGridProps, Header = () => <div></div> }) => {

    return <div style={{ width: '100%', height: '100%' }}>
        {!isLoading && columns
            ? <div style={{ height: '100%', display: 'flex', flexDirection: 'column', overflowX: 'hidden', width: '100%', height: '100%' }}>
                <Header />
                <DataGrid
                    className='rdg-light'
                    style={{ height: window.innerHeight - 120, overflowY: 'scroll' }}
                    enableVirtualization
                    columns={columns}
                    rows={rows}
                    {...dataGridProps}
                />
            </div>
            : <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        }
    </div >
})