import { useMemo } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useNavigate } from "react-router-dom"; // Assuming you are using react-router

export const useDashboardPages = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return useMemo(() => {
    const adminOnly = [
      {
        name: "Utilizadores",
        onClick: () => navigate("/dashboard/users"),
      },
      {
        name: "Serviços",
        onClick: () => navigate("/dashboard/services"),
      },
      {
        name: "Preçário",
        onClick: () => navigate("/dashboard/pricing"),
      },
      {
        name: "Pré-agendamento",
        onClick: () => navigate("/dashboard/pre-appointments"),
      },
      {
        name: "Old Admin",
        onClick: () => navigate("/dashboard/old-admin"),
      },

    ];

    return [
      {
        name: "Reservas",
        onClick: () => navigate("/dashboard/sessions"),
      },
      ...(user?.role === "admin" ? adminOnly : []),
    ];
  }, [user?.role, navigate]);
};
