import { useMutation, useQuery, useQueryClient } from "react-query";
import { venturaCareApi, venturaCuidaApi } from "../../api/venturaCuida";

export const makeSheetKey = (spreadSheetId) => `sheet-${spreadSheetId}`;

export const useAddSheetRowData = (spreadSheetId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await venturaCareApi().post(
        `/spreadsheet/${spreadSheetId}/add-row`,
        data
      );
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(makeSheetKey(spreadSheetId));
    },
  });
};

export const useCheckTherapist = () => {
  return useMutation({
    mutationFn: async (name) => {
      const therapist = await venturaCareApi().get(`/api/excel/therapist/${name}`);
      return therapist?.data;
    },
  });
};

export const useSheet = (spreadSheetId, options) => {
  return useQuery(makeSheetKey(spreadSheetId), async () => {
    const data = await venturaCuidaApi().get(`/spreadsheet/${spreadSheetId}`);
    return data?.data;
  },
    options,
  );
};
