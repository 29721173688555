import { useMutation } from 'react-query';
import { venturaCuidaApi } from '../../api/venturaCuida';

// Define the function that will call the API
const createUserAndPreAppointment = async (data) => {
    const response = await venturaCuidaApi().post('api/client/create-user-and-pre-appointment', data);
    return response.data;
};

// Define the custom hook
const useCreateUserAndAppointment = () => {
    return useMutation(createUserAndPreAppointment, {
        onError: (error) => {
            console.error('Error creating user and appointment:', error);
        },
        onSuccess: (data) => {
            console.log('User and appointment created successfully:', data);
        },
    });
};

export default useCreateUserAndAppointment;
