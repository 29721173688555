import { Autocomplete, TextField, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import VButton from "../../V/VButton/index.js";
import { withSnackbar } from "notistack";
import { MyDialog } from "../MyDialog/index.js";
import { useState } from "react";
import {
  useAddSheetRowData,
  useSheet,
} from "../../../hooks/useVenturaCuidaSheet/index.js";
import {
  BONDI_BLUE,
  VENTURACARE_SPREADSHEET,
} from "../../../constants.js/index.js";
import moment from "moment/moment";
import { filter } from "lodash";
import NameLogin from "../NameLogin/index.js";
import { DateFormInput } from "../DateAndHour/index.js";
import AuthTherapist from "../Auth/index.js";

function TherapyPlatForm({ open = false, handleClose, enqueueSnackbar }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);

  const { data: workers } = useSheet(
    VENTURACARE_SPREADSHEET.tabs.Fisioterapeutas,
    { enabled: step === 1 }
  );

  const { data: clients } = useSheet(
    VENTURACARE_SPREADSHEET.tabs.Clients,
    { enabled: step === 1 }
  );

  const clientsFiltred = useMemo(() => {
    if (clients?.data) {
      return window?.env?.NODE_ENV === "development"
        ? clients.data
        : filter(clients?.data, ({ Nome }) => !Nome.includes("Teste"));
    }
  }, [clients]);

  const { control, register, handleSubmit, reset, watch } = useForm({
    defaultValues: {},
  });

  const localHandleClose = () => {
    reset({});
    setStep(0);
    handleClose();
  };

  const addAvaliacao = useAddSheetRowData(
    VENTURACARE_SPREADSHEET.tabs.PlanoIntervencao
  );

  const onError = (errors, e) => {
    enqueueSnackbar("Aconteceu um erro", { variant: "error" });
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const now = moment().format("YYYY/MM/DD hh:mm");

    const newRow = {
      Timestamp: now,
      "Data da avaliação": moment().format("YYYY/MM/DD"),
      ...data,
    };

    addAvaliacao.mutate(newRow, {
      onSuccess: () => {
        enqueueSnackbar("Registado com sucesso", {
          variant: "success",
        });
        localHandleClose();
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        throw error;
      },
    });
  };

  return (
    <FormProvider {...{ control }}>
      <MyDialog
        stylesProps={{ width: "100%" }}
        open={open}
        onClose={localHandleClose}
        scroll="paper"
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div
          style={{
            padding: theme.spacing(3),
            paddingLeft: 20,
            paddingRight: 20,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <Typography variant="body1" fontWeight={700} color={BONDI_BLUE}>
            Objetivos e Plano de Intervenção
          </Typography>
          {step === 1 && (
            <>
              <Typography variant="body2">Terapeuta*</Typography>
              <TextField
                disabled
                {...register("Terapeuta")}
                label="Terapeuta"
                variant="outlined"
              />
              <Typography variant="body2">Cliente*</Typography>
              <div style={{ display: "flex", gap: theme.spacing(2) }}>
                <Controller
                  control={control}
                  name="Cliente"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      options={clientsFiltred?.map((e) => e?.Nome)}
                      renderInput={(params) => (
                        <TextField {...params} label="Cliente" />
                      )}
                      onChange={(e) => {
                        onChange(e?.target?.innerText);
                      }}
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
              <Typography variant="body2">Diagnóstico*</Typography>
              <TextField
                {...register("Diagnóstico")}
                multiline
                minRows={3}
                label="Diagnóstico"
                variant="outlined"
              />
              <Typography variant="body2">Objetivos*</Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(4),
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(2),
                  }}
                >
                  <TextField
                    {...register("Objetivos curto prazo", { required: true })}
                    multiline
                    minRows={3}
                    label="Curto prazo"
                    variant="outlined"
                  />
                  <DateFormInput name="Curto prazo data" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(2),
                  }}
                >
                  <TextField
                    {...register("Objetivos médio prazo", { required: true })}
                    multiline
                    minRows={3}
                    label="Médio prazo"
                    variant="outlined"
                  />
                  <DateFormInput name="Médio prazo data" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(2),
                  }}
                >
                  <TextField
                    {...register("Objetivos longo prazo", { required: true })}
                    multiline
                    minRows={3}
                    label="Longo prazo"
                    variant="outlined"
                  />
                  <DateFormInput name="Longo prazo data" />
                </div>
              </div>
              <Typography variant="body2">Plano de intervenção*</Typography>
              <TextField
                {...register("Plano de intervenção", { required: true })}
                multiline
                minRows={3}
                label="Plano de intervenção"
                variant="outlined"
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <VButton
                  style={{ alignSelf: "flex-end" }}
                  variant="contained"
                  color="secondary"
                  showDot={"left"}
                  onClick={() => {
                    localHandleClose();
                  }}
                >
                  Cancelar
                </VButton>
                <VButton
                  style={{ alignSelf: "flex-end" }}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  onClick={() => {
                    handleSubmit(onSubmit, onError)();
                  }}
                >
                  Registar
                </VButton>
              </div>
            </>
          )}
          {step === 0 && (
            <FormProvider
              {...{ control, register, handleSubmit, reset, watch }}
            >
              <AuthTherapist
                {...{
                  localHandleClose,
                  enqueueSnackbar,
                  onAuthSuccess: () => { setStep(1) },
                }}
              />
            </FormProvider>
          )}
        </div>
      </MyDialog>
    </FormProvider>
  );
}

export default withSnackbar(TherapyPlatForm);
